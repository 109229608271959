import React, { useState, useEffect } from 'react';
import UserAPI from '../services/UserAPI';
import ClientAPI from '../services/ClientAPI';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AddUser from "../components/addcount/addUser";
import AddClient from "../components/addcount/addClient";
import ClientList from "../components/addcount/clientList";
import UserList from "../components/addcount/userList";

import {Grid, 
		Typography,
		Paper,
		Tabs,
		Tab,
       } from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		display: 'flex',
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
	},
	titlePage: {
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		marginLeft: 25,
		marginTop: 8,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
			marginLeft: 0,
			marginTop: 0,
		},
	},
	iconstyle: {
		marginTop: 12,
		float: 'right', 
		color: 'white',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
			marginTop: 16,
		},
	},
	warningPaper: {
		padding: 20,
		textAlign: "justify",
		fontFamily: 'AvantGardeBook',
	}
}));

const AntTabs = withStyles({
	root: {
		backgroundColor: '#2d444f',
		display: 'flex',
		justifyContent: 'center'
	},
	indicator: {
		backgroundColor: '#eb6c29',
	},
})(Tabs);

const AntTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(4),
		fontFamily: 'AvantGardeBold',
		fontSize: '1.5em',
		color: 'white',
		'&:hover': {
			color: '#eb6c29',
			opacity: 1,
		},
		'&$selected': {
			color: '#eb6c29',
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: '#eb6c29',
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const AddCount = ({reload}) => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:900px)');
	const responsiveDevice2 = useMediaQuery('(min-width:465px)');

	const [stateOnglets, setStateOnglets] = useState(0);
	const [users, setUsers] = useState([]);
	const [clients, setClients] = useState([]);
	const [reloadUsers, setReloadUsers] = useState(false);
	const [reloadClients, setReloadClients] = useState(false);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const responseUsers = await UserAPI.findAllUsers();
				setUsers(responseUsers);
			} catch(error){
				//console.log(error);
				toast.error('Une erreur est survenue concernant le chargement des utilisateurs.');
			}
		};
		fetchUsers();
	}, [reloadUsers]);

	useEffect(() => {
		const fetchClients = async () => {
			try {
				const responseClients = await ClientAPI.findAllClients();
				responseClients.sort(function(a, b) {
					var textA = a.nomClient.toUpperCase();
					var textB = b.nomClient.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				});
				setClients(responseClients);
			} catch(error){
				//console.log(error);
				toast.error('Une erreur est survenue concernant le chargement des clients.');
			}
		};
		fetchClients();
	}, [reloadClients]);

	const reloadUserslist = () => {
		setReloadUsers(!reloadUsers);
	}

	const reloadClientslist = () => {
		setReloadClients(!reloadClients);
	}

	const handleChange = (event, newValue) => {
		event.preventDefault();
		setStateOnglets(newValue);
	};

	return (
		<div className={classes.root}>
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid item xs={12} lg={6} container justify="center">
						{responsiveDevice2 ?
							<PersonAddIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Gestionnaire de compte
						</Typography>
					</Grid>

					{responsiveDevice ? 
						<Grid item xs={12} lg={6}>
							<Paper>
								<AntTabs
									value={stateOnglets}
									onChange={handleChange}
									variant="fullWidth"
									centered
								>
									<AntTab title="Utilisateurs" label="Utilisateurs" />
									<AntTab title="Clients" label="Clients" />
								</AntTabs>
							</Paper> 
						</Grid>
					:null}  
				</Grid>
				<Grid container spacing={3} justify="center">
					{responsiveDevice ? 
						<Grid item xs={12} lg={12}>
							{stateOnglets === 1 ? <ClientList reloadTab={() => reloadClientslist()} passClients={clients} /> : <UserList reloadTab={() => reloadUserslist()} passUsers={users} passClients={clients} reload={() => reload()} />}
						</Grid>
					:
						<Grid container item xs={12} lg={12}>
							<Paper className={classes.warningPaper}>
								<Typography variant="body1" >
									Le tableau n'est pas en mesure de s'afficher correctement sur votre écran. <br/>
									Veuillez passer sur un ordinateur afin
									pouvoir afficher le tableau des utilisateurs et des clients.
								</Typography>
							</Paper>
						</Grid>
					}
					<Grid item xs={12} sm={12} lg={6}>
						<AddUser reloadTab={() => reloadUserslist()} passClients={clients} />
					</Grid>
					<Grid item xs={12} sm={12} lg={6}>
						<AddClient reloadTab={() => reloadClientslist()} passUsers={users} />
					</Grid>
				</Grid>
			</main>
		</div>
	);
}

export default AddCount
