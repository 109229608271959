import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ls from 'localstorage-slim';

import EffectifXemelios from '../components/effectif/effectifXemelios.jsx';
import EffectifDsn from '../components/effectif/effectifDsn.jsx';

import { Grid, 
         Typography,
		 Paper,
         Tabs,
         Tab
       } from '@material-ui/core';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		},
  	},
}));

const AntTabs = withStyles({
	root: {
	  backgroundColor: '#2d444f',
	  display: 'flex',
	  justifyContent: 'center'
	},
	indicator: {
	  backgroundColor: '#eb6c29',
	},
  })(Tabs);
  
  const AntTab = withStyles((theme) => ({
	root: {
	  textTransform: 'none',
	  minWidth: 72,
	  fontWeight: theme.typography.fontWeightRegular,
	  marginRight: theme.spacing(4),
	  fontFamily: 'AvantGardeBold',
	  fontSize: '1.5em',
	  color: 'white',
	  '&:hover': {
		color: '#eb6c29',
		opacity: 1,
	  },
	  '&$selected': {
		color: '#eb6c29',
		fontWeight: theme.typography.fontWeightMedium,
	  },
	  '&:focus': {
		color: '#eb6c29',
	  },
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Effectif = ({passingClient}) => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');
	const [client, setClient] = useState([]);
	const [stateOnglets, setStateOnglets] = useState(0);

	const getDecodedClient = () => {
		const client = ls.get('client', { decrypt: true })

		if(client){
			const decodedClient = JSON.parse(client);
			setClient(decodedClient);
		}
	}

	useEffect(() => {
		getDecodedClient();
	}, []);

	const handleChange = (event, newValue) => {
		setStateOnglets(newValue);
	};

  	return (
		<div className={classes.root}>
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={4} justify="center" alignItems="center">
						{responsiveDevice ?
							<AccessibilityNewIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Effectif salarial
						</Typography>
					</Grid>
				{responsiveDevice ? 
					<Grid item xs={12} lg={8}>
						<Paper>
							<AntTabs
								value={stateOnglets}
								onChange={handleChange}
								variant="fullWidth"
							>
								<AntTab label="Effectif xemelios" />
								<AntTab label="Effectif DSN" />
							</AntTabs>
						</Paper>
					</Grid>
				: null }
				</Grid>
				{stateOnglets === 0 && <EffectifXemelios passClient={client} />}
				{stateOnglets === 1 && <EffectifDsn passClient={client} />} 
			</main>
		</div>
 	);
}

export default Effectif
