import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import axios from "axios";
// import UserAPI from '../services/UserAPI';
import {SENDEMAIL} from "../config.js";

import { Button,
         TextField,
         Grid,
         Paper,
         Typography,
         Link
        } from '@material-ui/core';

import Logo from "../images/logogsrh2.png";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor: "#2d444f",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: "white",
        backgroundColor: "#66aec8",
        '&:hover': {
            backgroundColor: "#69aec4",
        },
        fontFamily:'AvantGardeBold',
        fontSize: '1.5em'

    },
    logoContainer: {
        width: 500,
        height: 500,
        margin: 'auto',
    },
    logo: {
        width: '100%',
        height: '100%',
        margin: 0 ,
        padding: 0,
    },
    title: {
        fontFamily:'AvantGardeBold'
    }
}));


const ForgetPassword = ({history}) => {
    const classes = useStyles();
    const [email, setEmail] = useState({email: ""});

    const handleChangeEmail = (event) => {
        setEmail({email: event.target.value});
    }

    const handleSubmitEmail = (event) => {
        event.preventDefault();
            axios.post(SENDEMAIL, email)
                        .then((response) => {
                            setEmail({email: ""});
                            toast.success('Vérifiez votre boîte mail. Un e-mail vous a été envoyé.');
                        })
                        .catch((error) => {
                            console.log(error);
                        })
    }

    return(
        <Grid container component="main" className={classes.root}>
            <Grid container item xs={false} sm={4} md={7} className={classes.image}>
                <Grid item className={classes.logoContainer} xs={false}>
                    <img src={Logo} alt="Logo GS'RH" className={classes.logo} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5" className={classes.title}>
                Demande de réinitialisation du mot de passe
                </Typography>
                <form className={classes.form} onSubmit={handleSubmitEmail}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username" 
                    label="Adresse email"
                    name="username" 
                    autoComplete="email"
                    type="email"
                    value={email.email}
                    onChange={handleChangeEmail}
                    // error= {errorMessage}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Valider
                </Button>
                <Grid container>
                    <Grid item>
                        <Link href="/#/accueil" variant="body2">
                            {"Retourner à la page de connexion"}
                    </Link>
                    </Grid>
                </Grid>
                </form>
            </div>
            </Grid>
        </Grid>
    )
}

export default ForgetPassword;