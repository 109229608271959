import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import UserAPI from '../../services/UserAPI';
import { toast } from "react-toastify";

import { Accordion,
         AccordionDetails,
         AccordionSummary,
         Typography,
         Grid,
         TextField,
         MenuItem,
         Button,
         Checkbox,
         FormControlLabel,
        } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontFamily: 'AvantGardeBold',
      textTransform: "uppercase",
      color: '#3492aa',
    },
    select: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '25ch',
        },
    },
    '.MuiButton-containedPrimary' : {
        backgroundColor: '#69aec4'
    },
    headerPanel: {
        backgroundColor: '#ebebeb',
    },
}));

const CreateUserButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#2d444f',
        '&:hover': {
            backgroundColor: '#3492aa',
        },
        marginRight: '2vw',
        fontFamily: 'AvantGardeBook',
        [theme.breakpoints.down('md')]: {
            marginRight: "5vw",
        },
    },
}))(Button);

const AddUser = ({passClients, reloadTab}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [idclient, setIdclient] = useState([]);
    const [role, setRole] = useState('');
    const [newUserProfil, setNewUserProfil] = useState({
        email: "",
        roles: [],
        password: "",
        prenom: "",
        nom: "",
        clients: []
    });

    const [errors, setErrors] = useState({
        email: "",
        roles: "",
        password: "",
        prenom: "",
        nom: ""
    });
    const [selectAll, setSelectAll] = useState(false);

    // Création d'un nouvel utilisateur
    const createNewUser = (event, param, option = null) => {
        if(param === "roles" || param === "clients"){
            if(param === "clients"){
                setIdclient(option);
                const idValue = [];

                option.forEach(id => {
                    idValue.push("/api/clients/" + id.idclient);
                })
                
                setNewUserProfil({...newUserProfil, [param] : idValue});
            } else {
                setRole(event.target.value);
                setNewUserProfil({...newUserProfil, [param] : [event.target.value]});
            }
        } else if(param === "all") {
            if(selectAll){
                setSelectAll(false);
                setNewUserProfil({...newUserProfil, clients: []});
            } else if(selectAll === false){
                setSelectAll(true);
                setIdclient([]);
                setNewUserProfil({...newUserProfil, clients: passClients});
            }
        } else {
            setNewUserProfil({...newUserProfil, [param] : event.target.value});
        }
    }

    // Soumission de la création de l'utilisateur
    const submitUser = async (event) => {
        event.preventDefault();
        
        try {
            let checkIfUserExist = await UserAPI.findUserByEmail({email: newUserProfil.email});

            if(checkIfUserExist === false) {
                const idClients = [];
                if(selectAll){
                    const value = newUserProfil.clients;
                    value.forEach(user => {
                        idClients.push("/api/clients/" + user.idclient);
                    })
                    newUserProfil.clients = idClients;
                }
                await UserAPI.postUser(newUserProfil);
                toast.success(`L'utilisateur ${newUserProfil.prenom} ${newUserProfil.nom} a été crée avec succès.`);
                setErrors({});  
                setNewUserProfil({});
                setIdclient([]);
                setRole();
                setSelectAll(false);
                reloadTab();
            } else {
                toast.error("L'email de l'utilisateur existe déjà.");
            }
        } catch(error) {
            const { violations } = error.response.data;
            if(violations){
                const apiErrors = {};
                violations.forEach(violation => {
                apiErrors[violation.propertyPath] = violation.message;
                });
                setErrors(apiErrors);
            }
            toast.error("Une erreur est survenue lors de la création de l'utilisateur.");
        }
    }
    // Fonction native de l'ouverture du panel
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.headerPanel}
            >
                <Typography className={classes.heading}>Ajouter un nouvel utilisateur</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                        <TextField
                            required
                            id="prenom"
                            name="prenom"
                            label="Prénom"
                            variant="outlined"
                            value={newUserProfil.prenom ? newUserProfil.prenom : ''}
                            helperText={errors.prenom}
                            onChange={(e) => createNewUser(e, 'prenom')}
                            style={{ width: "100%"}}
                        />
                    </Grid>   
                    <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                        <TextField
                            required
                            id="nom"
                            name="nom"
                            label="Nom"
                            variant="outlined"
                            value={newUserProfil.nom ? newUserProfil.nom : ''}
                            helperText={errors.nom}
                            onChange={(e) => createNewUser(e,'nom')}
                            style={{ width: "100%"}}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                        <TextField
                            required
                            type="email"
                            id="email"
                            name="email"
                            label="Adresse email"
                            variant="outlined"
                            value={newUserProfil.email ? newUserProfil.email : ''}
                            helperText={errors.email}
                            onChange={(e) => createNewUser(e,'email')}
                            style={{ width: "100%"}}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                        <TextField
                            id="outlined-password-input"
                            label="Mot de passe"
                            type="password"
                            name="password"
                            required
                            autoComplete="current-password"
                            variant="outlined"
                            value={newUserProfil.password ? newUserProfil.password : ''}
                            helperText={errors.password}
                            onChange={(e) => createNewUser(e,'password')}
                            style={{ width: "100%"}}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                        <TextField
                            style={{ width: "100%"}}
                            id="role"
                            select
                            required
                            name="roles"
                            label="Rôle accordé"
                            value={role ? role : ''}
                            onChange={(e) => createNewUser(e,'roles')}
                            helperText={errors.roles}
                            variant="outlined"
                        >
                            <MenuItem value="ROLE_ADMIN">
                                ADMINISTRATEUR
                            </MenuItem>
                            <MenuItem value="ROLE_USER">
                                UTILISATEUR
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} justify="center">
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={selectAll} name="all" onChange={(e) => createNewUser(e,"all")} />}
                                label="Sélectionner tous les clients"
                            />
                        </Grid>
                        {selectAll === false && 
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="clients"
                                    name="clients"
                                    options={passClients}
                                    value={idclient}
                                    getOptionLabel={(option) => option.nomClient}
                                    filterSelectedOptions
                                    disableCloseOnSelect
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Clients associés"
                                            style={{ width: "100%"}}
                                        />
                                    )}
                                    onChange={(e, option) => createNewUser(e, 'clients', option)}
                                />
                            </Grid>
                        }   
                    </Grid>
                    <Grid container item xs={12} sm={12} lg={12} justify="flex-end">
                        <CreateUserButton variant="contained" onClick={submitUser}>
                            Création de l'utilisateur
                        </CreateUserButton>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default AddUser;