import axios from "axios";
import {DELETERETURNEXCEL, 
        EXPORT_DETAIL_PAR_MOIS, 
        GET_FICHE_DE_PAIE,
        EXPORT_FICHE_DE_PAIE, 
        GET_MATRICULES,
        GET_YEARS_BY_MATRICULE, 
        GET_MONTH_BY_YEAR_AND_MATRICULE,
        EXPORT_BASE_AGENTS_XEMELIOS,
        GET_YEAR_DSN,
        GET_MONTH_BY_YEAR_DSN,
        EXPORT_BASE_AGENTS_DSN} from "../config.js";

function exportDetailParMois(param) {
    return axios({
        url: EXPORT_DETAIL_PAR_MOIS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Detail_Paie_Par_Mois_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

function getFicheDePaie(param) {
    return axios
    .post(GET_FICHE_DE_PAIE, param)
    .then(response => response.data);
}

function exportFicheDePaie(param) {
    return axios({
        url: EXPORT_FICHE_DE_PAIE,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Fiche_De_Paie_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

function getAllMatriculesByIdclient(param){
    return axios
    .post(GET_MATRICULES, param)
    .then((response) => response.data)
}

function getYearsByMatricule(param){
    return axios
    .post(GET_YEARS_BY_MATRICULE, param)
    .then((response) => response.data)
}

function getMonthByYearsAndMatricules(param){
    return axios
    .post(GET_MONTH_BY_YEAR_AND_MATRICULE, param)
    .then((response) => response.data)
}

function getYearsDsn(param){
    return axios
    .post(GET_YEAR_DSN, param)
    .then((response) => response.data)
}

function getMonthByYearDsn(param){
    return axios
    .post(GET_MONTH_BY_YEAR_DSN, param)
    .then((response) => response.data)
}
function exportXlsBaseAgentsXemelios(param) {
    return axios({
        url: EXPORT_BASE_AGENTS_XEMELIOS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Base_agents_xemelios_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

function exportXlsBaseAgentsDsn(param) {
    return axios({
        url: EXPORT_BASE_AGENTS_DSN,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Base_agents_dsn_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

export default {
    exportDetailParMois,
    getFicheDePaie,
    exportFicheDePaie,
    exportXlsBaseAgentsXemelios,
    getAllMatriculesByIdclient,
    getYearsByMatricule,
    getMonthByYearsAndMatricules,
    getYearsDsn,
    getMonthByYearDsn,
    exportXlsBaseAgentsDsn
} 