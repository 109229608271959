import axios from "axios";
import {GRADECORRESP_API, SORTEDNOMENCLATUREGRADE, EXPORT_GRADE_XLS, DELETERETURNEXCEL} from "../config";

function findAllGrades(param1){
    return axios
        .get(GRADECORRESP_API + '?idclient.idclient=' + param1)
        .then(response => response.data['hydra:member']);
}

function findAllNomenclatures(){
    return axios
        .get(SORTEDNOMENCLATUREGRADE)
        .then(response => response.data);
}

function fetchOneGrade(param){
    return axios
        .get(GRADECORRESP_API + "/" + param)
        .then(response => response.data);
}

function changeGrade(param1, param2){
    return axios
        .put(GRADECORRESP_API + "/" + param1, param2);
}

function exportGrade(param){
    return axios({
        url: EXPORT_GRADE_XLS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Grade_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

export default {
    findAllNomenclatures,
    findAllGrades,
    fetchOneGrade,
    changeGrade,
    exportGrade
}