import axios from "axios";
import {DISPLAYEFFECTIFXEMELIOS, DELETEEFFECTIFXEMELIOS, DISPLAYEFFECTIFDSN, DELETEEFFECTIFDSN} from "../config.js"; 

function sendIdForEffectifXemelios(param){
    return axios
        .post(DISPLAYEFFECTIFXEMELIOS, param)
        .then(response => response.data);
}

function sendIdForEffectifDsn(param){
    return axios
        .post(DISPLAYEFFECTIFDSN, param)
        .then(response => response.data);
}

function sendIdEmployeurForDeleteXemelios(param1, param2, param3){
    return axios
        .post(DELETEEFFECTIFXEMELIOS, [param1, param2, param3])
        .then(response => response.data);
}

function sendIdEmployeurForDeleteDsn(param1, param2){
    return axios
        .post(DELETEEFFECTIFDSN, [param1, param2])
        .then(response => response.data);
}

export default {
    sendIdForEffectifXemelios,
    sendIdEmployeurForDeleteXemelios,
    sendIdForEffectifDsn,
    sendIdEmployeurForDeleteDsn
}