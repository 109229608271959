import React, {useState, useMemo, useEffect, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {toast} from 'react-toastify';
import {UPLOADEXCEL, EXCELTODB, EXCELDISPLAYFILE, DELETEEXCELFILE} from "../../config.js";

import {Button,
        ListItem,
        ListItemIcon,
        ListItemText,
        Tooltip,
        Typography,
		Grid,
		Dialog,
		Slide,
		CircularProgress,
		DialogContent,
		DialogContentText,
        } from '@material-ui/core';

import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: 20,
	borderWidth: 2,
	borderRadius: 10,
	borderStyle: 'dashed',
	backgroundColor: '#ebebeb',
	color: '#2d444f',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	//height: 400,
	verticalAlign: "middle",
};
  
const activeStyle = {
	borderColor: '#3492aa'
};

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	container: {
		marginTop: 25,
		marginBottom: 25,

	},
	iconstyle: {
		fontSize: 48, 
		color: '#eb6c29',
	},
	liststyle: {
		border: '1px solid #4b4644',
		borderRadius: 10,
		marginBottom: 25
	},
	title: {
		fontFamily: 'AvantGardeBold',
		marginLeft: 15,
		color: '#2d444f'
	},
	fontstyle: {
		fontFamily: 'AvantGardeBook'
	},
	deleteicon: {
		color: '#eb6c29',
	}
}));

const TraitementButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		fontSize: '1.5em',
		width: 500,
	},
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DropzoneOtherBase = ({passClient, whichTab, idUser}) => {
    const classes = useStyles();
    const fullWidth = true;
    const maxWidth = 'sm';

	const [allFiles, setAllFiles] = useState([]);
    const [addedFiles, setAddedFiles] = useState(false);
	const [send, setSend] = useState(false);
	const [open, setOpen] = useState(false);
    
    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
			multiple: true,
			onDrop: async files => {
				let formdata = new FormData();
				files.forEach(file => {
					formdata.append('fichier[]', file);
					formdata.append('whichTab', whichTab);
				})

				let requestOptions = {
					method: 'POST',
					body: formdata,
					redirect: 'follow',
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Content-Type': 'multipart/form-data'
					}
				};
		
				try {
					formdata.append("idUser", idUser);
					await axios.post(UPLOADEXCEL, formdata, requestOptions)
							.then(response => {
								if(response.data[0].id){
									setAddedFiles(!addedFiles);    
								}
								response.data[0].id ? 
									toast.success("Vos fichiers ont bien été déposés. En attente d'envoi en base de données.")
								: toast.error("Fichier(s) non-valide(s). Veuillez déposer des fichiers xlsx.");
								});
				} catch(error) {
					if(error.response){
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if(error.request) {
						console.log(error.request);
						toast.warning("Attention, problème de requête.");
					} else {
						console.log('Erreur', error.message);
						toast.error("Il y a eu une erreur lors du dépôt de vos fichiers.");
					}
				}
			}
		});

	const fetchAllFilename = useCallback((idUser) => {
		if(idUser !== ""){
			axios.post(EXCELDISPLAYFILE, {directory: whichTab, idUser: idUser})
			.then((response) => {
				setAllFiles(response.data);
			})
			.catch((erreur) => {
				console.log(erreur);
			})
		}
	}, [whichTab]);

    useEffect(() => {
		fetchAllFilename(idUser);
    }, [addedFiles, send, idUser, fetchAllFilename]);

    const importExcelFile = () => {
		handleClickOpen();
        axios.post(EXCELTODB, {idclient: passClient.idclient, directory: whichTab, idUser: idUser})
			.then((response) => {
				handleClose();
				setSend(!send);
				toast.success("Mise à jour de la base de données avec succès.");
			})
			.catch((erreur) => {
				console.log(erreur);
			})
    }

    // Suppression des fichiers 
    const sendDeleteAction = param => {
      	axios.post(DELETEEXCELFILE, {id: param, directory: whichTab, idUser: idUser})
            .then((response) => {
              setAddedFiles(!addedFiles);
            })
            .catch((erreur) => {
              console.log(erreur);
            })
	}
	
	const handleClickOpen = () => {
		setOpen(true);
	};
  
	const handleClose = () => {
		setOpen(false);
	};

    const displayFiles = allFiles.map(file => (
		<ListItem key={file.id} >
			<ListItemText className={classes.fontstyle}>{file.name}.{file.ext}</ListItemText>
			<ListItemText className={classes.fontstyle}>{file.size}</ListItemText>
			<Tooltip title="Supprimer">
				<ListItemIcon>
					<DeleteIcon className={classes.deleteicon} id={file.id} onClick={() => sendDeleteAction(file.id)} />
				</ListItemIcon>
			</Tooltip>
		</ListItem>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        }), [isDragActive]
    );

    return (
		<Grid container style={{margin: 10, padding: 5}}>
			<Grid item lg={12} md={12} sm={12} xs={12} className={classes.container}>
				<div {...getRootProps({style})}>
					<input {...getInputProps()} />
					{!isDragActive && 
						(<>
							<AddIcon className={classes.iconstyle} />
							<p className={classes.fontstyle}>Cliquez pour sélectionner les fichiers ou faites-les glisser ici</p>
						</>)
					}
					{isDragActive && 
						(<>
							<MoveToInboxIcon className={classes.iconstyle} />
							<p className={classes.fontstyle}> Dépot...</p>
						</>)
					}
				</div>
			</Grid>

			<Grid item lg={12} md={12} sm={12} xs={12} className={classes.liststyle}>
				<Typography variant="h6" className={classes.title}>
					Liste des fichiers déposés :
				</Typography>
				{displayFiles}
			</Grid>
			<Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justify="center">
				<TraitementButton disabled={passClient.idclient && allFiles.length > 0 ? false : true } variant="contained" onClick={() => importExcelFile()}>Mise à jour de la base de données</TraitementButton>
			</Grid>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				TransitionComponent={Transition}
				onClose={handleClose}
				disableBackdropClick={true}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<center>
						<CircularProgress />
						<br/><br/>
						<DialogContentText className={classes.fontstyle} id="alert-dialog-slide-description">
							Importation des nouvelles lignes en cours. <br/>
							Veuillez patienter.
						</DialogContentText>
					</center>
				</DialogContent>
			</Dialog>
		</Grid>
    )
}

export default DropzoneOtherBase