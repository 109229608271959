import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import MetierAPI from '../../services/MetierAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Paper,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TablePagination,
        TableRow,
		TableSortLabel,
        FormGroup,
        FormControlLabel,
        Switch,
        TextField,
        Button,
        Grid,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        Slide,
		Typography,
		DialogContentText,
        CircularProgress
    } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchBar from "material-ui-search-bar";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
    root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
    },
	paperRoot: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		//marginTop: 16,
		},
	},
    container: {
      [theme.breakpoints.up('xl')]: {
        maxHeight: 625,
      },
      [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
      },
    },
    tableheader: {
      textTransform: "uppercase",
      fontFamily: 'AvantGardeBold',
      color: '#3492aa',
      backgroundColor: '#ebebeb',
    },
    formControlLabel: {
      fontFamily: 'AvantGardeBook'
    },
    selectclient: {
      '& .MuiTextField-root': {
        width: 200,
      },
    },
     labelstyle: {
      fontFamily: 'AvantGardeBook',
    },
    search: {
      float: 'right',
	},
	textfieldSize: {
		width:"100%"
	},
	itembgColor: {
		color: "#3492aa",
		opacity: '0.5'
	},
	warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const SwitchCustom = withStyles({
  switchBase: {
    color: '#3492aa',
    '&$checked': {
      color: '#3492aa',
    },
    '&$checked + $track': {
      backgroundColor: '#3492aa',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ValidationParamButton = withStyles({
	root: {
	  color: '#fff',
	  backgroundColor: '#2d444f',
	  '&:hover': {
		backgroundColor: '#3492aa',
	  },
	  fontFamily: 'AvantGardeBold',
	  float: 'right'
	},
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
	{ id: 'metierXml', label: 'Libellé Métier XML', minWidth: 200 },
	{ id: 'metierEspelia', label: 'Métier Espelia', minWidth: 300 },
	{ id: 'libelleMetier', label: 'Libellé du métier', maxWidth: 50 },
	{ id: 'fonctionEspelia', label: 'Fonction Espelia', maxWidth: 50 },
];

const Metier = ({passClient}) => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

	/* Pagination du tableau */
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [displayAll, setDisplayAll] = useState(false);

	const [allMetiers, setAllMetiers] = useState([]);
	const [nomenclatureMetier, setNomenclatureMetier] = useState([]);
	const [selectedMetierRow, setSelectedMetierRow] = useState({});

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('metierXml');
	const [send, setSend] = useState(false);
	const [open, setOpen] = useState(false);
	const [openExport, setOpenExport] = useState(false);
	const [metierToSend, setMetierToSend] = useState([]);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

	const handleChangeDisplay = (event) => {
		setDisplayAll(!displayAll);
    }

	useEffect(() => {
		if(displayAll){
			setRows(allMetiers);
		} else {
			const filteredRows = allMetiers.filter((row) => {
				let rowToShow;
				if(!row.metierEspelia){
					rowToShow = row;
				}
				return rowToShow;
			});
			setRows(filteredRows);
		}
	}, [displayAll, allMetiers]);

	const requestSearch = (searchedVal) => {
        const filteredRows = allMetiers.filter((row) => {
			let searchRequest;
			if(displayAll){
				searchRequest = row.metierXml.toLowerCase().includes(searchedVal.toLowerCase());
			} else {
				if(!row.metierEspelia){
					searchRequest = row.metierXml.toLowerCase().includes(searchedVal.toLowerCase());
				}
			}
			return searchRequest;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

	const fetchAllMetiers = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseMetier = await MetierAPI.findAllMetiers(passClient);
				setAllMetiers(responseMetier);
			} catch(error) {
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération des métiers.');
			}
		}
	}, []);

	const fetchNomenclatureMetier = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseNomenclatureMetier = await MetierAPI.findAllNomenclatures();
				setNomenclatureMetier(responseNomenclatureMetier);
			} catch(error) {
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération de la nomenclature des métiers.');
			}
		}
	}, []);

    useEffect(() => {
		if(passClient !== null){
			fetchAllMetiers(passClient.idclient);
			fetchNomenclatureMetier(passClient.idclient);
		}
    }, [send, passClient, fetchAllMetiers, fetchNomenclatureMetier]);

	/* Envoie en bdd */
	const handleSubmit = async (param) => {
		if(param === "update"){
			try {
				delete selectedMetierRow.idclient;
				await MetierAPI.changeMetier(selectedMetierRow.id, selectedMetierRow);
				handleClose();
				// setSend(!send);
			} catch(error){
				toast.error('Une erreur est survenue.');
			}
			toast.success("Enregistrement avec succès");
			setSelectedMetierRow({});
			setSend(!send);
		} else {
			metierToSend.forEach(async (metier) => {
				try {
					delete metier.idclient;
					await MetierAPI.changeMetier(metier.id, metier);
					setSend(!send);
				} catch(error){
					toast.error('Une erreur est survenue.');
				}
				toast.success("Enregistrement avec succès");
				setSend(!send);
			})
			setMetierToSend([]);
		}
	}

	const handleChange = (e, param1, param2) => {
		if(e.target.type === "textarea"){
			if(param1 === "update"){
				setSelectedMetierRow({...selectedMetierRow, [e.target.id]: e.target.value});
			} else {
				if(metierToSend.some(metier => metier.id === param1.id)){
					setMetierToSend([...metierToSend].map(object => {
						if(object.id === param1.id){
							return {
							...object, [e.target.id]: e.target.value
							}
						}
						else return object;
					}))
				} else {
					let changedMetier = Object.assign({}, param1);
					changedMetier[e.target.id] = e.target.value;
					setMetierToSend([...metierToSend, changedMetier]);
				}
			}
		} else {
			if(param2 === "update"){
				setSelectedMetierRow({...selectedMetierRow, metierEspelia: param1});
			} else {
				//* Vérifie si l'objet est présent dans le tableau
				if(metierToSend.some(metier => metier.id === param2.id)){
					if(param1 !== null){
						setMetierToSend([...metierToSend].map(object => {
							if(object.id === param2.id){
								return {
								...object, metierEspelia: param1
								}
							}
							else return object;
						}))
					} else {
						setMetierToSend(current =>
							current.filter(item => {
								// 👇️ remove object
								return item.id !== param2.id;
							}),
						);
					}
				} else {
					//* Duplique l'objet sans créer une référence à l'object original
					let changedMetier = Object.assign({}, param2);
					changedMetier.metierEspelia = param1;
					setMetierToSend([...metierToSend, changedMetier]);
				}
			}
		}
	}

	const exportMetier = async () => {
		if(passClient.idclient){
			handleClickOpenDialog("export");
			try {
				await MetierAPI.exportMetier({idclient: passClient.idclient});
				handleClose("export");
			} catch(error){
				console.log(error);
				handleClose("export");
				toast.error('Une erreur est survenue lors de l\'export des métiers.');
			}
		}
	}

	const handleChangePage = (event, newPage) => {
		event.preventDefault();
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	/* Gestion de la modale */
	const handleClickOpenDialog = (param) => {
		if(param === "export"){
			setOpenExport(true);
		} else {
			setOpen(true);
		}
	};

    const handleClose = (param) => {
		if(param === "export"){
			setOpenExport(false);
		} else {
			setOpen(false);
			setSelectedMetierRow([]);
		}
    };

	const handleRowClick = (event, param) => {
		event.preventDefault();
		handleClickOpenDialog("edit");
		setSelectedMetierRow(param);
    }

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

    return (
		<div className={classes.root}>
		{passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
							<SettingsIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Paramétrage des métiers
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={3} justify="space-between" alignItems="flex-end">
					<Grid item container justify="flex-start" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={exportMetier}> 
							Export des métiers
						</ValidationParamButton> 
					</Grid> 
					<Grid item container justify="flex-end" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={handleSubmit}> 
							Enregistrer les modifications 
						</ValidationParamButton> 
					</Grid>
					<Grid item sm={12} md={12} lg={12}> 
						<FormGroup row>
							<FormControlLabel
								control={
									<SwitchCustom 
										onChange={handleChangeDisplay} 
										name="displayAll"
									/>
								}
								label={<span className={classes.labelstyle}>Tout afficher</span>}
							/>
						</FormGroup>
					</Grid>
					<Paper className={classes.paperRoot}>
						<SearchBar
							placeholder="Rechercher un métier"
							value={searched}
							onChange={(searchVal) => requestSearch(searchVal)}
							onCancelSearch={() => cancelSearch()}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow>
									{columns.map((column) => (
										<TableCell
											className={classes.tableheader}
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
											sortDirection={order}
										>
											<TableSortLabel
												active={orderBy === column.id}
												direction={orderBy === column.id ? order : 'asc'}
												onClick={(e) => handleRequestSort(e, column.id)}
											>
												{column.label}
											</TableSortLabel>
										</TableCell>
									))}
									</TableRow>
								</TableHead>
								<TableBody>
									{stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(metier => (
										<TableRow 
											key={metier.id}
											hover={metier.metierEspelia != null ? true : false}
											onClick={metier.metierEspelia ? (event) => handleRowClick(event, metier) : null}
										>
											<TableCell>{metier.metierXml}</TableCell>
											<TableCell>
												{metier.metierEspelia ?
													metier.metierEspelia.libelle
												: 
													<Autocomplete
														filterSelectedOptions
														options={nomenclatureMetier.sort((a, b) => -b.specialiteNiv2.localeCompare(a.specialiteNiv2))}
														value={metier.metierEspelia}
														getOptionLabel={(option) => option.libelle}
														getOptionSelected={(option, value) => option.libelle === value.libelle}
														groupBy={(option) => option.specialiteNiv2}
														id="selectNomenclatureMetier"
														onChange={(e, option) => handleChange(e, option, metier)}
														style={{width: "100%"}}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																label="Nomenclature métier"
															/>
														)}
													/>
												}
											</TableCell>
											<TableCell>
												{metier.libelleMetier ?
													metier.libelleMetier
												: 
													<TextField
														id="libelleMetier"
														multiline
														rows={3}
														fullWidth
														variant="outlined"
														onClick={(e) => e.stopPropagation()}
														onChange={(e) => handleChange(e, metier)}
													/>
												}
											</TableCell>
											<TableCell>
												{metier.fonctionEspelia ?
													metier.fonctionEspelia
												: 
													<TextField
														id="fonctionEspelia"
														multiline
														fullWidth
														rows={3}
														variant="outlined"
														onClick={(e) => e.stopPropagation()}
														onChange={(e) => handleChange(e, metier)}
													/>
												}
											</TableCell>
										</TableRow>
									))
								}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							labelRowsPerPage="Lignes par pages:"
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
							rowsPerPageOptions={[50, 100, 150]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							backIconButtonProps={{
								"aria-label": "Page précédente"
							}}
							nextIconButtonProps={{
								"aria-label": "Page suivante"
							}}

							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Paper>
					<Dialog
						open={open}
						TransitionComponent={Transition}
						keepMounted
						fullWidth={true}
						maxWidth="sm"
						onClose={() => handleClose("edit")}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle id="alert-dialog-slide-title" className={classes.titleModale}><center>Modification du métier</center></DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										value={selectedMetierRow.metierXml ? selectedMetierRow.metierXml : ""}
										variant="outlined"
										fullWidth
										label="Libellé Métier XML"
										InputProps={{readOnly: true}}
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Autocomplete
										disableClearable
										filterSelectedOptions
										options={nomenclatureMetier.sort((a, b) => -b.specialiteNiv2.localeCompare(a.specialiteNiv2))}
										value={selectedMetierRow.metierEspelia ? selectedMetierRow.metierEspelia : null}
										getOptionLabel={(option) => option.libelle}
										getOptionSelected={(option, value) => option.libelle === value.libelle}
										groupBy={(option) => option.specialiteNiv2}
										id="selectNomenclatureMetier"
										onChange={(e, option) => handleChange(e, option, "update")}
										style={{width: "100%"}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Nomenclature métier"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="libelleMetier"
										label="Libellé du métier"
										multiline
										fullWidth
										rows={3}
										variant="outlined"
										InputLabelProps={{shrink: true}}
										value={selectedMetierRow.libelleMetier}
										onChange={(e) => handleChange(e, "update")}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="fonctionEspelia"
										label="Fonction Espelia"
										multiline
										fullWidth
										rows={3}
										variant="outlined"
										InputLabelProps={{shrink: true}}
										value={selectedMetierRow.fonctionEspelia}
										onChange={(e) => handleChange(e, "update")}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<ValidationParamButton onClick={() => handleClose("edit")}>
								Annuler
							</ValidationParamButton> 
							<ValidationParamButton onClick={() => handleSubmit("update")}>
								Modifier
							</ValidationParamButton>      
						</DialogActions>
					</Dialog>
					<Dialog
						fullWidth={true}
						maxWidth="sm"
						open={openExport}
						onClose={() => handleClose("export")}
						TransitionComponent={Transition}
						disableBackdropClick={true}
						aria-labelledby="alert-dialog-infodropzone"
						aria-describedby="alert-dialog-infodropzone"
					>
						<DialogContent>
							<center>
								<CircularProgress />
								<DialogContentText id="alert-dialog-send-file">
									Export des métiers en cours. <br/>
									Ceci peut prendre quelques minutes.<br/>
									Vous serez averti lorsque le processus sera terminé.
								</DialogContentText>
							</center>
						</DialogContent>
					</Dialog>
				</Grid>
			</main>
		}
		{!passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid item container justify="flex-start" sm={12} md={12} lg={12}>
					<Paper className={classes.warningPaper}>
						<Typography variant="body1">
							Pour visualiser et paramétrer les métiers, vous devez sélectionner un client
						</Typography>
					</Paper>
				</Grid>
			</main>
		}
		</div>
    )
}

export default Metier;