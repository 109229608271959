import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';

import {
	Typography,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogContentText,
	Slide,
	Grid,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import AddIcon from '@material-ui/icons/Add';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 10,
	borderStyle: 'dashed',
	backgroundColor: '#ebebeb',
	color: '#2d444f',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	//height: 400,
	verticalAlign: "middle",
};

const activeStyle = {
	borderColor: '#3492aa'
};


const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	container: {
		marginTop: '25px'
	},
	iconstyle: {
		fontSize: 48,
		color: '#eb6c29',
	},
	liststyle: {
		border: '1px solid #2d444f',
		borderRadius: 5
	},
	fontstyle: {
		fontFamily: 'AvantGardeBook',

	},
	title: {
		fontFamily: 'AvantGardeBold',
		marginLeft: 15,
		color: '#2d444f'
	},
	deleteicon: {
		color: '#eb6c29',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DropZone = ({ type, sendToController, directory, sendDeleteAction, allFiles, handleClickOpen, handleClose, open, countingFiles }) => {
	const classes = useStyles();
	const fullWidth = true;
	const maxWidth = 'sm';

	const {
		getRootProps,
		getInputProps,
		isDragActive,
	} = useDropzone({
		multiple: true,
		maxFiles: (12 - allFiles.length),
		disabled: allFiles.length >= 12 ? true : false,
		onDrop: async files => {
			handleClickOpen("dropzone");
			countingFiles(files.length);

			for (let file of files) {
				let formdata = new FormData();
				formdata.append('fichier', file);
				formdata.append('whichDirectory', directory)
				sendToController(formdata);
			}
		},
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
	}), [isDragActive]);

	return (
		<>
			<Grid item className={classes.container}>
				<div {...getRootProps({ style }, { className: 'dropzone disabled' })}>
					<input {...getInputProps()} />
					{!isDragActive &&
						(<>
							<AddIcon className={classes.iconstyle} />
							<p className={classes.fontstyle}>Cliquez pour sélectionner les fichiers ou faites-les glisser ici </p>
						</>)
					}
					{isDragActive &&
						(<>
							<MoveToInboxIcon className={classes.iconstyle} />
							<p className={classes.fontstyle}> Dépot...</p>
						</>)
					}
				</div>
			</Grid>
			<br />
			<Grid item>
				<Typography>
					<span style={{ color: "red", fontWeight: "bold" }}>Attention : Une fois que vous aurez lancé le traitement de vos {type === 'xemelios' ? 'XEMELIOS' : 'DSN'}, aucun nouvel import ne sera possible avant la fin de leur traitement.</span>
					<br />
					<br />

					<i>1 : Cliquez pour sélectionner ou déposer tous les fichiers {type === 'xemelios' ? 'XEMELIOS' : 'DSN'} que vous souhaitez importer (pas de limite de taille et de nombre).</i>
					<br />

					<br />
					<i>2 : Cliquez sur ‘Traitement des fichiers’. Une fois lancé vous ne pourrez plus faire de nouvel import durant le traitement des fichiers.</i>
					<br />

					<br />
					<i>3 : Une fois les fichiers traités vous recevrez un email avec des informations sur l’import de vos fichiers. Cet email contient une pièce jointe excel.</i>
				</Typography>
			</Grid>
			<br />
			<Grid item className={classes.liststyle}>
				<Typography variant="h6" className={classes.title}>
					Liste des fichiers déposés :
				</Typography>
				{allFiles.map(file => (
					<ListItem key={file.id} >
						<ListItemText className={classes.fontstyle}>{file.name}.{file.ext}</ListItemText>
						<ListItemText className={classes.fontstyle}>{file.size}</ListItemText>
						<Tooltip title="Supprimer">
							<ListItemIcon>
								<DeleteIcon className={classes.deleteicon} id={file.id} onClick={() => sendDeleteAction(file.id)} />
							</ListItemIcon>
						</Tooltip>
					</ListItem>
				))}
			</Grid>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				TransitionComponent={Transition}
				disableBackdropClick={true}
				onClose={() => handleClose("dropzone")}
				aria-labelledby="alert-dialog-slide-dropzone"
				aria-describedby="alert-dialog-slide-dropzone"
			>
				<DialogContent>
					<center>
						<CircularProgress />
						<br /><br />
						<DialogContentText className={classes.fontstyle} id="alert-dialog-depot">
							Dépôt de vos fichiers en cours. <br />
							Veuillez patienter.
						</DialogContentText>
					</center>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default DropZone 