import React, {useState, useEffect} from 'react';
import {HashRouter, Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import ls from 'localstorage-slim';

import Home from './templates/home';
import DropFileXemelios from './templates/dropFileXemelios';
import DropFileDns from './templates/dropFileDNS';
import AddCount from './templates/addCount';
import OtherBase from './templates/otherBase';
import LoginPage from './templates/loginPage';
import ForgetPassword from './templates/forgetPassword';
import Effectif from './templates/effectif';
import AuthAPI from './services/AuthAPI';
import AuthContext from './contexts/AuthContext'
import PrivateRoute from './components/privateRoute';
import FicheAgent from './templates/ficheAgent'
import Exports from './templates/exports'

import Grade from './components/onglets/grade';
import CodePaie from './components/onglets/codePaie';
import Service from './components/onglets/service';
import Metier from './components/onglets/metier';
import Echelon from './components/onglets/echelon';

import Navbar from './components/navbar/navbar';
import ScrollIntoView from "./components/scrollIntoView";

import Box from '@material-ui/core/Box';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

AuthAPI.setup();

const App = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
	const [access, setAccess] = useState();
	const NavbarWithRouter = withRouter(Navbar);
	const contextValue = {isAuthenticated, setIsAuthenticated};

	useEffect(() => {
		setAccess(AuthAPI.getDecodedToken());

		const fetchClientsOnAuth = async () => {
			await AuthAPI.getUserClients();

			let fetchDecodedClient = AuthAPI.getDecodedClient();

			if(fetchDecodedClient[0] !== null){
				setClients(fetchDecodedClient[0]); 
			}

			if(fetchDecodedClient[1] !== null){
				setPassingClient(fetchDecodedClient[1]);
			}
		}
		if(isAuthenticated) {
			fetchClientsOnAuth();
		}
	}, [isAuthenticated]);

	const [passingClient, setPassingClient] = useState(null);
	const [clients, setClients] = useState(ls.get('clients', { decrypt: true }) || []);
	const [reload, setReload] = useState(false);

	const DropFileXemeliosComponent = () => {
		return <DropFileXemelios passingClient={passingClient} />
	}
  
	const DropFileDnsComponent = () => {
		return <DropFileDns passingClient={passingClient} />
	}

	const FicheAgentComponent = () => {
		return <FicheAgent passingClient={passingClient} />
	}

	const OtherBaseComponent = () => {
		return <OtherBase passingClient={passingClient} />
	}

	const GradeComponent = () => {
		return <Grade passClient={passingClient} />
	}

	const CodePaieComponent = () => {
		return <CodePaie passClient={passingClient} />
	}

	const ServiceComponent = () => {
		return <Service passClient={passingClient} />
	}

	const MetierComponent = () => {
		return <Metier passClient={passingClient} />
	}

	const EchelonComponent = () => {
		return <Echelon passClient={passingClient} />
	}

	const EffectifComponent = () => {
		return <Effectif passingClient={passingClient} />
	}

	const ExportsComponent = () => {
		return <Exports passingClient={passingClient} />
	}
	
	const reloadComponent = () => {
		setReload(!reload);
	}

	const AddCountComponent = () => {
		return <AddCount reload={reloadComponent} />
	}
  
	return (
		<AuthContext.Provider value={contextValue}>
		<HashRouter>
		{isAuthenticated ? <NavbarWithRouter setPassingClient={setPassingClient} passingClient={passingClient} clientsArr={clients} reload={reloadComponent} /> : null }
			<Box>
				<ScrollIntoView>
				<Switch>
					<Route exact path="/seconnecter" component={LoginPage} />
					<Route exact path="/motdepasseoublie" component={ForgetPassword} />
					<PrivateRoute exact path="/accueil" component={Home} />
					<PrivateRoute exact path="/depotXemelios" component={DropFileXemeliosComponent} />
					<PrivateRoute exact path="/depotDns" component={DropFileDnsComponent} />
					<PrivateRoute exact path="/grades" component={GradeComponent} />
					<PrivateRoute exact path="/codespaie" component={CodePaieComponent} />
					<PrivateRoute exact path="/services" component={ServiceComponent} />
					<PrivateRoute exact path="/metiers" component={MetierComponent} />
					<PrivateRoute exact path="/echelons" component={EchelonComponent} />
					<PrivateRoute exact path="/effectif" component={EffectifComponent} />
					<PrivateRoute exact path="/ficheAgent" component={FicheAgentComponent} />
					<PrivateRoute exact path="/autresbases" component={OtherBaseComponent} />
					<PrivateRoute exact path="/exports" component={ExportsComponent} />
					{access ? 
						<PrivateRoute exact path="/compte" component={AddCountComponent} />
					:
						<Redirect to="/accueil" />
					}
					<Redirect to="/accueil" />
				</Switch>
				</ScrollIntoView>
			</Box>
			<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} newestOnTop />
		</HashRouter>
		</AuthContext.Provider>
  	);
}

export default App;
