import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import GradeAPI from '../../services/GradeAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {Paper,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TablePagination,
		TableSortLabel,
        TableRow,
        FormGroup,
        FormControlLabel,
        Switch,
        TextField,
        Button,
        Grid,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        Slide,
		Typography,
		DialogContentText,
        CircularProgress
        } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchBar from "material-ui-search-bar";
import SettingsIcon from '@material-ui/icons/Settings';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
    },
	paperRoot: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
		fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
		fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		//marginTop: 16,
		},
	},
    container: {
		[theme.breakpoints.up('xl')]: {
		maxHeight: 640,
		},
		[theme.breakpoints.down(1900)]: {
		maxHeight: 440,
		},
    },
    tableheader: {
		textTransform: "uppercase",
		fontFamily: 'AvantGardeBold',
		color: '#3492aa',
		backgroundColor: '#ebebeb',
    },
    labelstyle: {
      	fontFamily: 'AvantGardeBook',
    },
    search: {
      	float: 'right',
    },
	warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const SwitchCustom = withStyles({
  	switchBase: {
		color: '#3492aa',
		'&$checked': {
		color: '#3492aa',
		},
		'&$checked + $track': {
		backgroundColor: '#3492aa',
		},
  },
  checked: {},
  track: {},
})(Switch);

const ValidationParamButton = withStyles({
  	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
 	},
})(Button);

const columns = [
	{ id: 'gradeXml', label: 'Grade XML', minWidth: 150 },
	{ id: 'gradeEspelia', label: 'Grade Espelia', minWidth: 150 },
	{ id: 'cat', label: 'Catégorie',  minWidth: 100, align: 'center'},
	{ id: 'ce', label: 'CE', minWidth: 170, align: 'center'},
	{ id: 'filiere', label: 'Filière', minWidth: 170, align: 'center'},
];

const Grade = ({passClient}) => {
    const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

    /* Pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
	const [displayAll, setDisplayAll] = useState(false);

	const [allGrades, setAllGrades] = useState([]);
    const [nomenclatureGrade, setNomenclatureGrade] = useState([]);
    const [selectedGradeRow, setSelectedGradeRow] = useState({});
	const [gradeToSend, setGradeToSend] = useState([]);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

    /* Rechargement de la page à l'envoie */
    const [send, setSend] = useState(false);
    /* Ordre d'affichage */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('gradeXml');
    const [open, setOpen] = useState(false);
    const [openExport, setOpenExport] = useState(false);

	const handleChangeDisplay = (event) => {
		setDisplayAll(!displayAll);
    }

	useEffect(() => {
		if(displayAll){
			setRows(allGrades);
		} else {
			const filteredRows = allGrades.filter((row) => {
				let rowToShow;
				if(!row.gradeEspelia){
					rowToShow = row;
				}
				return rowToShow;
			});
			setRows(filteredRows);
		}
	}, [displayAll, allGrades]);

	const requestSearch = (searchedVal) => {
        const filteredRows = allGrades.filter((row) => {
			let searchRequest;
			if(displayAll){
				searchRequest = row.gradeXml.toLowerCase().includes(searchedVal.toLowerCase());
			} else {
				if(!row.gradeEspelia){
					searchRequest = row.gradeXml.toLowerCase().includes(searchedVal.toLowerCase());
				}
			}
			return searchRequest;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

	const fetchAllGrades = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseGrades = await GradeAPI.findAllGrades(passClient);
				setAllGrades(responseGrades);
			} catch(error) {
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération des grades.');
			}
		}
	}, []);

    const fetchNomenclatureGrade = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseNomenclatureGrade = await GradeAPI.findAllNomenclatures();
				setNomenclatureGrade(responseNomenclatureGrade.data);
			} catch(error) {
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération de la nomenclature des grades.');
			}
		}
	}, []);

	useEffect(() => {
		if(passClient !== null){
			fetchAllGrades(passClient.idclient);
			fetchNomenclatureGrade(passClient.idclient);
		}
    }, [passClient, send, fetchAllGrades, fetchNomenclatureGrade]);

	const handleRowClick = (event, param) => {
		event.preventDefault();
		handleClickOpenDialog("edit");
		setSelectedGradeRow(param);		
    }

	const handleChange = (e, param1, param2) => {
		if(param2 === "update"){
			setSelectedGradeRow({...selectedGradeRow, gradeEspelia: param1});
		} else {
			if(gradeToSend.some(grade => grade.idgrade === param2.idgrade)){
				if(param1 !== null){
					setGradeToSend([...gradeToSend].map(object => {
						if(object.idgrade === param2.idgrade){
							return {
							...object, gradeEspelia: param1.grade_lib, gbGa: param1.gb_ga, ce: param1.ce_lib, cat: param1.cat, gradeId: param1.grade_id, filiere: param1.filiere_lib
							}
						}
						else return object;
					}))
				} else {
					setGradeToSend(current =>
						current.filter(item => {
						  	// 👇️ remove object
						  	return item.idgrade !== param2.idgrade;
						}),
					);
				}
			} else {
				//* Duplique l'objet sans créer une référence à l'object original
				let changedGrade = Object.assign({}, param2);
				changedGrade.gradeEspelia = param1.grade_lib;
				changedGrade.gbGa = param1.gb_ga;
				changedGrade.ce = param1.ce_lib; 
				changedGrade.cat = param1.cat; 
				changedGrade.gradeId = param1.grade_id;
				changedGrade.filiere = param1.filiere_lib;
				setGradeToSend([...gradeToSend, changedGrade]);
			}
		}
	}

	const handleSubmit = async (param) => {
		if(param === "update"){
			try {
				delete selectedGradeRow.idclient;
				selectedGradeRow.gbGa = selectedGradeRow.gradeEspelia.gb_ga; 
				selectedGradeRow.ce = selectedGradeRow.gradeEspelia.ce_lib; 
				selectedGradeRow.cat = selectedGradeRow.gradeEspelia.cat; 
				selectedGradeRow.gradeId = selectedGradeRow.gradeEspelia.grade_id; 
				selectedGradeRow.filiere = selectedGradeRow.gradeEspelia.filiere_lib; 				
				selectedGradeRow.gradeEspelia = selectedGradeRow.gradeEspelia.grade_lib; 
				await GradeAPI.changeGrade(selectedGradeRow.idgrade, selectedGradeRow);
				handleClose();
				// setSend(!send);
			} catch(error){
				toast.error('Une erreur est survenue.');
			}
			toast.success("Enregistrement avec succès");
			setSelectedGradeRow({});
			setSend(!send);
		} else {
			gradeToSend.forEach(async (grade) => {
				try {
					delete grade.idclient;
					await GradeAPI.changeGrade(grade.idgrade, grade);
					setSend(!send);
				} catch(error){
					toast.error('Une erreur est survenue.');
				}
				toast.success("Enregistrement avec succès");
				setSend(!send);
			})
			setGradeToSend([]);
		}
	}

	const exportGrade = async () => {
		if(passClient.idclient) {
			handleClickOpenDialog("export");
			try {
				await GradeAPI.exportGrade({idclient: passClient.idclient});
				handleClose("export");
			} catch(error){
				console.log(error);
				handleClose("export");
				toast.error('Une erreur est survenue lors de l\'export des grades.');
			}
		}
	}

	/* Gestion de la modale */
	const handleClickOpenDialog = (param) => {
		if(param === "export"){
			setOpenExport(true);
		} else {
			setOpen(true);
		}
	};

    const handleClose = (param) => {
		if(param === "export"){
			setOpenExport(false);
		} else {
			setOpen(false);
			setSelectedGradeRow({});
		}
    };

    const handleChangePage = (event, newPage) => {
		event.preventDefault();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	return (
		<div className={classes.root}>
		{passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
						<SettingsIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
						Paramétrage des grades
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2} justify="space-between" alignItems="flex-end">
					<Grid item container justify="flex-start" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={exportGrade}> 
							Export des grades
						</ValidationParamButton> 
					</Grid> 
					<Grid item container justify="flex-end" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={handleSubmit}> 
							Enregistrer les modifications 
						</ValidationParamButton> 
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<FormGroup row>
							<FormControlLabel
								className={classes.labelstyle}
								control={
									<SwitchCustom 
										onChange={handleChangeDisplay}  
										name="displayAll" 
									/> 
								} 
								label={<span className={classes.labelstyle}>Tout afficher</span>}
							/> 
						</FormGroup> 
					</Grid>  
				</Grid>
				<Grid item xs={12} lg={12}>
					<Paper className={classes.paperRoot}>
						<SearchBar
							placeholder="Rechercher un grade"
							value={searched}
							onChange={(searchVal) => requestSearch(searchVal)}
							onCancelSearch={() => cancelSearch()}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow>
									{columns.map((column) => (
										<TableCell
											className={classes.tableheader}
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth}}
											sortDirection={order}
										>
											<TableSortLabel
												active={orderBy === column.id}
												direction={orderBy === column.id ? order : 'asc'}
												onClick={(e) => handleRequestSort(e, column.id)}
											>
												{column.label}
											</TableSortLabel>
										</TableCell>
									))}
									</TableRow>
								</TableHead>
								<TableBody>
								{stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(grade => (
										<TableRow 
											hover={grade.gradeEspelia ? true : false}
											key={grade.idgrade}
											onClick={grade.gradeEspelia ? (event) => handleRowClick(event, grade) : null}
										>
											<TableCell>{grade.gradeXml}</TableCell>
											<TableCell>
												{grade.gradeEspelia  ?
													grade.gradeEspelia
												:
													<Autocomplete
														id={String(grade.idgrade)}
														filterSelectedOptions
														options={nomenclatureGrade.sort((a, b) => -b.ce_lib.localeCompare(a.ce_lib))}
														groupBy={(option) => option.ce_lib}
														getOptionLabel={(option) => option.grade_lib}
														style={{ width: 400 }}
														renderInput={(params) =>
															<TextField {...params} 	
																variant="outlined"
															/>
														}
														onChange={(e, option) => handleChange(e, option, grade)}
													/>
												}
											</TableCell>
											<TableCell align="center">{grade.cat}</TableCell>
											<TableCell align="center">{grade.ce}</TableCell>
											<TableCell align="center">{grade.filiere}</TableCell>
										</TableRow>
								))
							}	
							</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							labelRowsPerPage="Lignes par pages:"
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
							rowsPerPageOptions={[50, 100, 150]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							backIconButtonProps={{
								"aria-label": "Page précédente"
							}}
							nextIconButtonProps={{
								"aria-label": "Page suivante"
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Paper>
				</Grid>
				<Dialog
					open={open}
					fullWidth={true}
					scroll={"body"}
					maxWidth="sm"
					disableEnforceFocus
					TransitionComponent={Transition}
					disableBackdropClick={true}
					onClose={() => handleClose("edit")}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					style={{overflowY: "hidden"}}
				>
					<DialogTitle id="alert-dialog-slide-title" className={classes.labelstyle}>
						<center>Modification du grade</center>
					</DialogTitle>
					<DialogContent style={{overflowY: "hidden"}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={selectedGradeRow.gradeXml ? selectedGradeRow.gradeXml : ''}
									variant="outlined"
									fullWidth
									label="Grade XML"
									InputProps={{readOnly: true}}
									InputLabelProps={{shrink: true}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									disableClearable
									filterSelectedOptions
									options={nomenclatureGrade.sort((a, b) => -b.ce_lib.localeCompare(a.ce_lib))}
									value={selectedGradeRow.gradeEspelia ? selectedGradeRow.gradeEspelia : null}
									getOptionLabel={(option) => option.grade_lib}
									getOptionSelected={(option, value) => option.grade_lib === value.gradeLib}
									groupBy={(option) => option.ce_lib}
									id="selectNomenclatureGrade"
									onChange={(e, option) => handleChange(e, option, "update")}
									// style={{width: "100%"}}
									renderInput={(params) => (
										<TextField
										{...params}
										variant="outlined"
										fullWidth
										label={typeof(selectedGradeRow.gradeEspelia) === 'object' ? "" : selectedGradeRow.gradeEspelia}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<ValidationParamButton onClick={() => handleClose("edit")}>
							Annuler
						</ValidationParamButton> 
						<ValidationParamButton onClick={() => handleSubmit("update")}>
							Modifier
						</ValidationParamButton>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={openExport}
					onClose={() => handleClose("export")}
					TransitionComponent={Transition}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-infodropzone"
					aria-describedby="alert-dialog-infodropzone"
				>
					<DialogContent>
						<center>
							<CircularProgress />
							<DialogContentText id="alert-dialog-send-file">
								Export des grades en cours. <br/>
								Ceci peut prendre quelques minutes.<br/>
								Vous serez averti lorsque le processus sera terminé.
							</DialogContentText>
						</center>
					</DialogContent>
				</Dialog>    
			</main>
		}
		{!passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid item container justify="flex-start" sm={12} md={12} lg={12}>
					<Paper className={classes.warningPaper}>
						<Typography variant="body1">
							Pour visualiser et paramétrer les grades, vous devez sélectionner un client
						</Typography>
					</Paper>
				</Grid>
			</main>
		}
		</div>
    )
}

export default Grade