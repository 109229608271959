import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClientAPI from '../../services/ClientAPI';
import { toast } from "react-toastify";

import { Accordion,
         AccordionDetails,
         AccordionSummary,
         Typography,
         Grid,
         TextField,
         Button,
         Switch,
         FormControlLabel
        } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontFamily: 'AvantGardeBold',
      textTransform: "uppercase",
      color: '#3492aa',
    },
    headerPanel: {
      backgroundColor: '#ebebeb',
    },
}));

const CreateClientButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#2d444f',
    '&:hover': {
      backgroundColor: '#3492aa',
    },
    marginRight: '2vw',
    fontFamily: 'AvantGardeBook',
    [theme.breakpoints.down('md')]: {
        marginRight: "5vw",
    },
},
}))(Button);

const CustomSwitch = withStyles({
    switchBase: {
      color: "#3492AA",
      '&$checked': {
        color: "#246676",
      },
      '&$checked + $track': {
        backgroundColor: "#246676",
      },
    },
    checked: {},
    track: {},
})(Switch);

const AddClient = ({reloadTab, passUsers}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [iduser, setIduser] = useState([]);
    const [newClientProfil, setNewClientProfil] = useState({
      nomClient: "",
      sirenSiret: null,
      users: [],
      anonyme: false,
	  actif: true
    });
    const [errors, setErrors] = useState({
      nomClient: ""
    });

    const createNewClient = (event, param, option = null) => {
      if(param === "users"){
        setIduser(option);
        const idValue = [];

        option.forEach(id => {
            idValue.push("/api/users/" + id.id);
        })
        
        setNewClientProfil({...newClientProfil, [param] : idValue});
      } else if(param === "anonyme" || param === "actif"){
        setNewClientProfil({...newClientProfil, [param] : event.target.checked});
      } else {
        setNewClientProfil({...newClientProfil, [param] : event.target.value});
      }
    }

    const submitClient = async (event) => {
      event.preventDefault();
      
      try {
          await ClientAPI.postClient(newClientProfil);
          toast.success(`Le client ${newClientProfil.nomClient} a été crée avec succès.`);
          setErrors({});  
          setNewClientProfil({
            nomClient: "",
            sirenSiret: null,
            users: [],
            anonyme: false,
			actif: true
          });
          setIduser([]);
          reloadTab();
      } catch(error) {
          toast.error("Une erreur est survenue lors de la création du client.");
      }
    }

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
		<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				className={classes.headerPanel}
			>
          	<Typography className={classes.heading}>Ajouter un nouveau client</Typography>
        	</AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
				<TextField
					required
					id="nomClient"
					name="nomClient"
					label="Nom du client"
					variant="outlined"
					value={newClientProfil.nomClient ? newClientProfil.nomClient : ''}
					helperText={errors.nomClient}
					onChange={(e) => createNewClient(e,'nomClient')}
					style={{ width: "100%"}}
				/>
            </Grid>
            <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
				<TextField
					id="sirenSiret"
					name="sirenSiret"
					label="Siren / Siret"
					variant="outlined"
					value={newClientProfil.sirenSiret ? newClientProfil.sirenSiret : ''}
					onChange={(e) => createNewClient(e, 'sirenSiret')}
					style={{ width: "100%"}}
				/>
            </Grid> 
            <Grid container item xs={12} sm={4} md={4} lg={12} justify="center">
				<Autocomplete
					multiple
					id="users"
					name="users"
					options={passUsers}
					value={iduser}
					getOptionLabel={(option) => option.prenom + " " + option.nom}
					filterSelectedOptions
					disableCloseOnSelect
					style={{ width: "100%"}}

					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Utilisateurs associés"
						/>
					)}
					onChange={(e, option) => createNewClient(e, 'users', option)}
				/>
            </Grid> 
            <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
				<FormControlLabel
					name="anonyme"
					control={
						<CustomSwitch 
							checked={newClientProfil.anonyme ? newClientProfil.anonyme : false}
							onChange={(e) => createNewClient(e, "anonyme")}  
						/>
					}
					label="Anonyme"
				/>
            </Grid>
            <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
				<FormControlLabel
					name="actif"
					control={
						<CustomSwitch 
							checked={newClientProfil.actif ? newClientProfil.actif : false}
							onChange={(e) => createNewClient(e, "actif")}  
						/>
					}
					label="Actif"
				/>
            </Grid>
            <Grid container item xs={12} sm={12} lg={12} justify="flex-end">
				<CreateClientButton variant="contained" onClick={submitClient}>
					Création du client
				</CreateClientButton>
            </Grid>
          </Grid>   
        </AccordionDetails>
      </Accordion>
    )
}

export default AddClient;