import axios from "axios";
import {AGENTS_API, EMPLOYEURS_API, AGENTSMOIS_API, RELOOP_ASSOCIATION} from "../config.js";

function findEmployeur(param){
    return axios
    .get(EMPLOYEURS_API + '?idclient.idclient=' + param)
    .then(response => response.data['hydra:member']);
}

function findAgents(param){
    return axios
    .get(AGENTS_API + '?idemployeur.idclient.idclient=' + param)
    .then(response => response.data['hydra:member']);
}

function findAgentsMois(param){
    return axios
    .get(AGENTSMOIS_API + '?idagent.idagent=' + param)
    .then(response => response.data['hydra:member']);
}

function updateAgentMois(param){
    return axios
    .put(AGENTSMOIS_API + '/' + param.idagentMois, param)
}

function reassociation(param){
    return axios
    .post(RELOOP_ASSOCIATION, param)
    .then((response) => response.data)
}

export default {
    findEmployeur,
    findAgents,
    findAgentsMois,
    updateAgentMois,
    reassociation
}
