import React, { useState, useEffect } from 'react';
import ClientAPI from '../../services/ClientAPI';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";

import { Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TablePagination,
         TableRow,
         TextField,
         Button,
         Grid,
         Dialog,
         DialogActions,
         DialogContent,
         DialogTitle,
         Slide,
         FormControlLabel,
         Collapse,
         Box,
         Tooltip,
         IconButton,
         TableSortLabel,
         Switch
        } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginBottom: 30
    },
    container: {
        [theme.breakpoints.up('xl')]: {
            maxHeight: 640,
        },
        [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    cells: {
        textAlign: 'center'
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#3492AA",
        '&$checked': {
            color: "#246676",
        },
        '&$checked + $track': {
            backgroundColor: "#246676",
        },
    },
    checked: {},
    track: {},
  })(Switch);

const ValidationParamButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#2d444f',
      '&:hover': {
        backgroundColor: '#3492aa',
      },
      fontFamily: 'AvantGardeBold',
      float: 'right'
    },
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'idclient', label: 'Id', minWidth: 150 },
    { id: 'nomClient', label: 'Nom du client', minWidth: 150 },
    { id: 'sirenSiret', label: 'Siren / Siret', minWidth: 150 },
    { id: 'collapse', label: 'Utilisateurs', minWidth: 50, align: 'center', display: false},
    { id: 'edition', label: 'Éditer', minWidth: 50, align: 'center', display: false},
];

const ClientList = ({reloadTab, passClients}) => {
    const classes = useStyles();
    /* Pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [clientProfil, setClientProfil] = useState({});
    const [open, setOpen] = useState(false);
    const [collapsing, setCollapsing] = useState({});
    const [rows, setRows] = useState(passClients);
    const [searched, setSearched] = useState("");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState("idclient");

    useEffect(() => {
        setRows(passClients);
    }, [passClients]);

    const fetchClientProfil = async (param) => {
        try {
            const responseProfil = await ClientAPI.fetchClientProfil(param);
            setClientProfil(responseProfil);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue concernant la récupération du client demandé.');
        }
    }

    const handleSubmitModif = async (event) => {
        event.preventDefault();
        let users = [];
        const usersTab = clientProfil.users;
        usersTab.forEach(item => {
            users.push("/api/users/" + item.id);
        });
        clientProfil.users = users;

        try {
            await ClientAPI.changeClientProfil(clientProfil.idclient, clientProfil);
            handleClose();
            toast.success(`Modification du client ${clientProfil.nomClient} réussi.`);
            setClientProfil({});
            reloadTab();
        } catch(error) {
            //console.log(error.response);
            toast.error('Une erreur est survenue lors de la modification du client.');
        }
    }

    const handleRowClick = (event, param) => {
        event.preventDefault();
        setOpen(true);
        fetchClientProfil(param);
    }

    const handleModifyProfil = name => event => {
        event.preventDefault();
        if(name === "anonyme" || name === "actif"){
            setClientProfil({...clientProfil, [name] : event.target.checked});
        } else {
            setClientProfil({...clientProfil, [name] : event.target.value});
        }
    };

    const handleClose = () => {
        setOpen(false);
        setCollapsing({});
    };

    /* Utilisé pour la pagination du tableau */
    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        event.preventDefault();
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = passClients.filter((row) => {
            return row.nomClient.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
  
    return (
        <>
            <Paper className={classes.root}>
                <SearchBar
                    placeholder="Rechercher un client"
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        className={classes.tableheader}
                                        key={column.id}
                                        align={column.align}
                                        sortDirection={order}
                                    >
                                        {column.display === false ? 
                                            column.label
                                        : 
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(e) => handleRequestSort(e, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        }                                    
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(client => (
                                <React.Fragment key={client.idclient}>
                                    <TableRow 
                                        hover
                                        key={client.idclient}
                                    >
                                        <TableCell>{client.idclient}</TableCell>
                                        <TableCell>{client.nomClient}</TableCell>
                                        <TableCell>{client.sirenSiret}</TableCell>
                                        <TableCell className={classes.cells}> 
                                            <IconButton aria-label="expand row" size="small" onClick={() => setCollapsing({...collapsing, [client.idclient]: !collapsing[client.idclient]} )}>
                                                {collapsing[client.idclient] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={classes.cells}>
                                            <Tooltip title="Editer">
                                                <IconButton aria-label="edit" onClick={(event) => handleRowClick(event, client.idclient)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={collapsing[client.idclient]} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            {client.users.map((user) => { 
                                                return <span key={user.id}>{user.prenom} {user.nom} | </span>
                                            })}
                                        </Box>
                                    </Collapse>
                                    </TableCell>
                                </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <TablePagination
                labelRowsPerPage="Lignes par pages:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={passClients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "Page précédente"
                    }}
                    nextIconButtonProps={{
                    "aria-label": "Page suivante"
                    }}
        
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className={classes.labelstyle}><center>Modification du client</center></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={clientProfil.nomClient ? clientProfil.nomClient : ''}
                                id="nomClient"
                                name="nomClient"
                                label="Nom du client"
                                onChange={handleModifyProfil("nomClient")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={clientProfil.sirenSiret ? clientProfil.sirenSiret : ''}
                                id="sirenSiret"
                                name="sirenSiret"
                                label="Siren / Siret"
                                onChange={handleModifyProfil("sirenSiret")}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                            <FormControlLabel
                                name="anonyme"
                                control={
                                    <CustomSwitch 
                                        checked={clientProfil.anonyme ? clientProfil.anonyme : false}
                                        onChange={handleModifyProfil("anonyme")}  
                                    />
                                }
                                label="Anonyme"
                            />
                        </Grid>                        
                        <Grid container item xs={12} sm={4} md={4} lg={6} justify="center">
                            <FormControlLabel
                                name="actif"
                                control={
                                    <CustomSwitch 
                                        checked={clientProfil.actif ? clientProfil.actif : false}
                                        onChange={handleModifyProfil("actif")}  
                                    />
                                }
                                label="Actif"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ValidationParamButton onClick={handleClose}>
                        Annuler
                    </ValidationParamButton>
                    <ValidationParamButton onClick={handleSubmitModif}>
                        Modifier
                    </ValidationParamButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ClientList;