import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import EchelonAPI from '../../services/EchelonAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TablePagination,
         TableRow,
         FormGroup,
         FormControlLabel,
         Switch,
         MenuItem,
         TextField,
         Button,
         Grid,
         Dialog,
         DialogActions,
         DialogContent,
         DialogTitle,
         Slide,
		 Typography
        } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
    },
	paperRoot: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		//marginTop: 16,
		},
	},  
	container: {
		[theme.breakpoints.up('xl')]: {
			maxHeight: 625,
		},
		[theme.breakpoints.down(1900)]: {
			maxHeight: 440,
		},
	},
	tableheader: {
		textTransform: "uppercase",
		fontFamily: 'AvantGardeBold',
		color: '#3492aa',
		backgroundColor: '#ebebeb',
	},
	formControlLabel: {
		fontFamily: 'AvantGardeBook'
	},
	selectclient: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: 200,
		},
	},
	labelstyle: {
		fontFamily: 'AvantGardeBook',
	},
	search: {
		float: 'right',
	}
}));

const SwitchCustom = withStyles({
	switchBase: {
		color: '#3492aa',
		'&$checked': {
			color: '#3492aa',
		},
		'&$checked + $track': {
			backgroundColor: '#3492aa',
		},
	},
	checked: {},
	track: {},
})(Switch);

const ValidationParamButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
	},
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
{ id: 'echelon_xml', label: 'Echelon XML', minWidth: 300 },
{ id: 'echelon_espelia', label: 'Echelon Espelia', minWidth: 300 },
];

const Echelon = () => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

	/* Pagination du tableau */
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [displayAll, setDisplayAll] = useState(false);

	const [allEchelons, setAllEchelons] = useState([]);
	const [nomenclatureEchelon, setNomenclatureEchelon] = useState([]);
	const [selectedNomenclature, setSelectedNomenclature] = useState([]);
	const [selectedEchelonRow, setSelectedEchelonRow] = useState([]);
	const [changedEchelon, setChangedEchelon] = useState([]);
	const [send, setSend] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const fetchAllEchelons = async () => {
			try {
				const responseEchelon = await EchelonAPI.findAllEchelons();
				let echelonArray = [];

				if (displayAll) {
					setAllEchelons(responseEchelon);
				} else {
					responseEchelon.forEach(element => {
						if(!element.echelonEspelia){
							echelonArray.push(element)
						}
					});
					setAllEchelons(echelonArray);
				}
			} catch(error) {
				//console.log(error);
				toast.error('Une erreur est survenue lors de la récupération des échelons.');
			}
		}
		fetchAllEchelons();
	}, [send, displayAll]);

 	 /* Récupération de la nomenclature pour le select */
	useEffect(() => {
		const fetchNomenclatureEchelon = async () => {
			try {
				const responseNomenclatureEchelon = await EchelonAPI.findAllNomenclatures();
				setNomenclatureEchelon(responseNomenclatureEchelon);
			} catch(error) {
				//console.log(error);
				toast.error('Une erreur est survenue lors de la récupération de la nomenclature des échelons.');
			}
		}
		fetchNomenclatureEchelon();
	}, []);

 	 /* Récupération d'un codePaie au clic sur une ligne */
	const fetchOneEchelonOnClick = async (param) => {
		try {
			const responseOneEchelon = await EchelonAPI.fetchOneEchelon(param);
			setSelectedEchelonRow(responseOneEchelon);
		} catch(error){
		//console.log(error.response);
		toast.error('Une erreur est survenue.');
		}
	}

	  // Récupérer la value d'un champs lors d'une modification de ligne
	const handleModify = name => event => {
		setSelectedEchelonRow({ ...selectedEchelonRow, [name]: event.target.value });
	};

 	 /* Envoie en bdd des modifications*/
	const handleSubmitModif = async (event) => {
		event.preventDefault();
		try {
			await EchelonAPI.changeEchelon(selectedEchelonRow.idechelonCorrespondance, selectedEchelonRow);
			toast.success("Modification de l'échelon bien pris en compte.")
			handleClose();
			setSend(!send);
		} catch(error){
			//console.log(error.response);
			toast.error('Une erreur est survenue.');
		}
	}
  
 	 // Gestion de fermeture de la modale
	const handleClose = () => {
		setOpen(false);
	};
  
  	/* Gestion d'ouverture de la modale */
	const handleRowClick = (event, param) => {
		event.preventDefault();
		setOpen(true);
		fetchOneEchelonOnClick(param);
	}
 
	  /* Changement du select */
	const handleChangeSelect = async (event, param) => {
		event.preventDefault();
		setSelectedNomenclature({...selectedNomenclature, [param] : event.target.value});

		try {
			const responseOneEchelon = await EchelonAPI.fetchOneEchelon(param);
			responseOneEchelon.echelonEspelia = event.target.value;
			setChangedEchelon([...changedEchelon, responseOneEchelon]);
		} catch(error) {
			console.log(error);
		}
	};

 	 /* Envoie en bdd */
	const handleSubmit = () => {
		changedEchelon.forEach(item => {
			EchelonAPI.changeEchelon(item.idechelonCorrespondance, item);
		})
		toast.success("Enregistrement avec succès");
		setSend(!send);
		setSelectedNomenclature([]);
		setChangedEchelon([]);
	}

	/* Permet de permuter l'affichage */
	const handleChangeDisplay = (event) => {
		setDisplayAll(!displayAll);
	};

	/* Utilisé pour la pagination du tableau */
	const handleChangePage = (event, newPage) => {
		event.preventDefault();
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

  	return(
		<div className={classes.root}>
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
							<SettingsIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Paramétrage des échelons
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={3} justify="space-between" alignItems="flex-end">
					<Grid item sm={6} md={6} lg={6}>
						<FormGroup row>
							<FormControlLabel
								control={
									<SwitchCustom 
										onChange={handleChangeDisplay} 
										name="displayAll"
									/>
								}
								label={<span className={classes.labelstyle}>Tout afficher</span>}
							/>
						</FormGroup>
					</Grid>
					<Grid item container justify="flex-end" sm={6} md={6} lg={6} > 
						<ValidationParamButton variant="contained" onClick={handleSubmit}> 
							Enregistrer les modifications 
						</ValidationParamButton> 
					</Grid> 
				</Grid>
				<Paper className={classes.paperRoot}>
					<TableContainer className={classes.container}>
						<Table stickyHeader aria-label="sticky table" size="small">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											className={classes.tableheader}
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{allEchelons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(echelon => (
									<TableRow 
										key={echelon.idechelonCorrespondance}
										hover={echelon.echelonEspelia ? true : false}
										onClick={echelon.echelonEspelia ? (event) => handleRowClick(event, echelon.idechelonCorrespondance) : null}
									>
										<TableCell>{echelon.echelonXml}</TableCell>
										<TableCell>
											{echelon.echelonEspelia == null ?
												<TextField
													id={String(echelon.idechelonCorrespondance)}
													select
													fullWidth
													name="echelon"
													InputLabelProps={{ shrink: true }}
													label="Échelon"
													value={selectedNomenclature[echelon.idechelonCorrespondance] ? selectedNomenclature[echelon.idechelonCorrespondance] : ''}
													onChange={(event) => handleChangeSelect(event, echelon.idechelonCorrespondance)}
												>
													{nomenclatureEchelon.map(nomenclature => (
														<MenuItem key={nomenclature.idnomenclatureEchelon} id={nomenclature.idnomenclatureEchelon} value={nomenclature.echelon}>
															{nomenclature.echelon}
														</MenuItem>
													))}
												</TextField>
											:
												echelon.echelonEspelia
											}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage="Lignes par pages:"
						labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
						rowsPerPageOptions={[50, 100, 150]}
						component="div"
						count={allEchelons.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							"aria-label": "Page précédente"
							}}
							nextIconButtonProps={{
							"aria-label": "Page suivante"
							}}

						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title"><center>Modification d'un échelon</center></DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={selectedEchelonRow.echelonXml ? selectedEchelonRow.echelonXml : ''}
									variant="outlined"
									fullWidth
									label="Echelon XML"
									InputProps={{readOnly: true}}
									InputLabelProps={{shrink: true}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									value={selectedEchelonRow.echelonEspelia ? selectedEchelonRow.echelonEspelia : '' }
									onChange={handleModify("echelonEspelia")}
									variant="outlined"
									fullWidth
									select
									id="echelonEspelia"
									label="Echelon Espelia"
									InputLabelProps={{ shrink: true }}
									name="echelonEspelia"
									autoComplete="echelonEspelia"
								>
									{nomenclatureEchelon.map(nomenclature => (
										<MenuItem key={nomenclature.idnomenclatureEchelon} id={nomenclature.idnomenclatureEchelon} value={nomenclature.echelon}>
											{nomenclature.echelon}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<ValidationParamButton onClick={handleSubmitModif}>
							Modifier
						</ValidationParamButton>
					</DialogActions>
				</Dialog>
			</main>
		</div>
  	)
}

export default Echelon