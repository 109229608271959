import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import jwtDecode from "jwt-decode";
import UserAPI from '../services/UserAPI';
import { toast } from 'react-toastify';
import { UPLOADFILE, DISPLAYFILE, FILEDELETE, DELETEALLFILES, EXTRACTIONFILEDSN, RETRAITEMENTBASEABSENCE } from "../config.js";
import axios from "axios";

import {
	Typography,
	Grid,
	Paper,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	CircularProgress,
	Slide,
	Backdrop, Box
} from '@material-ui/core';

import DropZone from '../components/dropzone/dropzone';
import InfoDropzone from '../components/dropzone/infoDropzone';

import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f',
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right',
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
			//marginTop: 16,
		},
	},
	warningPaper: {
		padding: 20,
		textAlign: "justify",
		fontFamily: 'AvantGardeBook',
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ValidationParamButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
	},
})(Button);

const DropFileDns = ({ passingClient }) => {
	const classes = useStyles();
	const fullWidth = true;
	const maxWidth = 'sm';

	const responsiveDevice = useMediaQuery('(min-width:545px)');

	const [reload, setReload] = useState(false);
	const [errors, setErrors] = useState([]);
	const [allFiles, setAllFiles] = useState([]);
	const [open, setOpen] = useState(false);
	const [openDropzone, setOpenDropzone] = useState(false);
	const [idUser, setIdUser] = useState('');
	const [isAnonyme, setIsAnonyme] = useState(false);
	const [isImportingDsn, setIsImportingDsn] = useState(false);
	const [openRetraitement, setOpenRetraitement] = useState(false);

	useEffect(() => {
		if (passingClient) {
			setIsAnonyme(passingClient.anonyme);
		}
	}, [passingClient]);

	const handleReload = useCallback(() => {
		setReload(!reload);
	}, [reload]);

	useEffect(() => {
		const getDecodedToken = (param) => {
			const tokenBrut = window.localStorage.getItem("authToken");
			if (tokenBrut) {
				const decodedToken = jwtDecode(tokenBrut);
				getUserName(param, decodedToken.username);
			}
		}

		const getUserName = (param, param2) => {
			param.forEach(user => {
				if (user.email === param2) {
					setIdUser(user.id);
					setIsImportingDsn(user.isImportingDsn)
				}
			})
		}

		const fetchUsers = async () => {
			try {
				const responseUsers = await UserAPI.findAllUsers();
				getDecodedToken(responseUsers);
			} catch (error) {
				//console.log(error);
				toast.error('Une erreur est survenue.');
			}
		};
		fetchUsers();
	}, []);

	const fetchAllFilename = useCallback((idUser) => {
		if (idUser !== "") {
			axios.post(DISPLAYFILE, { whichDirectory: "uploadDsnFolder", idUser: idUser })
				.then((response) => {
					setAllFiles(response.data);
				})
				.catch((erreur) => {
					console.log(erreur);
				})
		}
	}, []);

	useEffect(() => {
		fetchAllFilename(idUser);
	}, [reload, idUser, fetchAllFilename]);

	const [countFiles, setCountFiles] = useState(0);
	let count = 0;

	const countingFiles = (param) => {
		setCountFiles(param)
	}

	const sendToController = (param1) => {
		try {
			param1.append("idUser", idUser);
			axios.post(UPLOADFILE, param1)
				.then(response => {
					if (response.data[0].id) {
						if (count === countFiles) {
							handleClose("dropzone");
							count = 0;
							setCountFiles(0);
						}
						fetchAllFilename(idUser);
						setErrors([]);
						toast.success("Vos fichiers ont bien été déposés. En attente d'envoi en base de données.")
						handleClose("dropzone");

					} else {
						setErrors((errors) => [...errors, response])
						handleClose("dropzone");
						toast.error(response.data[0].message);
					}
				})
		} catch (error) {
			console.log(error);
			if (error.response) {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				console.log(error.request);
				toast.warning("Attention, problème de requête.");
			} else {
				console.log('Erreur', error.message);
				toast.error("Il y a eu une erreur lors du dépôt de vos fichiers.");
			}
		}
	}

	const [howManyFilesSend, setHowManyFilesSend] = useState(0);
	const [filesNotSendForError, setFilesNotSendForError] = useState([]);

	const sendFilesindatabase = () => {
		handleClickOpen("infos");
		let coutingFileTab = allFiles[count];

		const url = EXTRACTIONFILEDSN;
		const data = { id: passingClient.idclient, anonyme: isAnonyme, filename: coutingFileTab.completeName, idUser: idUser };

		try {
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				},
				keepalive: true, // Ajout de l'option keepalive ici
			}).then(async (response) => {
				console.log(response)

				if (!response.ok) {
					const errorData = await response.json();
					throw { status: response.status, message: errorData.message };
				}
				return response.json();

				// setHowManyFilesSend((prevHowManyFilesSend) => prevHowManyFilesSend + 1);
				// toast.success(`Le fichier ${coutingFileTab.name}.${coutingFileTab.ext} a été enregistré en base de données.`);
				// count++;
				// if (count !== allFiles.length) {
				// 	sendFilesindatabase();
				// }
			}).then((data) => {
				setHowManyFilesSend((prevHowManyFilesSend) => prevHowManyFilesSend + 1);
				toast.success(`Le fichier a été enregistré en base de données.`);
			}).catch((error) => {
				// console.log(erreur);
				// handleClose("infos");
				// setFilesNotSendForError((filesNotSendForError) => [...filesNotSendForError, `Le fichier ${coutingFileTab.name}.${coutingFileTab.ext} n'a pas pu être intégré.`])
				// toast.error(`Une erreur est survenue lors de l'enregistrement du fichier ${coutingFileTab.name}.${coutingFileTab.ext}.`);
				console.log(`Status: ${error.status}, Message: ${error.message}`);

				// Si le statut est 400 ou 409, on affiche le message d'erreur renvoyé par le backend
				if (error.status === 400 || error.status === 409) {
					toast.error(error.message);
				} else {
					// Sinon, on affiche un message d'erreur générique
					toast.error(error.message);

					// toast.error("Une erreur est survenue lors de l'enregistrement de vos fichiers en base de données.");
				}

				handleClose("infos");
				setFilesNotSendForError((filesNotSendForError) => [...filesNotSendForError, `Le fichier n'a pas pu être intégré.`]);
			});
		} catch (error) {
			console.log(error);
		}
	}

	//* Permet de supprimer les fichiers du dossier dsn APRES avoir fait les insertions (utiles pour la barre de progress)
	const deleteAllFiles = useCallback(() => {
		if (allFiles.length !== 0 && howManyFilesSend === allFiles.length) {
			try {
				axios.post(DELETEALLFILES, { whichDirectory: "uploadDsnFolder", idUser: idUser })
					.then((response) => {
						setHowManyFilesSend(0);
						handleClose('infos');
						handleReload();
						count = 0;
					})
					.catch((erreur) => {
						console.log(erreur);
					})
			} catch (error) {
				console.log(error);
			}
		}
	}, [allFiles, howManyFilesSend, idUser, handleReload]);

	useEffect(() => {
		deleteAllFiles();
	}, [howManyFilesSend, deleteAllFiles]);

	const sendDeleteAction = param => {
		axios.post(FILEDELETE, { id: param, whichDirectory: "uploadDsnFolder", idUser: idUser })
			.then((response) => {
				console.log(response);
				if (response.status && response.status === 200) {
					if (response.data.message) {
						toast.success(response.data.message);
					} else if (response.data[0].message) {
						toast.success(response.data[0].message);
					}
				} else {
					toast.error("Une erreur est survenue lors de la suppression du fichier.");
				}

				handleReload();
			})
			.catch((erreur) => {
				console.log(erreur);
			})
	}

	const handleClickOpen = (param) => {
		if (param === "dropzone") {
			setOpenDropzone(true);
		} else if (param === "absence") {
			setOpenRetraitement(true)
		} else {
			setOpen(true);
		}
	};

	const handleClose = (param) => {
		if (param === "dropzone") {
			setOpenDropzone(false);
		} else if (param === "absence") {
			setOpenRetraitement(false)
		} else {
			setOpen(false);
		}
	};

	const retraitementBaseAbsence = async () => {
		handleClickOpen("absence");

		try {
			await axios.post(RETRAITEMENTBASEABSENCE).then((response) => {
				handleClose('absence');
				toast.success(response.data.data);
			});
		} catch (error) {
			console.log(error);
			if (error.response) {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				toast.error("Un erreur est survenue");

			} else if (error.request) {
				console.log(error.request);
				toast.warning("Attention, problème de requête.");
			} else {
				console.log('Erreur', error.message);
				toast.error("Il y a eu une erreur lors du dépôt de vos fichiers.");
			}
		}
	}

	return (
		<div className={classes.root}>
			{passingClient &&
				<main>
					{isImportingDsn === true ?
						<Backdrop open={true}
							style={{ color: '#fff', zIndex: 10, backgroundColor: 'rgba(75, 70, 68, 0.7)' }}
						>
							<Box textAlign="center" style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)'
							}}>
								<Typography variant="h5" style={{ color: "black", textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
									Un import est déjà en cours...
								</Typography>
								<Typography variant="body1" style={{ color: "black", textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
									Veuillez patienter jusqu'à la fin du processus.
								</Typography>
								<Typography variant="body2" style={{ color: "black", textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
									Vous recevrez un mail une fois l'import terminé.
								</Typography>
							</Box>

						</Backdrop>
						:
						null
					}
					<div className={classes.toolbar} />
					<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
						<Grid container item xs={12} lg={6} justify="center">
							{responsiveDevice ?
								<MoveToInboxIcon className={classes.iconstyle} />
								: null}
							<Typography variant="h6" className={classes.titlePage}>
								Déposer un fichier DSN
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} alignItems="flex-end" justify="space-between">
						<Grid item container justify="flex-start" sm={4} md={4} lg={4}>
							<ValidationParamButton variant="contained" onClick={retraitementBaseAbsence}>
								Retraitement de la base absence
							</ValidationParamButton>
						</Grid>
					</Grid>
					<Grid container spacing={3} justify="center">
						<Grid item xs={12} lg={12}>
							<DropZone
								type={"dsn"}
								open={openDropzone}
								directory={"uploadDsnFolder"}
								allFiles={allFiles}
								sendToController={(param) => sendToController(param)}
								sendDeleteAction={(param) => sendDeleteAction(param)}
								handleClickOpen={(param) => handleClickOpen(param)}
								handleClose={(param) => handleClose(param)}
								countingFiles={(param) => countingFiles(param)}
							/>
						</Grid>
						<Grid item xs={12} lg={12}>
							<InfoDropzone
								type={"dsn"}
								passingClient={passingClient}
								open={open}
								errors={errors}
								reload={() => handleReload()}
								handleClickOpen={(param) => handleClickOpen(param)}
								handleClose={(param) => handleClose(param)}
								sendFilesindatabase={() => sendFilesindatabase()}
								howManyFiles={allFiles.length}
								howManyFilesSend={howManyFilesSend}
								filesNotSendForError={filesNotSendForError}
								directory={"uploadDsnFolder"}
							/>
						</Grid>
					</Grid>
				</main>
			}
			{!passingClient &&
				<main>
					<div className={classes.toolbar} />
					<Grid item container justify="flex-start" sm={12} md={12} lg={12}>
						<Paper className={classes.warningPaper}>
							<Typography variant="body1">
								Pour déposer des fichiers dsn, vous devez sélectionner un client
							</Typography>
						</Paper>
					</Grid>
				</main>
			}
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={openRetraitement}
				TransitionComponent={Transition}
				disableBackdropClick={true}
				onClose={() => handleClose("absence")}
				aria-labelledby="alert-dialog-slide-dropzone"
				aria-describedby="alert-dialog-slide-dropzone"
			>
				<DialogContent>
					<center>
						<CircularProgress />
						<br /><br />
						<DialogContentText className={classes.fontstyle} id="alert-dialog-depot">
							Retraitement de la base absence en cours. <br />
							Veuillez patienter.
						</DialogContentText>
					</center>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default DropFileDns
