import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExportExcel from './exportExcel';
import DropzoneOtherBase from './dropzoneOtherBase';

import { Grid,
         Typography,
         Paper
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    warningPaper: {
		padding: 20,
		textAlign: "justify",
		fontFamily: 'AvantGardeBook',
	}
}));

const OtherBaseService = ({passClient, idUser}) => {
	const classes = useStyles();
    const whichTab = "services";

    return ( 
        <div>
            {passClient &&
                <Grid container style={{marginTop: "25px"}}>
                    <Grid container item alignItems="center" justify="center" lg={6} md={6} sm={6} xs={12}> 
                        <ExportExcel passClient={passClient} whichTab={whichTab} />
                    </Grid>    
                    <Grid container item alignItems="center" justify="center" lg={6} md={6} sm={6} xs={12} style={{borderLeft: "3px solid #eb6c29"}}>
                        <DropzoneOtherBase passClient={passClient} whichTab={whichTab} idUser={idUser} />
                    </Grid>
                </Grid>
            }
            {!passClient &&
                <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                    <Paper className={classes.warningPaper}>
                        <Typography variant="body1">
                            Pour déposer des fichiers ou réaliser des exports Excel des services, vous devez sélectionner un client
                        </Typography>
                    </Paper>
                </Grid>
            }
        </div>
    )      
}

export default OtherBaseService