import React, {useState, useEffect} from 'react';
import axios from "axios";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {DBTOEXCEL, DELETERETURNEXCEL} from "../../config.js";

import { Typography,
         Button,
        } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: theme.spacing(16),
			height: theme.spacing(16),
		},
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
		}
    },
    fontstyle: {
      	fontFamily: 'AvantGardeBook'
    },
    selectclient: {
		width: 200,
		marginTop: 10,
    },
    title: {
		fontFamily: 'AvantGardeBold',
		fontSize: 20,
    },
	texte: {
		margin: 10, 
		padding: 5, 
		border: '1px solid #4b4644', 
		borderRadius: 10, 
		fontFamily: 'AvantGardeBold'
	}
}));

const TraitementButton = withStyles((theme) => ({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		fontSize: '1.5em',
		width: 250,
	},
}))(Button);

const ExportExcel = ({passClient, whichTab}) => {
    const classes = useStyles();

	const [filename, setFilename] = useState('');

    useEffect(() => {
		if(whichTab === "métiers"){
			setFilename("Metier_" + new Date().toLocaleString('fr-FR') + ".xlsx");
		} else if (whichTab === "services") {
			setFilename("Service_" + new Date().toLocaleString('fr-FR') + ".xlsx");
		} else if (whichTab === "permanents / non permanents") {
			setFilename("Permanent_" + new Date().toLocaleString('fr-FR') + ".xlsx");
		}
    }, [whichTab]);

    const getFileFromDatabase = () => {
		axios({
			url: DBTOEXCEL,
			method: 'POST',
			data: {id: passClient.idclient, whichTab: whichTab},
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			deleteReturnExcel();
		})
		.catch((erreur) => {
			console.log(erreur);
		});
    }

    const deleteReturnExcel = () => {
        try {
          	axios.get(DELETERETURNEXCEL)
        } catch(error){
          	console.log(error);
        }
	}

    return (
		<>
			<Typography align="center" variant="h6" className={classes.texte}>
				Appuyez sur le bouton de récupération afin de lancer l'exportation d'un fichier Excel des {whichTab} {whichTab !== "permanents / non permanents" && "par agent"}
			</Typography>
			<TraitementButton className={classes.title} disabled={passClient.idclient ? false : true} variant="contained" onClick={getFileFromDatabase}> Récupération </TraitementButton>
		</>
    )
}

export default ExportExcel