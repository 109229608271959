import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import ExportAPI from '../../services/ExportAPI';

import {Paper,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TablePagination,
        TableRow,
        TextField,
        Button,
        Grid,
        Typography,
        Dialog,
        DialogContent,
        DialogContentText,
        CircularProgress,
        Slide
    } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 40,
        marginBottom: 40
    },
    container: {
        [theme.breakpoints.up('xl')]: {
        maxHeight: 640,
        },
        [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    labelstyle: {
            fontFamily: 'AvantGardeBook',
    },
    search: {
            float: 'right',
    },
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

const ValidationParamButton = withStyles({
    root: {
        color: '#fff',
        backgroundColor: '#2d444f',
        '&:hover': {
            backgroundColor: '#3492aa',
        },
        fontFamily: 'AvantGardeBold',
        float: 'right'
    },
})(Button);

const columns = [
    { id: 'codePaieEspelia1', label: 'Paie 1'},
    { id: 'codePaie', label: 'Code Paie'},
    { id: 'libellePaie', label: 'Libellé paie'},
    { id: 'idPaie', label: 'Id Paie'},
    { id: 'montant', label: 'Montant Total'},
];

const FicheDePaie = ({passClient}) => {
    const classes = useStyles();
    /* Pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    /* Ordre d'affichage */
    const order = 'asc';
    const orderBy = 'libellePaie';
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [matricules, setMatricules] = useState([]);
    const [years, setYears] = useState([]);
    const [monthes, setMonthes] = useState([]);
    const [matriculeSelected, setMatriculeSelected] = useState("");
    const [yearSelected, setYearSelected] = useState("");
    const [monthSelected, setMonthSelected] = useState("");

    const getMatricules = async (passClient) => {
        try {
            const response = await ExportAPI.getAllMatriculesByIdclient({idclient: passClient.idclient});
            setMatricules(response);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue lors de la récupération des matricules.');
        }   
    }

    const getYearsByMatricule = async (param) => {
        try {
            const response = await ExportAPI.getYearsByMatricule({idclient: passClient.idclient, matricule: param});
            setYears(response);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue lors de la récupération des années par matricule.');
        }   
    }

    const getMonthByYearsAndMatricules = async (param) => {
        try {
            const response = await ExportAPI.getMonthByYearsAndMatricules({idclient: passClient.idclient, matricule: matriculeSelected, year: param});
            setMonthes(response);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue lors de la récupération des mois par année et par matricule.');
        }   
    }

    const exportFicheDePaie = async () => {
        if(passClient.idclient && matriculeSelected && yearSelected && monthSelected) {
            setOpen(true);
            try {
                await ExportAPI.exportFicheDePaie({idclient: passClient.idclient, matricule: matriculeSelected, annee: yearSelected, mois: monthSelected});
                setOpen(false);
            } catch(error) {
                console.log(error);
                setOpen(false);
                toast.error('Une erreur est survenue lors de l\'export de la fiche de paie.');
            }   
        }
    }
    
    const getFicheDePaie = async () => {
        if(passClient.idclient && matriculeSelected && yearSelected && monthSelected) {
            try {
                const response = await ExportAPI.getFicheDePaie({idclient: passClient.idclient, matricule: matriculeSelected, annee: yearSelected, mois: monthSelected});
                setRows(response);
            } catch(error) {
                console.log(error);
            }  
        }
    }
    
    useEffect(() => {
        if(passClient.idclient){
            getMatricules(passClient);
        }
    }, [passClient]);

    const handleChange = (event, option, param) => {
        event.preventDefault();
        setRows([])
        
        if(param === "matricule") {
            setMatriculeSelected(option ? option.matricule : null);
            if(option) getYearsByMatricule(option.matricule);
        } else if(param === "year") {
            setYearSelected(option ? option.annee : null);
            if(option) getMonthByYearsAndMatricules(option.annee);
        } else if(param === "month"){
            setMonthSelected(option ? option.mois : null);
        }
	}

    useEffect(() => {
        getFicheDePaie();
    }, [matriculeSelected, yearSelected, monthSelected]);

    const handleChangePage = (event, newPage) => {
		event.preventDefault();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

    return (
        <div style={{marginTop: 20}}>
            <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item sm={4} md={4} lg={4}>
                    <Autocomplete
                        options={matricules}
                        getOptionLabel={(option) => option.matricule}
                        getOptionSelected={(option, value) => option.matricule === value.matricule}
                        renderInput={(params) =>
                            <TextField {...params} 	
                                variant="outlined" label="Choix du matricule de l'agent"
                            />
                        }
                        onChange={(event, option) => handleChange(event, option, "matricule")}
                        disabled={passClient.idclient ? false : true}
                    />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                    <Autocomplete
                        options={years}
                        getOptionLabel={(option) => option.annee}
                        getOptionSelected={(option, value) => option.annee === value.annee}
                        renderInput={(params) =>
                            <TextField {...params} 	
                                variant="outlined" label="Choix de l'année"
                            />
                        }
                        onChange={(event, option) => handleChange(event, option, "year")}
                        disabled={matriculeSelected ? false : true}
                    />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                    <Autocomplete
                        options={monthes}
                        getOptionLabel={(option) => option.mois}
                        getOptionSelected={(option, value) => option.mois === value.mois}
                        renderInput={(params) =>
                            <TextField {...params} 	
                                variant="outlined" label="Choix du mois"
                            />
                        }
                        onChange={(event, option) => handleChange(event, option, "month")}
                        disabled={yearSelected ? false : true}
                    />
                </Grid>
                {!passClient.idclient &&
                    <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                        <Paper className={classes.warningPaper}>
                            <Typography variant="body1">
                                Pour réaliser un export d'une fiche de paie, vous devez sélectionner un client
                            </Typography>
                        </Paper>
                    </Grid>
                }
                <Grid item container justify="flex-start" alignItems="center" sm={12} md={12} lg={12}>
                    <ValidationParamButton variant="contained" disabled={passClient.idclient && matriculeSelected && yearSelected && monthSelected ? false : true} onClick={exportFicheDePaie}> 
                        Export de la fiche de paie
                    </ValidationParamButton> 
                </Grid>
            </Grid>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    className={classes.tableheader}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth}}
                                    sortDirection={order}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((paie, i) => (
                            <TableRow 
                                key={i}
                            >
                                <TableCell>{paie.code_paie_espelia1}</TableCell>
                                <TableCell>{paie.code_paie}</TableCell>
                                <TableCell>{paie.libelle_paie}</TableCell>
                                <TableCell>{paie.idagent_mois_paie}</TableCell>
                                <TableCell>{paie.montant_total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Lignes par pages:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Page précédente"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Page suivante"
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog
				fullWidth={true}
				maxWidth="sm"
				open={open}
				TransitionComponent={Transition}
				disableBackdropClick={true}
				aria-labelledby="alert-dialog-infodropzone"
				aria-describedby="alert-dialog-infodropzone"
			>
				<DialogContent>
					<center>
						<CircularProgress />
                        <DialogContentText id="alert-dialog-send-file">
                            Export de la fiche de paie en cours. <br/>
                            Ceci peut prendre quelques minutes.<br/>
                            Vous serez averti lorsque le processus sera terminé.
                        </DialogContentText>
					</center>
				</DialogContent>
			</Dialog>          
        </div>
    )
}

export default FicheDePaie