import axios from "axios";
import {ECHELONCORRESPONDANCE_API, ECHELONNOMENC_API} from "../config.js";

function findAllEchelons(param){
    return axios
        .get(ECHELONCORRESPONDANCE_API)
        .then(response => response.data['hydra:member']);
}

function findAllNomenclatures(){
    return axios
        .get(ECHELONNOMENC_API)
        .then(response => response.data['hydra:member']);
}

function fetchOneEchelon(param){
    return axios
        .get(ECHELONCORRESPONDANCE_API + "/" + param)
        .then(response => response.data);
}

function changeEchelon(param1, param2){
    return axios
        .put(ECHELONCORRESPONDANCE_API + "/" + param1, param2);
}

export default {
    findAllEchelons,
    findAllNomenclatures,
    fetchOneEchelon,
    changeEchelon
}