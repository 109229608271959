import axios from "axios";
import {USERS_API, CREATEDIRECTORY, DELETEDIRECTORY, FINDUSERBYEMAIL} from "../config.js";

function findAllUsers(){
    return axios
        .get(USERS_API + "?order[prenom]=desc")
        .then(response => response.data['hydra:member']);
}

function postUser(param){
    return axios
        .post(USERS_API, param)
        .then(response => {
            return axios.post(CREATEDIRECTORY, {id: response.data.id})
        })
        .then(response => response.data)
}

function findUserByEmail(param){
    return axios
        .post(FINDUSERBYEMAIL, param)
        .then(response => response.data);
}

function fetchUserProfil(param){
    return axios
        .get(USERS_API + "/" + param)
        .then(response => response.data);
}

function changeUserProfil(param1, param2){
    return axios
        .put(USERS_API + "/" + param1, param2);
}

function deleteUser(param){
    return axios
        .delete(USERS_API + "/" + param)
        .then(() => {
            return axios.post(DELETEDIRECTORY, {id: param})
        })
        .then(response => response.data)
}

function createDirectory(param){
    return axios
    .post(CREATEDIRECTORY, param)
    .then(response => response.data);
}

function fetchManyClients(param){
    return axios
        .get(USERS_API + "?" + param)
        .then(response => response.data['hydra:member']);
}

export default {
    findAllUsers,
    postUser,
    fetchUserProfil,
    changeUserProfil,
    deleteUser,
    createDirectory,
    fetchManyClients,
    findUserByEmail
}