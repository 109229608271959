import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
	Button,
	CircularProgress,
	LinearProgress,
	Dialog,
	DialogContent,
	DialogContentText,
	Slide,
	Typography,
	Grid,
	Box,
	IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	selectclient: {
		width: 200,
	},
	title: {
		fontFamily: 'AvantGardeBook',
		color: '#2d444f'
	},
	title2: {
		fontFamily: 'AvantGardeBold',
		color: 'red'
	},
	divselectclient: {
		border: '2px solid #eb6c29',
		borderRadius: 5,
		width: '30vw',
		padding: 20,
		[theme.breakpoints.up('sm')]: {
			width: '30vw',
			float: 'right',
		},
		[theme.breakpoints.down('780')]: {
			width: '50vw',
			float: 'right',
		},

		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 'auto'
		},
		textAlign: 'center',
	},
	error: {
		display: 'block',
		border: '2px solid red',
		borderRadius: 5,
		padding: 15
	},
	allisgood: {
		display: 'none',
	}
}))

const TraitementButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
	},
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const InfoDropzone = ({ type, handleClose, open, errors, sendFilesindatabase, passingClient, howManyFiles, howManyFilesSend, allFiles }) => {
	const classes = useStyles();
	const fullWidth = true;
	const maxWidth = 'sm';

	const normalise = value => ((100 * value) / howManyFiles);
	const [estimatedTime, setEstimatedTime] = useState(null);

	useEffect(() => {
		if (allFiles && allFiles.length > 0) {
			const totalSizeBytes = allFiles.reduce((total, file) => total + file.size2, 0);

			const totalSizeMb = totalSizeBytes / (1024 * 1024);

			const timePerMb = 3 / 5;
			const estimatedTimeMinutes = totalSizeMb * timePerMb;

			const hours = Math.floor(estimatedTimeMinutes / 60);
			const minutes = Math.floor(estimatedTimeMinutes % 60);
			const seconds = Math.floor((estimatedTimeMinutes * 60) % 60);

			const formattedEstimatedTime = `${hours}h ${minutes}m ${seconds}s`;
			setEstimatedTime(formattedEstimatedTime);
		}
	}, [allFiles]);

	return (
		<>
			<Grid container spacing={5} justify="center">
				<Grid item lg={6} sm={12}>
					{errors.length > 0 &&
						<div className={classes.error}>
							{errors.map((error, j) => (
								<div key={"error" + j}>
									<h3 key={error[0]} className={classes.title2}>{error.data[0][0]}</h3>
									{error.data[0].data === [] ?
										error.data[0].data.map((err, i) => (
											err.message ? <p key={"suberror" + i} className={classes.title}>{err.message}</p> : null
										))
										: error.data[0].data
									}
								</div>
							))}
						</div>
					}
				</Grid>
				<Grid item container justify="flex-end">
					{howManyFiles > 0 &&
						<Grid item lg={6} sm={6}>
							<TraitementButton disabled={!passingClient} variant="contained" onClick={() => sendFilesindatabase()}> Traitement des fichiers </TraitementButton>
						</Grid>
					}
				</Grid>
			</Grid>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				TransitionComponent={Transition}
				disableBackdropClick={true}
				onClose={() => handleClose("info")}
				aria-labelledby="alert-dialog-infodropzone"
				aria-describedby="alert-dialog-infodropzone"
			>
				<DialogContent>
					<IconButton
						edge="start"
						color="inherit"
						onClick={() => {
							handleClose("info");
							window.location.reload();
						}}
						aria-label="close"
						style={{ position: 'absolute', top: 8, left: 8 }}
					>
						<CloseIcon />
					</IconButton>
					<center>
						<DialogContentText id="alert-dialog-send-file">
							Votre demande de traitement a été prise en compte.<br />
							Vous pouvez fermer cette fenêtre, vous serez notifié par e-mail une fois l'import réalisé.<br />
							L'accès à cette section de l'application vous sera impossible tant que le traitement n'est pas terminé.
						</DialogContentText>
					</center>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default InfoDropzone