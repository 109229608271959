import axios from "axios";
import {CLIENTS_API} from "../config.js";

function findAllClients(){
    return axios 
        .get(CLIENTS_API + "?order[nomClient]=desc")
        .then(response => response.data['hydra:member']);
}

function fetchClientProfil(param){
    return axios
        .get(CLIENTS_API + "/" + param)
        .then(response => response.data);
}

function postClient(param){
    return axios
        .post(CLIENTS_API, param)
}

function changeClientProfil(param1, param2){
    return axios
        .put(CLIENTS_API + "/" + param1, param2);
}

function deleteClient(param){
    return axios
        .delete(CLIENTS_API + "/" + param)
}

export default {
    findAllClients,
    fetchClientProfil,
    postClient,
    changeClientProfil,
    deleteClient,
}