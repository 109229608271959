import axios from "axios";
import {METIERCORRESPONDANCE_API, METIERNOMENC_API, EXPORT_METIER_XLS, DELETERETURNEXCEL} from "../config";

function findAllMetiers(param1){
    return axios
        .get(METIERCORRESPONDANCE_API + '?idclient.idclient=' + param1)
        .then(response => response.data['hydra:member']);
}

function fetchOneMetier(param){
    return axios
        .get(METIERCORRESPONDANCE_API + "/" + param)
        .then(response => response.data);
}

function changeMetier(param1, param2){
    return axios
        .put(METIERCORRESPONDANCE_API + "/" + param1, param2);
}

function deleteMetier(param){
    return axios
        .delete(METIERCORRESPONDANCE_API + "/" + param);
}

function findAllNomenclatures(param) {
    return axios
        .get(METIERNOMENC_API + '?' + param)
        .then(response => response.data['hydra:member']);
}

function addNomenclature(param){
    return axios
        .post(METIERNOMENC_API, param);
}

function deleteNomenclature(param){
    return axios
        .delete(METIERNOMENC_API + '/' + param);
}

function updateNomenclature(param, param2){
    return axios
        .put(METIERNOMENC_API + '/' + param, param2);
}

function exportMetier(param){
    return axios({
        url: EXPORT_METIER_XLS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Metier_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

export default {
    findAllMetiers,
    fetchOneMetier,
    changeMetier,
    findAllNomenclatures,
    deleteMetier,
    addNomenclature,
    deleteNomenclature,
    updateNomenclature,
    exportMetier
}