import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import ServiceAPI from '../../services/ServiceAPI';
import StyledTreeItem from './styledTreeItem';

import { Grid,
         Dialog,
         DialogActions,
		 DialogContent,
         DialogTitle,
         Slide,
         Button,
         TextField,
         Modal,
         Backdrop,
         Fade,
         Tooltip,
         FormHelperText
        } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 40,
        marginBottom: 40
    },
    treeview: {
        height: 400,
        flexGrow: 1,
        maxWidth: 600,
        marginLeft: 50,
        marginTop: 50,
        marginBottom: 50
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        fontFamily: 'AvantGardeBold', 
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #eb6c29',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    annulationButton: {
        fontFamily: 'AvantGardeBold'
    },
    buttonAddUf1: {
        color:"white",
        backgroundColor: '#2d444f',
        '&:hover': {
            backgroundColor: '#3492aa',
        },
        fontFamily: 'AvantGardeBold',
        marginTop: 15, 
        marginBottom: 15
    },
    buttonAddUf2: {
        marginLeft: 40, 
        marginBottom:5,
        fontFamily: 'AvantGardeBook',
        height: 25,
        backgroundColor: '#eb6c29',
        color:"white"
    },
    buttonAddUf3: {
        marginLeft: 70, 
        marginBottom:5, 
        fontFamily: 'AvantGardeBook', 
        height: 25, 
        backgroundColor: '#3492aa', 
        color:"white"
    },
    titleModal: {
        fontFamily: 'AvantGardeBold',
        fontHeight: 'bold'
    }
}));

const ValidationParamButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#2d444f',
      '&:hover': {
        backgroundColor: '#3492aa',
      },
      fontFamily: 'AvantGardeBold',
      float: 'right'
    },
})(Button);
  

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});  

const ServiceOrganigramme = ({reload, changedReload, passServiceNomenclature, passIdClient}) => {
    const classes = useStyles();

    const [nomenclatureService, setNomenclatureService] = useState([]);
    const [existOrNot, setExistOrNot] = useState(false);
    const [treeTab, setTreeTab] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [newHierarchie, setNewHierarchie] = useState({
        idClient: passIdClient,
        uf1: "",
        uf2: "",
        uf3: ""
    });
    const [modifHierarchie, setModifHierarchie] = useState({
        idClient: passIdClient,
        highestUf: "",
        hightestValue: "",
        uf: "",
        lastValue: "",
        newValue: "",
    });
    const [clickedButton, setClickedButton] = useState("");
    const [open, setOpen] = useState(false);
    const [openDialogModif, setOpenDialogModif] = useState(false);
    const [openModalValidation, setOpenModalValidation] = useState(false);
    const [error, setError] = useState({
        uf1: "",
        uf2: "",
        uf3: ""
    });
    /* Stockage des paramètres pour les passer à la modale */
    const [deleteValidate, setDeleteValidate] = useState({
        uf: "",
        label: ""
    });

    useEffect(() => {
        setNomenclatureService(passServiceNomenclature);
        const makeTreeTab = () => {
            let tabTree = [];
            let count = 1;

            passServiceNomenclature.forEach((element) => {
                tabTree.push(element.uf1);
            });
            let unique = tabTree.filter((v, i, a) => a.indexOf(v) === i);
            tabTree = unique;
            if(tabTree[0] === undefined){
                tabTree.shift();
            }
            /* Formation du premier niveau de nomenclature */
            for(let i = 0; i < tabTree.length; i++){
                let obj = {id: count, name: tabTree[i], children: []};
                tabTree[i] = obj;
                count++;
            }

            /* Formation second niveau */ 
            tabTree.forEach((item) => {
                let uniqueArray = [];
                passServiceNomenclature.forEach((element) => {
                    if(element.uf1 === item.name){
                        uniqueArray.push(element.uf2);
                    }
                });
                let unique = uniqueArray.filter((v, i, a) => a.indexOf(v) === i);
                uniqueArray = unique;

                for(let i = 0; i < uniqueArray.length; i++){
                    let obj = {id: count, name: uniqueArray[i], children: []};
                    item.children.push(obj);
                    count++;
                }
            });
            /* Formation troisième niveau */
            tabTree.forEach((item) => {
                item.children.forEach((sousItem) => {
                    let uniqueArray = [];
                    passServiceNomenclature.forEach((element) => {
                        if(element.uf2 === sousItem.name){
                            uniqueArray.push(element.uf3);
                        }
                    });

                    let unique = uniqueArray.filter((v, i, a) => a.indexOf(v) === i);
                    uniqueArray = unique;

                    for(let i = 0; i < uniqueArray.length; i++){
                        let obj = {id: count, name: uniqueArray[i]};
                        sousItem.children.push(obj);
                        count++;
                    }
                })
            });
            setTreeTab(tabTree);
        };        
        makeTreeTab();
    }, [passServiceNomenclature, changedReload, nomenclatureService]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
      };
    
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const handleCreateHierarchie = event => {
        event.preventDefault();
        setNewHierarchie({...newHierarchie, [event.target.name] : event.target.value});
        setError({...error, [event.target.name] : ""})
    }
    const handleSubmitHierarchie = async () => {
        try {
            let existOrNot = 0;

            nomenclatureService.forEach((nomenc) => {
                if(nomenc.uf1 === newHierarchie.uf1 && nomenc.uf2 === newHierarchie.uf2 && nomenc.uf3 === newHierarchie.uf3){
                    existOrNot++;
                }
            });

            if(existOrNot === 0){
                setExistOrNot(false);
                await ServiceAPI.addNomenclature(newHierarchie);
                setError({});
                handleClose();
                reload();
                toast.success('Succès de la création de la nouvelle hiérarchie.');
            } else {
                setExistOrNot(true);
            }
        } catch(error) {
            console.log(error);
            const { violations } = error.response.data;
            if(violations) {
                const apiErrors = {};
                violations.forEach(violation => {
                  apiErrors[violation.propertyPath] = violation.message;
                });
                setError(apiErrors);
            }
            toast.error('Une erreur s\'est produite lors de la création de la hiérarchie.');
        }
    }

    const handleOpenModaleValidation = (event, param, param2) => {
        event.preventDefault();
        setOpenModalValidation(true);
        setDeleteValidate({
            uf: param,
            label: param2
        })
    }

    const handleDeleteHierarchie = (event) => {
        event.preventDefault();
        handleClose();
        nomenclatureService.forEach(async(item) => {
            if(item[deleteValidate.uf] === deleteValidate.label){
                try {
                    await ServiceAPI.deleteNomenclature(item.idserviceOrganigramme);
                    reload();
                    toast.success(`L'${deleteValidate.uf} : ${deleteValidate.label} a bien été supprimé.`);
                } catch(error) {
                    console.log(error);

                }
            }
        })
    }  

    const handleModifyHierarchie = (event) => {
        event.preventDefault();

        if(modifHierarchie.newValue !== ""){
            nomenclatureService.forEach(async(item) => {
                if(item[modifHierarchie.highestUf] === modifHierarchie.hightestValue){
                    if(item[modifHierarchie.uf] === modifHierarchie.lastValue){
                        item[modifHierarchie.uf] = modifHierarchie.newValue;
                        try {
                            await ServiceAPI.updateNomenclature(item.idserviceOrganigramme, item);
                            reload();
                            toast.success("La hiérarchie a bien été modifié.");
                        } catch(error) {
                            console.log(error);
                        }
                    }
                }
            })
            handleClose();
        } 
    }

    const handleClickOpen = (event, id, param = "", param2 = "") => {
        event.preventDefault();
        setOpen(true);
        setNewHierarchie({
            idClient: passIdClient,
            uf1: param,
            uf2: param2,
            uf3: ""
        })
        setClickedButton(id);
    };

    const handleOpenDialogModif = (event, id, param = "", param2 = "", param3 = "") => {
        event.preventDefault();
        setOpenDialogModif(true);
        setModifHierarchie({
            idClient: passIdClient,
            highestUf: param2,
            hightestValue: param3,
            uf: id,
            lastValue: param,
            newValue: "",
        });
    };

    const handleClose = () => {
        setOpen(false);
        setOpenDialogModif(false);
        setOpenModalValidation(false);
    };

    const handleModifyUf = event => {
        event.preventDefault();
        setModifHierarchie({...modifHierarchie, newValue : event.target.value});
    }

    return (
        <>
            <Grid container spacing={3} justify="space-between" alignItems="flex-end">
                <TreeView
                    id="myTreeTab"
                    className={classes.treeview}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {treeTab.map((item, i) => (
                            <StyledTreeItem 
                                key={"level" + (i++)} 
                                nodeId={"level" + (i++)}
                                color="white"
                                bgColor="rgba(52, 146, 170, 1)"
                                labelIcon={LooksOneIcon}
                                labelText={item.name}
                                labelInfo={
                                    <> 
                                        <Tooltip title="Supprimer">
                                            <DeleteIcon onClick={(event, param, param2) => handleOpenModaleValidation(event, 'uf1', item.name)} /> 
                                        </Tooltip>
                                        <Tooltip title="Modifier">
                                            <EditIcon onClick={(event, id, param) => handleOpenDialogModif(event, 'uf1', item.name)} /> 
                                        </Tooltip>
                                    </>
                                }
                            >
                                {item.children ?
                                    <> 
                                        {item.children.map((subItem) => (
                                            <StyledTreeItem 
                                                key={"sousLevel" + (i++)} 
                                                nodeId={"sousLevel" + (i++)} 
                                                color="white"
                                                bgColor="rgba(52, 146, 170, 1)"
                                                labelIcon={LooksTwoIcon}
                                                labelText={subItem.name}
                                                labelInfo={
                                                    <> 
                                                        <Tooltip title="Supprimer">
                                                            <DeleteIcon onClick={(event, param, param2) => handleOpenModaleValidation(event, 'uf2', subItem.name)} />
                                                        </Tooltip>
                                                        <Tooltip title="Modifier">
                                                            <EditIcon  onClick={(event, id, param) => handleOpenDialogModif(event, 'uf2', subItem.name, 'uf1', item.name )} /> 
                                                        </Tooltip>
                                                    </>
                                                }
                                            >
                                                {subItem.children ? 
                                                    <>
                                                        {subItem.children.map((subSubItem) => (
                                                                <StyledTreeItem 
                                                                    key={"sousSousLevel" + (i++)} 
                                                                    nodeId={"sousSousLevel" + (i++)} 
                                                                    color="white"
                                                                    bgColor="rgba(52, 146, 170, 1)"
                                                                    labelIcon={Looks3Icon}
                                                                    labelText={subSubItem.name}
                                                                    labelInfo={
                                                                        <>
                                                                            <Tooltip title="Supprimer">
                                                                                <DeleteIcon onClick={(event, param, param2) => handleOpenModaleValidation(event, 'uf3', subSubItem.name)} /> 
                                                                            </Tooltip>
                                                                            <Tooltip title="Modifier">
                                                                                <EditIcon onClick={(event, id, param) => handleOpenDialogModif(event, 'uf3', subSubItem.name, 'uf2', subItem.name)} /> 
                                                                            </Tooltip>
                                                                        </>
                                                                    } 
                                                                />
                                                        ))}
                                                        <Button
                                                            id="Uf3" 
                                                            onClick={(event, id, param, param2) => handleClickOpen(event, "uf3", item.name, subItem.name)}
                                                            variant="contained"
                                                            className={classes.buttonAddUf3}
                                                            startIcon={<AddBoxIcon />}
                                                        >
                                                            Ajouter un nouvel UF3
                                                        </Button>
                                                    </>
                                                : 
                                                    null 
                                                }
                                            </StyledTreeItem>
                                        ))}
                                        <Button
                                            id="Uf2"
                                            onClick={(event, id, param) => handleClickOpen(event, "uf2", item.name)}
                                            variant="contained"
                                            className={classes.buttonAddUf2}
                                            startIcon={<AddBoxIcon />}
                                        >
                                           Ajouter un nouvel UF2
                                        </Button>
                                    </>
                                : 
                                    null
                                } 
                            </StyledTreeItem>
                    ))}
                    <Button
                        id="hiérarchie"
                        onClick={(event, id) => handleClickOpen(event, "hiérarchie")}
                        variant="contained"
                        className={classes.buttonAddUf1}
                        startIcon={<AddBoxIcon />}
                    >
                        Ajouter une nouvelle hiérarchie
                    </Button>
                </TreeView>
            </Grid>
            <Dialog
				open={open}
				TransitionComponent={Transition}
                keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={classes.titleModale}>
                    <center>
                        Ajout d'un{clickedButton === "hiérarchie" && "e"} {clickedButton}
                    </center>
                </DialogTitle>
				<DialogContent>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        error={error.uf1 ? true : false}
                                        helperText={error.uf1 ? error.uf1 : ""}
                                        value={newHierarchie.uf1}
                                        onChange={(event) => handleCreateHierarchie(event)}
                                        variant="outlined"
                                        fullWidth
                                        id="uf1"
                                        name="uf1"
                                        label="UF1"
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            readOnly: clickedButton === "uf2" ? true : false
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required  
                                        error={error.uf2 ? true : false}
                                        helperText={error.uf2 ? error.uf2 : ""}
                                        value={newHierarchie.uf2}
                                        onChange={(event) => handleCreateHierarchie(event)}
                                        variant="outlined"
                                        fullWidth
                                        id="uf2"
                                        label="UF2"
                                        InputLabelProps={{ shrink: true }}
                                        name="uf2"
                                        InputProps={{
                                            readOnly: clickedButton === "uf3" ? true : false
                                        }}
                                    />
                                </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    error={error.uf3 ? true : false}
                                    helperText={error.uf3 ? error.uf3 : ""}
                                    value={newHierarchie.uf3}
                                    onChange={(event) => handleCreateHierarchie(event)}
                                    variant="outlined"
                                    fullWidth
                                    id="uf3"
                                    label="UF3"
                                    InputLabelProps={{ shrink: true }}
                                    name="uf3"
                                />
                            </Grid>
                        </Grid>
                    </form>
                    {existOrNot === true &&
                        <FormHelperText style={{color:"red"}}>Cette hiérarchie existe déjà !</FormHelperText>
                    }
				</DialogContent>
				<DialogActions>
                    <Button className={classes.annulationButton} onClick={handleClose}>
                        Annuler
                     </Button>
					<ValidationParamButton type="submit" onClick={handleSubmitHierarchie}>
						Créer
					</ValidationParamButton>      
				</DialogActions>
			</Dialog>
            <Dialog
				open={openDialogModif}
				TransitionComponent={Transition}
                keepMounted={false}
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={classes.titleModale}>
                    <center>
                        Modification de l'{modifHierarchie.uf}
                    </center>
                </DialogTitle>
				<DialogContent>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    placeholder={modifHierarchie.lastValue ? modifHierarchie.lastValue : ""}
                                    onChange={(event) => handleModifyUf(event)}
                                    variant="outlined"
                                    fullWidth
                                    name="newValue"
                                    label={modifHierarchie.uf}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </form>
				</DialogContent>
				<DialogActions>
                    <Button className={classes.annulationButton} onClick={handleClose}>
                            Annuler
                    </Button>
					<ValidationParamButton type="submit" onClick={handleModifyHierarchie}>
						Modifier
					</ValidationParamButton>      
				</DialogActions>
			</Dialog>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                disableBackdropClick
                open={openModalValidation}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModalValidation}>
                    <div className={classes.paper}>
                        <center>
                            <p id="transition-modal-description">Vous êtes sur le point de supprimer une hiérarchie</p>
                            <h3>Êtes-vous sûr de vouloir continuer ?</h3>
                        </center>
                        <Button className={classes.annulationButton} onClick={handleClose}>
                            Annuler
                        </Button>
                        <ValidationParamButton onClick={(event) => handleDeleteHierarchie(event)}>
                            Supprimer
                        </ValidationParamButton>          
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default ServiceOrganigramme