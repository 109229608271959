import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import axios from "axios";
import CodePaieAPI from '../../services/CodePaieAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {REASSOCIATIONCODESPAIES, DELETECODEPAIEDOUBLON} from "../../config.js";

import {Paper,
		Table,
		TableBody,
		TableCell,
		TableContainer,
		TableHead,
		TablePagination,
		TableRow,
		TableSortLabel,
		FormGroup,
		FormControlLabel,
		Switch,
		TextField,
		Button,
		ButtonGroup,
		Checkbox,
		Grid,
		Dialog,
		DialogActions,
		DialogContent,
		DialogTitle,
		Slide,
		Typography,
		DialogContentText,
		Tooltip,
        CircularProgress
        } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import SettingsIcon from '@material-ui/icons/Settings';

import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
    root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
    },
	paperRoot: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		//marginTop: 16,
		},
	},
	container: {
		[theme.breakpoints.up('xl')]: {
			maxHeight: 625,
		},
		[theme.breakpoints.down(1900)]: {
			maxHeight: 440,
		},
	},
	tableheader: {
		textTransform: "uppercase",
		fontFamily: 'AvantGardeBold',
		color: '#3492aa',
		backgroundColor: '#ebebeb',
	},
	formControlLabel: {
		fontFamily: 'AvantGardeBook'
	},
	selectclient: {
		'& .MuiTextField-root': {
			width: 200,
		},
	},
	labelstyle: {
		fontFamily: 'AvantGardeBook',
	},
	search: {
		float: 'right',
	},
	titleModale: {
		fontFamily: 'AvantGardeBook'
	},
	warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const SwitchCustom = withStyles({
	switchBase: {
		color: '#3492aa',
		'&$checked': {
			color: '#3492aa',
		},
		'&$checked + $track': {
			backgroundColor: '#3492aa',
		},
	},
	checked: {},
	track: {},
})(Switch);


const ValidationParamButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
	},
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'catPaie', label: 'Catégorie Paie', minWidth: 120, display: true},
    { id: 'codePaieXml', label: 'Code Paie', minWidth: 120, display: true},
    { id: 'libellePaieXml', label: 'Libellé',  minWidth: 50, align: 'center', display: false},
    { id: 'codePaieEspelia1', label: 'Libellé Paie Espelia 1', minWidth: 90, align: 'center', display: true},
    { id: 'codePaieEspelia2', label: 'Libellé Paie Espelia 2', minWidth: 90, align: 'center', display: true},
    { id: 'codePaieEspelia3', label: 'Libellé Paie Espelia 3', minWidth: 90, align: 'center', display: true},
    { id: 'verification', label: 'Vérifié', minWidth: 90, align: 'center', display: false},
    { id: 'compteM14', label: 'Compte M14', minWidth: 80, align: 'center', display: false},
    { id: 'codePaieClient', label: 'Code Paie Client', minWidth: 170, align: 'center', display: false},
    { id: 'illustrationLibelle', label: 'Illustration du libellé', minWidth: 170, align: 'center', display: true},
    { id: 'rappel', label: 'Rappel', minWidth: 50, align: 'center', display: true},
	{ id: 'valeurAbsolue', label: 'Valeur Absolue', minWidth: 50, align: 'center', display: true}
  ];

const CodePaie = ({passClient}) => {
    const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

    /* Pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [displayAll, setDisplayAll] = useState(false);

    const [allCodePaies, setAllCodePaies] = useState([]);
    const [allCodePaiesWithoutSorting, setAllCodePaiesWithoutSorting] = useState([]);
	const [nomenclatureCodePaie, setNomenclatureCodePaie] = useState([]);
    const [selectedCodePaieRow, setSelectedCodePaieRow] = useState({});
    const [codePaieToSend, setCodePaieToSend] = useState([]);
	const [order, setOrder] = useState('asc');
    const [send, setSend] = useState(false);
	const [byLibelle, setByLibelle] = useState(false);
	const [orderBy, setOrderBy] = useState("libellePaieXml");
    const [open, setOpen] = useState(false);
	const [openExport, setOpenExport] = useState(false);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");
	const [reassoCodePaie, setReassoCodePaie] = useState(false);
	const [suppressionDoublon, setSuppressionDoublon] = useState(false);

	/* Permet de permuter l'affichage */
	const handleChangeDisplay = (event) => {
		setDisplayAll(!displayAll);
	};

	useEffect(() => {
		if(byLibelle){
			if(displayAll){
				setRows(allCodePaiesWithoutSorting);
				setAllCodePaies(allCodePaiesWithoutSorting);
			} else {
				const filteredRows = allCodePaiesWithoutSorting.filter((row) => {
					let rowToShow;
					if(!row.codePaieEspelia3){
						rowToShow = row;
					}
					return rowToShow;
				});
				setRows(filteredRows);
				setAllCodePaies(filteredRows);
			}
		} else {
			if(displayAll){
				let distinctCat = [];
				allCodePaiesWithoutSorting.forEach((cp) => {
					if(distinctCat.some((cat) => (cat.codePaieXml === cp.codePaieXml && cat.catPaie === cp.catPaie && cat.codePaieEspelia3 === cp.codePaieEspelia3)) === false){
						distinctCat.push(cp);
					}
				});
				setRows(distinctCat);
				setAllCodePaies(distinctCat);
			} else {
				let distinctCat = [];

				allCodePaiesWithoutSorting.forEach((cp) => {
					if(distinctCat.some((cat) => (cat.codePaieXml === cp.codePaieXml && cat.catPaie === cp.catPaie & cat.codePaieEspelia3 === cp.codePaieEspelia3)) === false){
						distinctCat.push(cp);
					}
				});
				const filteredRows = distinctCat.filter((row) => {
					let rowToShow;
					if(!row.codePaieEspelia3){
						rowToShow = row;
					}
					return rowToShow;
				});
				setRows(filteredRows);
				setAllCodePaies(filteredRows);
			}
		}
	}, [displayAll, byLibelle, allCodePaiesWithoutSorting]);

	const requestSearch = (searchedVal) => {
        const filteredRows = allCodePaies.filter((row) => {
			let searchRequest;
			if(byLibelle){
				if(displayAll){
					searchRequest = row.libellePaieXml.toLowerCase().includes(searchedVal.toLowerCase());
				} else {
					if(!row.codePaieEspelia3){
						searchRequest = row.libellePaieXml.toLowerCase().includes(searchedVal.toLowerCase());
					}
				}        
			} else {
				if(displayAll){
					if(row.codePaieXml !== undefined && row.codePaieXml !== null){
						searchRequest = row.codePaieXml.toLowerCase().includes(searchedVal.toLowerCase());
					}
				} else {
					if(!row.codePaieEspelia3){
						searchRequest = row.codePaieXml.toLowerCase().includes(searchedVal.toLowerCase());
					}
				}  
			}
			return searchRequest;
		});
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

	const fetchAllCodePaies = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseCodePaie = await CodePaieAPI.findAllCodePaies(passClient);
				setAllCodePaies(responseCodePaie);
				//* Utile pour la séléction par code
				setAllCodePaiesWithoutSorting(responseCodePaie);
			} catch(error) {
				toast.error('Une erreur est survenue lors de la récupération des codes paie.');
			}
	}
	}, []);

	const fetchNomenclatureCodePaie = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseNomenclatureCodePaie = await CodePaieAPI.findAllNomenclatures();
				responseNomenclatureCodePaie.forEach((n) => {
					n.concatNomencPaie = n.paie1 + " - " + n.paie2
				})
				setNomenclatureCodePaie(responseNomenclatureCodePaie);
			} catch(error){
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération de ma nomenclature des codes paies.');
			}
		}
	}, []);

	useEffect(() => {
		if(passClient !== null){
			fetchAllCodePaies(passClient.idclient);
			fetchNomenclatureCodePaie(passClient.idclient);
		}
	}, [passClient, send, fetchAllCodePaies, fetchNomenclatureCodePaie]);

	const changeDisplayColumns = (param) => {
		if(param === "libelle"){
			setByLibelle(true);
			setOrderBy("libellePaieXml");
			columns.forEach((item) => {
				if(item.id === 'libellePaieXml' || item.id === 'compteM14' || item.id === 'codePaieClient' || item.id === 'verification'){
					item.display = true;
				}
				if(item.id === 'illustrationLibelle'){
					item.display = false;
				}
			})
		} else if(param === "code"){
			setByLibelle(false);
			setOrderBy("codePaieXml");
			columns.forEach((item) => {
				if(item.id === 'libellePaieXml' || item.id === 'compteM14' || item.id === 'codePaieClient'|| item.id === 'verification'){
					item.display = false;
				}
				if(item.id === 'illustrationLibelle'){
					item.display = true;
				}
			});
		}
	}

	const handleChange = (e, param1, param2, param3) => {
		if(param2 === "update"){
			if(byLibelle){
				if(param3 !== undefined){
					setSelectedCodePaieRow({...selectedCodePaieRow, [param3] : e.target.checked});
				} else {
					//* L'ajout de "cp3" est utile pour l'Autocomplete de la modale de modification
					setSelectedCodePaieRow({...selectedCodePaieRow, codePaieEspelia1: param1.paie1, codePaieEspelia2: param1.paie2, codePaieEspelia3: param1.paie3, compteM14: param1.compteM14, cp3: param1});
				}
			} else {
				if(codePaieToSend.some(cp => (cp.codePaieXml === selectedCodePaieRow.codePaieXml) && (cp.catPaie === selectedCodePaieRow.catPaie))){
					if(param3 !== undefined){
						setSelectedCodePaieRow({...selectedCodePaieRow, [param3] : e.target.checked});
					} else {
						setSelectedCodePaieRow({...selectedCodePaieRow, codePaieEspelia1: param1.paie1, codePaieEspelia2: param1.paie2, codePaieEspelia3: param1.paie3, compteM14: param1.compteM14, cp3: param1});
					}
					codePaieToSend.forEach(cp => {
						cp.codePaieEspelia1 = param1.paie1;
						cp.codePaieEspelia2 = param1.paie2;
						cp.codePaieEspelia3 = param1.paie3;
						cp.compteM14 = param1.compteM14;
						if(param3 !== undefined){
							cp[param3] = e.target.checked;
						}
					})
				} else {
					let copy = codePaieToSend;
					allCodePaiesWithoutSorting.forEach((cp) => {
						if((cp.codePaieXml === selectedCodePaieRow.codePaieXml) && (cp.catPaie === selectedCodePaieRow.catPaie)){
							let changedCodePaie = Object.assign({}, cp);
							changedCodePaie.codePaieEspelia1 = param1.paie1;
							changedCodePaie.codePaieEspelia2 = param1.paie2;
							changedCodePaie.codePaieEspelia3 = param1.paie3;
							changedCodePaie.compteM14 = param1.compteM14;
							if(param3 !== undefined){
								changedCodePaie[param3] = e.target.checked;
							}

							copy.push(changedCodePaie);
						}
					})
					setCodePaieToSend(copy);
					if(param3 !== undefined){
						setSelectedCodePaieRow({...selectedCodePaieRow, [param3] : e.target.checked});
					} else {
						setSelectedCodePaieRow({...selectedCodePaieRow, codePaieEspelia1: param1.paie1, codePaieEspelia2: param1.paie2, codePaieEspelia3: param1.paie3, compteM14: param1.compteM14, cp3: param1});
					}
				}
			}
		} else {
			if(byLibelle){
				if(codePaieToSend.some(cp => cp.idcodePaie === param2.idcodePaie)){
					if(param1 !== null){
						setCodePaieToSend([...codePaieToSend].map(object => {
							if(object.idcodePaie === param2.idcodePaie){
								if(param3 !== undefined){
									return {
										...object, [param3] : e.target.checked
									}
								} else {
									return {
										...object, codePaieEspelia1: param1.paie1, codePaieEspelia2: param1.paie2, codePaieEspelia3: param1.paie3, compteM14: param1.compteM14
									}
								}
							}
							else return object;
						}))
					} else {
						setCodePaieToSend(current =>
							current.filter(item => {
								// 👇️ remove object
								return item.idcodePaie !== param2.idcodePaie;
							}),
						);
					}
				} else {
					//* Duplique l'objet sans créer une référence à l'object original
					let changedCodePaie = Object.assign({}, param2);
					changedCodePaie.codePaieEspelia1 = param1.paie1;
					changedCodePaie.codePaieEspelia2 = param1.paie2;
					changedCodePaie.codePaieEspelia3 = param1.paie3;
					changedCodePaie.compteM14 = param1.compteM14;
					setCodePaieToSend([...codePaieToSend, changedCodePaie]);
				}
			} else {
				if(codePaieToSend.some(cp => (cp.codePaieXml === param2.codePaieXml) && (cp.catPaie === param2.catPaie))){
					if(param1 !== null){
						setCodePaieToSend([...codePaieToSend].map(object => {
							if(object.codePaieXml === param2.codePaieXml){
								if(param3 !== undefined){
									return {
										...object, [param3] : e.target.checked
									}
								} else {
									return {
									...object, codePaieEspelia1: param1.paie1, codePaieEspelia2: param1.paie2, codePaieEspelia3: param1.paie3, compteM14: param1.compteM14
									}
								}
							}
							else return object;
						}))
					} else {
						setCodePaieToSend(current =>
							current.filter(item => {
								// 👇️ remove object
								return item.codePaieXml !== param2.codePaieXml;
							}),
						);
					}
				} else {
					let copy = codePaieToSend;
					allCodePaiesWithoutSorting.forEach((cp) => {
						if((cp.codePaieXml === param2.codePaieXml) && (cp.catPaie === param2.catPaie)){
							let changedCodePaie = Object.assign({}, cp);
							changedCodePaie.codePaieEspelia1 = param1.paie1;
							changedCodePaie.codePaieEspelia2 = param1.paie2;
							changedCodePaie.codePaieEspelia3 = param1.paie3;
							changedCodePaie.compteM14 = param1.compteM14;
							if(param3 !== undefined){
								changedCodePaie.rappel = e.target.checked
							}		
							copy.push(changedCodePaie);
						}
					})
					setCodePaieToSend(copy);
				}
			}
		}
	}

	const handleSubmit = async (param) => {
		if(param === "update"){
			if(byLibelle){
				try {
					delete selectedCodePaieRow.idclient;
					delete selectedCodePaieRow.cp3;
					await CodePaieAPI.changeCodePaie(selectedCodePaieRow.idcodePaie, selectedCodePaieRow);
					toast.success(`Modification avec succès du code paie "${selectedCodePaieRow.libellePaieXml}"`);
					handleClose("edit");
					setSend(!send);
				} catch(error){
					toast.error(`Une erreur est survenue sur le code paie "${selectedCodePaieRow.libellePaieXml}"`);
				}
				setSearched("");
				setSend(!send);
			} else {
				codePaieToSend.forEach(async (cp) => {
					try {
						await CodePaieAPI.changeCodePaie(cp.idcodePaie, cp);
						toast.success(`Modification avec succès du code paie "${cp.libellePaieXml}"`);
						setSend(!send);
						handleClose("edit");
					} catch(error){
						toast.error(`Une erreur est survenue sur le code paie "${cp.libellePaieXml}"`);
					}
					setSend(!send);
				})			
				setSearched("");
				setCodePaieToSend([]);
			}
		} else {
			codePaieToSend.forEach(async (cp) => {
				try {
					await CodePaieAPI.changeCodePaie(cp.idcodePaie, cp);
					setSend(!send);
					toast.success(`Enregistrement avec succès du code paie "${cp.libellePaieXml}"`);
				} catch(error){
					toast.error(`Une erreur est survenue sur le code paie "${cp.libellePaieXml}"`);
				}
				setSend(!send);
			})
			setSearched("");
			setCodePaieToSend([]);
		}
	}

	const exportCodePaie = async () => {
		if(passClient.idclient){
			handleClickOpenDialog("export")
			try {
				await CodePaieAPI.exportCodePaie({idclient: passClient.idclient});
				handleClose("export");
			} catch(error){
				console.log(error);
				handleClose("export");
				toast.error('Une erreur est survenue lors de l\'export des codes paie.');
			}
		}
	}

	/* Utilisé pour la pagination du tableau */
	const handleChangePage = (event, newPage) => {
		event.preventDefault();
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleClickOpenDialog = (param) => {
		if(param === "export"){
			setOpenExport(true);
		} else {
			setOpen(true);
		}
	};

    const handleClose = (param) => {
		if(param === "export"){
			setOpenExport(false);
		} else {
			setOpen(false);
			setSelectedCodePaieRow({});
			setCodePaieToSend([]);
		}
    };


	/* Gestion d'ouverture de la modale */
	const handleRowClick = (event, param) => {
		event.preventDefault();
		handleClickOpenDialog("edit")
		setSelectedCodePaieRow(param);
	}

	useEffect(() => {
		nomenclatureCodePaie.forEach((nomenc) => {
			if(nomenc.paie3 === selectedCodePaieRow.codePaieEspelia3){
				selectedCodePaieRow.cp3 = nomenc
			}
		}) 
	}, [selectedCodePaieRow, nomenclatureCodePaie]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const reassociationCodePaie = async () => {
		handleClickOpenDialog("export");
		setReassoCodePaie(true);

		try {
			await axios.post(REASSOCIATIONCODESPAIES, {id: passClient.idclient}).then((response) => {
				handleClose('export');						
				setReassoCodePaie(false);

				toast.success(response.data.data);
			});
		} catch (error) {
			console.log(error);
			if(error.response){
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				toast.error("Un erreur est survenue");

			} else if(error.request) {
				console.log(error.request);
				toast.warning("Attention, problème de requête.");
			} else {
				console.log('Erreur', error.message);
				toast.error("Il y a eu une erreur lors du dépôt de vos fichiers.");
			}
		}
	}

	const suppressionDoublons = async () => {
		handleClickOpenDialog("export");
		setSuppressionDoublon(true);

		try {
			await axios.post(DELETECODEPAIEDOUBLON, {id: passClient.idclient}).then((response) => {
				handleClose('export');						
				setSuppressionDoublon(false);

				toast.success(response.data.data);
			});
		} catch (error) {
			console.log(error);
			if(error.response){
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				toast.error("Un erreur est survenue");

			} else if(error.request) {
				console.log(error.request);
				toast.warning("Attention, problème de requête.");
			} else {
				console.log('Erreur', error.message);
				toast.error("Il y a eu une erreur lors du dépôt de vos fichiers.");
			}
		}
	}

	return (
		<div className={classes.root}>
		{passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
							<SettingsIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Paramétrage des codes paie
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2} alignItems="flex-end" justify="space-between">
					<Grid item container justify="center" sm={3} md={3} lg={3}> 
						<ValidationParamButton variant="contained" onClick={exportCodePaie}> 
							Export des codes paies
						</ValidationParamButton> 
					</Grid> 
					<Grid item container justify="center" sm={3} md={3} lg={3}> 
						<Tooltip title="Suite au traitement de vos fichiers, si vous rencontrez un problème de codes paies manquants, ce bouton permettra une réassociation des codes paies.">
							<ValidationParamButton style={{width: "auto"}} disabled={!passClient} variant="contained" onClick={reassociationCodePaie}>
								Réassociation des codes paies 
							</ValidationParamButton>
						</Tooltip>
					</Grid>
					<Grid item container justify="center" sm={3} md={3} lg={3}> 
						<Tooltip title="Suite au traitement de vos fichiers, si vous rencontrez un problème de codes paies en double, ce bouton permettra leur suppression.">
							<ValidationParamButton style={{width: "auto"}} disabled={!passClient} variant="contained" onClick={suppressionDoublons}>
								Suppression des doublons de codes paies
							</ValidationParamButton>
						</Tooltip>
					</Grid>
					<Grid item container justify="center" sm={3} md={3} lg={3}> 
						<ValidationParamButton variant="contained" onClick={handleSubmit}> 
							Enregistrer les modifications 
						</ValidationParamButton> 
					</Grid> 
					<Grid item sm={12} md={12} lg={12}>
						<ButtonGroup aria-label="vertical outlined primary button group">
							<Button style={{ color: byLibelle ? "#2d444f" : "white" , backgroundColor: byLibelle ? "white" :  "#eb6c29" ,fontFamily: 'AvantGardeBold'}} onClick={() => changeDisplayColumns('code')}>Par Code</Button>
							<Button style={{ color: byLibelle === false ? "#2d444f" : "white",  backgroundColor: byLibelle === false ? "white" : "#eb6c29", fontFamily: 'AvantGardeBold'}} onClick={() => changeDisplayColumns('libelle')}>Par libellé</Button>
						</ButtonGroup>
					</Grid>
					<Grid item sm={12} md={12} lg={12}> 
						<FormGroup row>
							<FormControlLabel
								control={
									<SwitchCustom 
										onChange={handleChangeDisplay} 
										name="displayAll"
									/>
								}
								label={<span className={classes.labelstyle}>Tout afficher</span>}
							/>
						</FormGroup>
					</Grid>
				</Grid>
				<Paper className={classes.paperRoot}>
					<SearchBar
						placeholder={"Rechercher un code paie" + (byLibelle ? " par libellé" : " par code")}
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>
					<TableContainer className={classes.container}>
						<Table stickyHeader aria-label="sticky table" size="small">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										column.display ? 
											<TableCell
												className={classes.tableheader}
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
												sortDirection={order}
											>
												<TableSortLabel
													active={orderBy === column.id}
													direction={orderBy === column.id ? order : 'asc'}
													onClick={(e) => handleRequestSort(e, column.id)}
												>
													{column.label}
												</TableSortLabel>
											</TableCell>
										:
											null
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(codePaie => (
									<TableRow 
										hover={codePaie.codePaieEspelia3 ? true : false}
										onClick={codePaie.codePaieEspelia3 ? (event) => handleRowClick(event, codePaie) : null}
										key={codePaie.idcodePaie}
										style={{ backgroundColor : byLibelle && codePaie.verification === false ? "rgba(255, 0, 0, 0.25)" : null}}
									>
										<TableCell>{codePaie.catPaie}</TableCell>
										<TableCell>{codePaie.codePaieXml}</TableCell>
										{byLibelle && <TableCell>{codePaie.libellePaieXml}</TableCell>}
										<TableCell>{codePaie.codePaieEspelia1}</TableCell>
										<TableCell>{codePaie.codePaieEspelia2}</TableCell>
										<TableCell>
											{codePaie.codePaieEspelia3 ?
												codePaie.codePaieEspelia3
											:
												<Autocomplete
													id={String(codePaie.idcodePaie)}
													filterSelectedOptions
													options={nomenclatureCodePaie.sort((a, b) => -b.paie2.localeCompare(a.paie2))}
													groupBy={(option) => option.concatNomencPaie}
													getOptionLabel={(option) => option.paie3}
													style={{ width: 300 }}
													renderInput={(params) => <TextField {...params} variant="outlined" />}
													onChange={(e, option) => handleChange(e, option, codePaie)}
												/>
											}
										</TableCell>
										{byLibelle && 
											<TableCell align="center">
												{codePaie.hasOwnProperty("verification") === false && <RemoveIcon />}	
												{codePaie.verification === true && <CheckIcon style={{color: "green"}} />}	
												{codePaie.verification === false && <HelpOutlineIcon />}	
											</TableCell>
										}
										{byLibelle && <TableCell align="center">{codePaie.compteM14}</TableCell>}
										{byLibelle && <TableCell align="center">{codePaie.codePaieClient}</TableCell>}
										{!byLibelle && <TableCell align="center">{codePaie.libellePaieXml}</TableCell>}
										<TableCell align="center">
											{codePaie.codePaieEspelia3 ?
													<Checkbox
														checked={codePaie.rappel ? codePaie.rappel : false}
														disabled={true}
													/>
											:
												<Checkbox
													id={String(codePaie.idcodePaie)}
													disabled={false}
													onChange={(e, option) => handleChange(e, option, codePaie, "rappel")}

												/>
											}
										</TableCell>
										<TableCell align="center">
											{codePaie.codePaieEspelia3 ?
													<Checkbox
														checked={codePaie.valeurAbsolue ? codePaie.valeurAbsolue : false}
														disabled={true}
													/>
												:
													<Checkbox
														id={String(codePaie.idcodePaie)}
														disabled={false}
														onChange={(e, option) => handleChange(e, option, codePaie, "valeurAbsolue")}

													/>
											}
										</TableCell>
									</TableRow>
								))
							}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage="Lignes par pages:"
						labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
						rowsPerPageOptions={[50, 100, 150]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							"aria-label": "Page précédente"
						}}
						nextIconButtonProps={{
							"aria-label": "Page suivante"
						}}

						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose("edit")}
					disableBackdropClick
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title" className={classes.titleModale}><center>Modification du code paie</center></DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={selectedCodePaieRow.libellePaieXml ? selectedCodePaieRow.libellePaieXml : ''}
									variant="outlined"
									fullWidth
									label="Libellé Paie XML"
									InputProps={{readOnly: true}}
									InputLabelProps={{shrink: true}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Autocomplete
									disableClearable
									filterSelectedOptions
									id={String(selectedCodePaieRow.idcodePaie)}
									options={nomenclatureCodePaie.sort((a, b) => -b.paie2.localeCompare(a.paie2))}
									value={selectedCodePaieRow.cp3 ? selectedCodePaieRow.cp3 : null}
									groupBy={(option) => option.paie2}
									getOptionLabel={(option) => option.paie3}
									// getOptionSelected={(option, value) => option.paie3 === value.codePaieEspelia3.paie3}
									style={{ width: "100%" }}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											fullWidth
											label={selectedCodePaieRow.codePaieEspelia3}
										/>
									)}
									onChange={(e, option) => handleChange(e, option, "update")}
								/>
							</Grid>
							{byLibelle && 
								<Grid item xs={12} sm={12}>
									<FormControlLabel 
										control={ 
											<Checkbox
												id={String(selectedCodePaieRow.idcodePaie)}
												checked={selectedCodePaieRow.verification ? selectedCodePaieRow.verification : false }
												onChange={(e, option) => handleChange(e, option, "update", "verification")}
											/>}
										label="Vérifié" 
									/>
								</Grid>
							}
							<Grid item xs={12} sm={12}>
								<FormControlLabel 
									control={ 
										<Checkbox
											id={String(selectedCodePaieRow.idcodePaie)}
											checked={selectedCodePaieRow.rappel ? selectedCodePaieRow.rappel : false }
											onChange={(e, option) => handleChange(e, option, "update", "rappel")}
										/>}
									label="Rappel" 
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<FormControlLabel 
									control={ 
										<Checkbox
											id={String(selectedCodePaieRow.idcodePaie)}
											checked={selectedCodePaieRow.valeurAbsolue ? selectedCodePaieRow.valeurAbsolue : false }
											onChange={(e, option) => handleChange(e, option, "update", "valeurAbsolue")}
										/>}
									label="Valeur Absolue" 
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<ValidationParamButton onClick={() => handleClose("edit")}>
							Annuler
						</ValidationParamButton>      
						<ValidationParamButton onClick={() => handleSubmit("update")}>
							Modifier
						</ValidationParamButton>      
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={openExport}
					onClose={() => handleClose("export")}
					TransitionComponent={Transition}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-infodropzone"
					aria-describedby="alert-dialog-infodropzone"
				>
					<DialogContent>
						<center>
							<CircularProgress />
							<DialogContentText id="alert-dialog-send-file">
								{reassoCodePaie === true ?
									<>
										Réassociation des codes paies en cours. <br/>
										Ceci peut prendre quelques minutes.<br/>
										Vous serez averti lorsque le processus sera terminé.
									</>
								:
									setSuppressionDoublon === true ? 
										<>
											Supression des doublons de codes paie en cours. <br/>
											Ceci peut prendre quelques minutes.<br/>
											Vous serez averti lorsque le processus sera terminé.
										</>
									:
										<>
											Export des codes paie en cours. <br/>
											Ceci peut prendre quelques minutes.<br/>
											Vous serez averti lorsque le processus sera terminé.
										</>
								}
								</DialogContentText>
						</center>
					</DialogContent>
				</Dialog> 
			</main>
		}
		{!passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid item container justify="flex-start" sm={12} md={12} lg={12}>
					<Paper className={classes.warningPaper}>
						<Typography variant="body1">
							Pour visualiser et paramétrer les codes paies, vous devez sélectionner un client
						</Typography>
					</Paper>
				</Grid>
			</main>
		}
		</div>
    )
}

export default CodePaie