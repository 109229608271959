import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import jwtDecode from "jwt-decode";

import OtherBaseService from "../components/otherbase/otherBaseService";
import OtherBaseMetier from "../components/otherbase/otherBaseMetier";
import OtherBasePermanent from "../components/otherbase/otherBasePermanent";

import { Grid, 
         Typography,
		 Paper,
         Tabs,
         Tab,
       } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '15vw',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '11vw',
    },
  },
  toolbar: theme.mixins.toolbar,
  titleBox: {
    backgroundColor: '#2d444f', 
    borderRadius: 5,
    marginBottom: 20,
  },
  titlePage: {
    marginLeft: 25,
    color: 'white',
    fontFamily: "AvantGardeBold",
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em'
    },
    [theme.breakpoints.down('465')]: {
      fontSize: '1em',
    },
  },
  iconstyle: {
    float: 'right', 
    color: 'white',
    marginTop: 5,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: '2em',
    },
  },
  divselectclient: {
	border: '2px solid #eb6c29',
	borderRadius: 5,
	// width: '30vw', 
	padding: 20, 
	[theme.breakpoints.up('sm')]: {
		width: '30vw', 
		float: 'left',
	},
	[theme.breakpoints.down('780')]: {
		width: '50vw', 
		float: 'left',
	},
	[theme.breakpoints.down('sm')]: {
		width: '100%', 
		margin: 'auto'
	},
	textAlign: 'center',
},
}));

const AntTabs = withStyles({
	root: {
	  backgroundColor: '#2d444f',
	  display: 'flex',
	  justifyContent: 'center'
	},
	indicator: {
	  backgroundColor: '#eb6c29',
	},
  })(Tabs);
  
  const AntTab = withStyles((theme) => ({
	root: {
	  textTransform: 'none',
	  minWidth: 72,
	  fontWeight: theme.typography.fontWeightRegular,
	  marginRight: theme.spacing(4),
	  fontFamily: 'AvantGardeBold',
	  fontSize: '1.5em',
	  color: 'white',
	  '&:hover': {
		color: '#eb6c29',
		opacity: 1,
	  },
	  '&$selected': {
		color: '#eb6c29',
		fontWeight: theme.typography.fontWeightMedium,
	  },
	  '&:focus': {
		color: '#eb6c29',
	  },
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const OtherBase = ({passingClient}) => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

	const [stateOnglets, setStateOnglets] = useState(0);

  	const [idUser, setIdUser] = useState('');

  	const getDecodedToken = () => {
		const tokenBrut = window.localStorage.getItem("authToken");
		if(tokenBrut){
			const decodedToken = jwtDecode(tokenBrut);
			setIdUser(decodedToken.id);
		}
	}

	useEffect(() => {
		getDecodedToken();
	}, []);

	const handleChange = (event, newValue) => {
		setStateOnglets(newValue);
	};

	return (
		<div className={classes.root}>
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={4} justify="center" alignItems="center">
						{responsiveDevice ?
							<DescriptionIcon className={classes.iconstyle} />
						: null
						}
						<Typography variant="h6" className={classes.titlePage}>
							Ajout d'autres bases de données
						</Typography>
					</Grid>
					{responsiveDevice ? 
						<Grid item xs={12} lg={8}>
						<Paper>
							<AntTabs
								value={stateOnglets}
								onChange={handleChange}
								variant="fullWidth"
							>
								<AntTab label="Service" />
								<AntTab label="Métier" />
								<AntTab label="Permanent / Non permanent" />
							</AntTabs>
						</Paper>
						</Grid>
					: null }  
				</Grid>
				{stateOnglets === 0 && <OtherBaseService passClient={passingClient} idUser={idUser} />}
				{stateOnglets === 1 && <OtherBaseMetier passClient={passingClient} idUser={idUser} />} 
				{stateOnglets === 2 && <OtherBasePermanent passClient={passingClient} idUser={idUser} />} 
			</main>
		</div>
	);
}

export default OtherBase
