import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PictoLogoSvg from "../images/Pictos logo.svg"
import Bandeau1Png from "../images/Bandeau-1.png"
import Bandeau1Avif from "../images/Bandeau-1.avif"
import Bandeau2Png from "../images/Bandeau-2.png"
import Bandeau2Avif from "../images/Bandeau-2.avif"

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down("lg")]: {
			marginTop: "10vh"
		},
		[theme.breakpoints.between("lg", 1440)]: {
			marginTop: "15vh",
			marginLeft: '15vw',
		},
		[theme.breakpoints.between(1441, "xl")]: {
			marginTop: "12vh",
			marginLeft: '12vw',
		},
		[theme.breakpoints.up('xl')]: {
			marginTop: "11vh",
			marginLeft: '8vw',
		},
		[theme.breakpoints.up(2100)]: {
			marginTop: "11vh",
			marginLeft: '8vw',
		},
	},
	pictoLogoContainer: {
		width: '200px',
		height: 'auto',
		margin: "0 auto",
	},
	pictoLogo: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	titreContainer: {
		width: 500,
		margin: "0 auto",
	},
	titre: {
		color: '#2d444f',
		fontFamily: 'AvantGardeBook',
		textAlign: "center",
		fontSize: 50,
		margin: "0 auto",
	},
	bloc1: {
		[theme.breakpoints.down("lg")]: {
			width: "80vw",
			marginTop: "2vh",
			padding: "1vh 4vw 10vh 4vw",
			borderTop: "solid #eb6c29 3px",
			borderRight: "solid #eb6c29 3px",
			borderBottom: "solid #eb6c29 3px",
			borderTopRightRadius: 25,
			borderBottomRightRadius: 25,
		},
		[theme.breakpoints.up('xl')]: {
			width: "57vw",
			border: "solid #eb6c29 3px",
			borderRadius: 25,
			marginTop: "2vh",
			padding: "1vh 4vw 10vh 4vw",
		},
	},
	texteBloc1: {
		fontSize: 18,
		textAlign: "justify"
	},
	imageBloc1: {
		[theme.breakpoints.down("lg")]: {
			marginTop: "-10vh",
			width: "80vw",
			height: "15vh",
			textAlign: "right",
			backgroundColor: "black",
			borderTopRightRadius: 40,
			borderBottomRightRadius: 40,
		},
		[theme.breakpoints.up('xl')]: {
			marginTop: "-10vh",
			width: "57vw",
			height: "15vh",
			textAlign: "right",
			backgroundColor: "black",
			borderRadius: 40,
		},
	},
	image1: {
		[theme.breakpoints.down("lg")]: {
			borderTopRightRadius: 40,
			borderBottomRightRadius: 40,
		},
		[theme.breakpoints.up('xl')]: {
			borderRadius: 40,
		},
		height: '100%',
		textAlign: "right",
		margin: 0,
		padding: 0,
	},
	bloc2: {
		[theme.breakpoints.up(2100)]: {
		},
		[theme.breakpoints.down("lg")]: {
			width: "80vw",
		},
		float:"right",
		width: "57vw",
		borderTop: "solid #eb6c29 3px",
		borderLeft: "solid #eb6c29 3px",
		borderBottom: "solid #eb6c29 3px",
		borderTopLeftRadius: 25,
		borderBottomLeftRadius: 25,
		marginTop: "-10vh",
		padding: "10vh 4vw 1vh 4vw ",
		zIndex: 1,
	},
	texteBloc2: {
		fontSize: 18,
		textAlign: "justify"
	},
	imageBloc2: {
		[theme.breakpoints.down("lg")]: {
			width: "80vw",
		},
		[theme.breakpoints.up(2100)]: {
			marginTop: "12vh",
		},
		zIndex: 2,
		position: 'relative',
		marginTop: "2vh",
		width: "57vw",
		height: "15vh",
		float:"right",
		textAlign: "left",
		borderRadius: 40,
	},
	image2: {
		width: '100%',
		height: '100%',
		textAlign: "right",
		margin: 0,
		padding: 0,
		borderTopLeftRadius: 40,
		borderBottomLeftRadius: 40,
	}
}));

const Home = () => {
  const classes = useStyles();

 	return (
		<div className={classes.root}>
			<div className={classes.pictoLogoContainer}>
				<img className={classes.pictoLogo} src={PictoLogoSvg} alt="Pictogramme Logo GSRH" />
			</div>
			<div className={classes.titreContainer}>
				<p className={classes.titre}>
					Bienvenue sur <strong>GS'RH</strong>
				</p>
			</div>
			<div className={classes.bloc1}>
				<p className={classes.texteBloc1}>
					Espelia accompagne depuis plusieurs années les collectivités dans la gestion de leurs ressources humaines (RIFSEEP, temps de travail, pilotage de la masse salariale, absentéisme, entretiens professionnels…). 
					Nous sommes convaincus du rôle prépondérant du traitement et l’analyse des données RH pour objectiver des faits et éclairer les prises de décisions.
				</p>
			</div>
			<div className={classes.imageBloc1}>
				<picture>
					<source className={classes.image1} srcSet={Bandeau1Avif} type="image/avif" />
					<img className={classes.image1} src={Bandeau1Png} alt="Bandeau 1" />
				</picture>
			</div>
			<div className={classes.imageBloc2}>
				<picture>
					<source className={classes.image2} srcSet={Bandeau2Avif} type="image/avif" />
					<img className={classes.image2} src={Bandeau2Png} alt="Bandeau 1" />
				</picture>
			</div>
			<div className={classes.bloc2}>
				<p className={classes.texteBloc2}>
					C’est pourquoi Espelia a développé GS’RH, plateforme d’analyse et de visualisation de données RH. 
					Plutôt qu’ajouter un énième logiciel de gestion, nous vous proposons une plateforme permettant de centraliser facilement vos données RH, 
					et de construire des tableaux de bord interactifs, personnalisables en fonction de vos besoins nécessairement évolutifs.
				</p>
			</div>
		</div>
  	);
}

export default Home 
