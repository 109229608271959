import React, {useState} from 'react';
import {makeStyles, withStyles,} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import ExportAPI from '../../services/ExportAPI';

import {Paper,
        Button,
        Grid,
        Typography,
        Dialog,
        DialogContent,
        DialogContentText,
        CircularProgress,
        Slide
    } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
    paperRoot: {
        width: '100%',
        marginTop: 40,
        marginBottom: 40
    },
    container: {
        [theme.breakpoints.up('xl')]: {
        maxHeight: 640,
        },
        [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    labelstyle: {
        fontFamily: 'AvantGardeBook',
    },
    search: {
        float: 'right',
    },
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const ValidationParamButton = withStyles({
    root: {
        color: '#fff',
        backgroundColor: '#2d444f',
        '&:hover': {
            backgroundColor: '#3492aa',
        },
        fontFamily: 'AvantGardeBold',
        float: 'right'
    },
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

const DetailsParPaie = ({passClient}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const exportDetailParMois = async () => {
        if(passClient.idclient){
            setOpen(true);
            try {
                await ExportAPI.exportDetailParMois({idclient: passClient.idclient});
                setOpen(false);
            } catch(error){
                console.log(error);
                setOpen(false);
                toast.error('Une erreur est survenue lors de l\'export du détail paie par mois.');
            }
        }
    }

    return (
        <Grid container spacing={2} justify="space-between" alignItems="flex-end" style={{marginTop: 20}}>
            {!passClient.idclient &&
                <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                    <Paper className={classes.warningPaper}>
                        <Typography variant="body1">
                            Pour réaliser un export du détail paie par mois, vous devez sélectionner un client
                        </Typography>
                    </Paper>
                </Grid>
            }
            <Grid item container justify="flex-start" sm={12} md={12} lg={12}> 
                <ValidationParamButton disabled={passClient.idclient ? false : true} variant="contained" onClick={exportDetailParMois}> 
                    Export du détail paie par mois
                </ValidationParamButton> 
            </Grid>   
            <Dialog
				fullWidth={true}
				maxWidth="sm"
				open={open}
				TransitionComponent={Transition}
				disableBackdropClick={true}
				aria-labelledby="alert-dialog-infodropzone"
				aria-describedby="alert-dialog-infodropzone"
			>
				<DialogContent>
					<center>
						<CircularProgress />
                        <DialogContentText id="alert-dialog-send-file">
                            Export du détail par paie en cours. <br/>
                            Ceci peut prendre quelques minutes.<br/>
                            Vous serez averti lorsque le processus sera terminé.
                        </DialogContentText>
					</center>
				</DialogContent>
			</Dialog>          
        </Grid>
    )
}

export default DetailsParPaie