import React, {useState, useContext} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AuthAPI from "../services/AuthAPI";
import AuthContext from "../contexts/AuthContext";

import Logo from "../images/logogsrh2.png";
import LogoEspelia from "../images/logoespelia.avif";
import BackgroundLoginPage from "../images/bgloginpage.avif"

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';

import {Button,
		TextField,
		Paper,
		Typography,
		Grid
        } from '@material-ui/core';

const ConnexionButton = withStyles({
	root: {
		color: "white",
		backgroundColor: "#66aec8",
		'&:hover': {
			backgroundColor: "#69aec4",
		},
		marginTop: 15,
		width: 250,
        fontFamily:'AvantGardeBold',
		fontSize: "large",
		fontWeight: "bold"
	},
})(Button);

const useStyles = makeStyles(theme => ({
	maindiv: {
		// margin: 0, 
		// padding: 0,
		minHeight: '100vh',
		width: '100vw',
		backgroundImage: `url(${BackgroundLoginPage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		textAlign: "center",
	},
	logocontainer: {
		width: '200px',
		height: '200px',
		position: 'relative',
		zIndex: 2,
		// backgroundColor: "red",
		margin: "0 auto",
	},
	logo: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	logoespelia: {
		width: '200px',
		margin: "0 auto",
	},
	bienvenue: {
		opacity: 0.8,
		width: '300px',
		padding: '45px 10px 5px 10px',
		position: 'relative',
		zIndex: 1,
		transform: 'translateY(-30%)',
		margin: "0 auto",
	},
	bienvenuegsrh: {
		color: '#66aec8',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		textAlign: 'center',
        fontFamily:'AvantGardeBold',
		fontSize: 'large',
	},
	description: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontFamily:'AvantGardeBold',
	},
	connexion: {
		opacity: 0.9,
		width: '300px',
		padding: '10px',
		margin: "0 auto",

	},
	seconnecter: {
		color: '#66aec8',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		textAlign: 'center',
        fontFamily:'AvantGardeBold',
		fontSize: 'large',
	},
	outils: {
		color: 'white',
	}
}))

const LoginPage = ({history}) => {
    const classes = useStyles();

    const {setIsAuthenticated} = useContext(AuthContext);
    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    });
    const [error, setError] = useState(false);

    // Gestion des champs de connexion
    const handleChangeEmail = (event) => {
        const value = event.currentTarget.value;
        const name = event.currentTarget.name;

        setCredentials({...credentials, [name]: value});
    }
    // Gestion du submit de connexion
    const handleSubmitLogin = async (event) => {
        event.preventDefault();

		try {
			// console.log("Connexion...");

			await AuthAPI.authenticate(credentials);
			setIsAuthenticated(true);
			history.replace('/accueil');
        } catch(error) {
            setError(true);
        }
    }

    return (
		<div className={classes.maindiv}>
			<div style={{position: "absolute", margin: "auto", top: 0, right: 0, bottom: 0, left: 0, maxHeight: 800}}>
				<div className={classes.logocontainer}>
					<img src={Logo} alt="Logo GS'RH" className={classes.logo} />
				</div>
				<Paper className={classes.bienvenue}>
					<p className={classes.bienvenuegsrh}> Bienvenue sur GS'RH </p>
					<p className={classes.description}> Votre outil d'analyse <br/> et de valorisation des données RH </p>
				</Paper>
				<form onSubmit={handleSubmitLogin}>
					<Paper className={classes.connexion}>
						<p className={classes.seconnecter}> Se connecter </p>
						<Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
							<Grid item>
								<AccountCircle />
							</Grid>
							<Grid item>
								<TextField 
									type="email"
									value={credentials.username}
									onChange={handleChangeEmail}
									label="Adresse email"
									name="username" 
									id="username" 
									required
									error={error}
									autoComplete="email"
									autoFocus
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
							<Grid item>
								<LockIcon />
							</Grid>
							<Grid item>
								<TextField 
									type="password"
									value={credentials.password}
									onChange={handleChangeEmail}
									label="Mot de passe"
									name="password" 
									id="password" 
									className="form-control"
									required
									error={error}
									autoComplete="current-password"
								/>
						</Grid>
						{error && 
							<Grid item>
								<Typography color="error" variant="body1" >
                                Cette adresse email n'existe pas ou le mot de passe est incorrect, ou le mot de passe est arrivé à expiration.
								</Typography>
							</Grid>
						}
						</Grid>
						<Grid container direction="column" alignItems="center" spacing={2}>
							<Grid item>
								<ConnexionButton type="submit">Connexion</ConnexionButton>
							</Grid>
							<Grid item>
								<Link to="/motdepasseoublie"> Mot de passe oublié ? </Link>
							</Grid>
						</Grid>
					</Paper>
				</form>
				<div>
					<p className={classes.outils}>Un outil développé par</p>
				</div>
				<div className={classes.logoespelia}>
					<img src={LogoEspelia} alt="Logo Espelia" className={classes.logo} />
				</div>
			</div>
		</div>
    )
}

export default LoginPage