import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import ServiceAPI from '../../services/ServiceAPI';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ServiceOrganigramme from '../onglets/serviceOrganigramme';

import { Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TablePagination,
         TableRow,
		 TableSortLabel,
         FormGroup,
         FormControlLabel,
         Switch,
         TextField,
         Button,
         Grid,
         Dialog,
         DialogActions,
         DialogContent,
         DialogTitle,
		 Slide,
		 Typography,
		 DialogContentText,
		 CircularProgress
       } from '@material-ui/core';
      
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchBar from "material-ui-search-bar";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
    },
	paperRoot: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
		marginLeft: 5,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		//marginTop: 16,
		},
	},
	container: {
      [theme.breakpoints.up('xl')]: {
        maxHeight: 625,
      },
      [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
      },
    },
    tableheader: {
      textTransform: "uppercase",
      fontFamily: 'AvantGardeBold',
      color: '#3492aa',
      backgroundColor: '#ebebeb',
    },
    formControlLabel: {
      fontFamily: 'AvantGardeBook'
    },
    selectclient: {
      '& .MuiTextField-root': {
        width: 200,
      },
    },
     labelstyle: {
      fontFamily: 'AvantGardeBook',
    },
    search: {
      float: 'right',
	},
	textfieldSize: {
		width:"100%"
	},
	itembgColor: {
		color: "#3492aa",
		opacity: '0.5'
	},
	warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const SwitchCustom = withStyles({
  switchBase: {
    color: '#3492aa',
    '&$checked': {
      color: '#3492aa',
    },
    '&$checked + $track': {
      backgroundColor: '#3492aa',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ValidationParamButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#2d444f',
    '&:hover': {
      backgroundColor: '#3492aa',
    },
    fontFamily: 'AvantGardeBold',
    float: 'right'
  },
})(Button);

const OrganigrammeButton = withStyles({
	root: {
	  color: '#fff',
	  backgroundColor: '#eb6c29',
	  '&:hover': {
		backgroundColor: '#eb6c29',
	  },
	  fontFamily: 'AvantGardeBold',
	  float: 'left'
	},
  })(Button);
  

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: 'serviceXml', label: 'Service XML', minWidth: 200 },
  { id: 'serviceEspelia', label: 'Service Espelia', minWidth: 300 },
  { id: 'politiquePublique', label: 'Politique Publique', minWidth: 300 },
];

const Service = ({passClient}) => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:545px)');

	/* Pagination du tableau */
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [displayAll, setDisplayAll] = useState(false);

	const [allServices, setAllServices] = useState([]);
	const [nomenclatureService, setNomenclatureService] = useState([]);
	const [nomenclaturePolitiquePublique, setNomenclaturePolitiquePublique] = useState([]);
	const [selectedServiceRow, setSelectedServiceRow] = useState({});
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('serviceXml');
	const [send, setSend] = useState(false);
	const [open, setOpen] = useState(false);
	const [openExport, setOpenExport] = useState(false);
	const [serviceToSend, setServiceToSend] = useState([]);
	const [displayOrganigramme, setDisplayOrganigramme] = useState(false);
	const [reload, setReload] = useState(false);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

	const handleChangeDisplay = (event) => {
		setDisplayAll(!displayAll);
    }

	useEffect(() => {
		if(displayAll){
			setRows(allServices);
		} else {
			const filteredRows = allServices.filter((row) => {
				let rowToShow;
				if(!row.serviceEspelia){
					rowToShow = row;
				}
				return rowToShow;
			});
			setRows(filteredRows);
		}
	}, [displayAll, allServices]);

	const requestSearch = (searchedVal) => {
        const filteredRows = allServices.filter((row) => {
			let searchRequest;
			if(displayAll){
				searchRequest = row.serviceXml.toLowerCase().includes(searchedVal.toLowerCase());
			} else {
				if(!row.serviceEspelia){
					searchRequest = row.serviceXml.toLowerCase().includes(searchedVal.toLowerCase());
				}
			}
			return searchRequest;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

	const fetchAllServices = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseService = await ServiceAPI.findAllServices(passClient);
				setAllServices(responseService);
			} catch(error) {
				//console.log(error);
				toast.error('Une erreur est survenue lors de la récupération des services.');
			}
		}
	}, []);

	const fetchNomenclatureService = useCallback(async (passClient) => {
		if(passClient){
			try {
				const responseNomenclatureService = await ServiceAPI.findAllNomenclatures(passClient);
				setNomenclatureService(responseNomenclatureService);
			} catch(error) {
				console.log(error);
				toast.error('Une erreur est survenue lors de la récupération de la nomenclature des services.');
			}
		}
	}, []);

	const fetchNomenclaturePolitiquePublique = useCallback(async () => {
		try {
			const responseNomenclaturePolitiquePublique = await ServiceAPI.findAllNomenclaturesPolitiquePublique();
			setNomenclaturePolitiquePublique(responseNomenclaturePolitiquePublique);
		} catch(error) {
			console.log(error);
			toast.error('Une erreur est survenue lors de la récupération de la nomenclature des politiques pubiques.');
		}
	}, []);

	useEffect(() => {
		if(passClient !== null){
			fetchAllServices(passClient.idclient);
			fetchNomenclatureService(passClient.idclient);
			fetchNomenclaturePolitiquePublique();
		}
	}, [passClient, send, reload, fetchAllServices, fetchNomenclatureService, fetchNomenclaturePolitiquePublique]);

	const handleRowClick = (event, param) => {
		event.preventDefault();
		setSelectedServiceRow(param);
		handleClickOpenDialog("edit")
    }

	const handleChange = async (event, param1, param2) => {
		if(param2 === "update"){
			if(param1 !== null){
				if(param1.libelle) {
					setSelectedServiceRow({...selectedServiceRow, idPolitiquePublique: param1});
				} else {
					setSelectedServiceRow({...selectedServiceRow, serviceEspelia: param1.uf3, idServiceOrganigramme: param1});
				}
			} else {

			}
		} else {
			if(serviceToSend.some(service => service.idservice === param2.idservice)){
				if(param1 !== null){
					setServiceToSend([...serviceToSend].map(object => {
						if(object.idservice === param2.idservice){
							if(param1.libelle) {
								return {
									...object, idPolitiquePublique: param1
								}
							} else {
								return {
									...object, serviceEspelia: param1.uf3, idServiceOrganigramme: param1
								}
							}
						}
						else return object;
					}))
				} else {
					setServiceToSend(current =>
						current.filter(item => {
						  	// 👇️ remove object
						  	return item.idservice !== param2.idservice;
						}),
					);
				}
			} else {
				//* Duplique l'objet sans créer une référence à l'object original
				let changedService = Object.assign({}, param2);
				if(param1.libelle) {
					changedService.idPolitiquePublique = param1;
				} else {
					changedService.serviceEspelia = param1.uf3;
					changedService.idServiceOrganigramme = param1;
				}
				setServiceToSend([...serviceToSend, changedService]);
			}
		}
	};

	/* Gestion de la modale */
	const handleClickOpenDialog = (param) => {
		if(param === "export"){
			setOpenExport(true);
		} else {
			setOpen(true);
		}
	};

	const handleClose = (param) => {
		if(param === "export"){
			setOpenExport(false);
		} else {
			setOpen(false);
			setSelectedServiceRow([]);
		}
	};

	/* Envoie en bdd */
    const handleSubmit = async (param) => {
		if(param === "update"){
			try {
				delete selectedServiceRow.idclient;
				await ServiceAPI.changeService(selectedServiceRow.idservice, selectedServiceRow);
				handleClose("edit");
				toast.success("Enregistrement avec succès");
				setSend(!send);
			} catch(error){
				toast.error('Une erreur est survenue.');
			}
			setSelectedServiceRow({});
			setSend(!send);
		} else {
			serviceToSend.forEach(async (service) => {
				try {
					delete service.idclient;
					await ServiceAPI.changeService(service.idservice, service);
					setSend(!send);
					toast.success("Enregistrement avec succès");
				} catch(error){
					console.log(error);
					toast.error('Une erreur est survenue.');
				}
				setSend(!send);
			})
			setServiceToSend([]);
		}
	}

	const exportService = async () => {
		if(passClient.idclient){
			handleClickOpenDialog("export");
			try {
				await ServiceAPI.exportService({idclient: passClient.idclient});
				handleClose("export");
			} catch(error){
				console.log(error);
				handleClose("export");
				toast.error('Une erreur est survenue lors de l\'export des services.');
			}
		}
	}
	
    const handleChangePage = (event, newPage) => {
		event.preventDefault();
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
	
	const handleDisplayOrganigramme = () => {
		setDisplayOrganigramme(!displayOrganigramme);
		const section = document.querySelector('#anchorSousTab');
        section.scrollIntoView({ behavior: 'smooth', block: 'end' });
	}

	const handleReload = () => {
		setReload(!reload)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
   
    return (
		<div className={classes.root}>
		{passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
							<SettingsIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Paramétrage des services
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={3} justify="space-between" alignItems="flex-end">
					<Grid item container justify="flex-start" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={exportService}> 
							Export des services
						</ValidationParamButton> 
					</Grid> 
					<Grid item container justify="flex-end" sm={6} md={6} lg={6}> 
						<ValidationParamButton variant="contained" onClick={handleSubmit}> 
							Enregistrer les modifications 
						</ValidationParamButton> 
					</Grid> 
					<Grid item sm={12} md={12} lg={12}> 
						<FormGroup row>
							<FormControlLabel
								control={
									<SwitchCustom 
										onChange={handleChangeDisplay} 
										name="displayAll"
									/>
								}
								label={<span className={classes.labelstyle}>Tout afficher</span>}
							/>
						</FormGroup>
					</Grid>
				</Grid>
				<Paper className={classes.paperRoot}>
					<SearchBar
						placeholder="Rechercher un service"
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>
					<TableContainer className={classes.container}>
						<Table stickyHeader aria-label="sticky table" size="small">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											className={classes.tableheader}
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
											sortDirection={order}
										>
											<TableSortLabel
												active={orderBy === column.id}
												direction={orderBy === column.id ? order : 'asc'}
												onClick={(e) => handleRequestSort(e, column.id)}
											>
												{column.label}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(service => (
									<TableRow 
										key={service.idservice}
										hover={service.serviceEspelia != null ? true : false}
										onClick={(service.serviceEspelia || service.idPolitiquePublique) ? (event) => handleRowClick(event, service) : null}
									>
										<TableCell>{service.serviceXml}</TableCell>
										<TableCell>
											{service.serviceEspelia ?
												service.serviceEspelia
											:
												<Autocomplete
													filterSelectedOptions
													options={nomenclatureService.sort((a, b) => -b.uf2.localeCompare(a.uf2))}
													value={service.idServiceOrganigramme}
													getOptionLabel={(option) => option.uf3}
													getOptionSelected={(option, value) => option.uf3 === value.uf3}
													groupBy={(option) => option.uf2}
													id="selectNomenclatureService"
													onChange={(e, option) => handleChange(e, option, service)}
													style={{width: "100%"}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
														/>
													)}
												/>
											}
										</TableCell>
										<TableCell>
											{service.idPolitiquePublique ?
												service.idPolitiquePublique.libelle
											:
												<Autocomplete
													filterSelectedOptions
													options={nomenclaturePolitiquePublique.sort((a, b) => -b.domaine.localeCompare(a.domaine))}
													value={service.idPolitiquePublique}
													getOptionLabel={(option) => option.libelle}
													getOptionSelected={(option, value) => option.libelle === value.libelle}
													groupBy={(option) => option.domaine}
													id="selectNomenclaturePolitiquePublique"
													onChange={(e, option) => handleChange(e, option, service)}
													style={{width: "100%"}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
														/>
													)}
												/>
											}
										</TableCell>
									</TableRow>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage="Lignes par pages:"
						labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
						rowsPerPageOptions={[50, 100, 150]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							"aria-label": "Page précédente"
						}}
						nextIconButtonProps={{
							"aria-label": "Page suivante"
						}}

						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
				{passClient && 
					<OrganigrammeButton onClick={handleDisplayOrganigramme}>
						{displayOrganigramme === false && "Ouvrir l'Organigramme"} 
						{displayOrganigramme && "Fermer l'Organigramme"}
					</OrganigrammeButton>	
				}
				{displayOrganigramme && <ServiceOrganigramme reload={() => handleReload()} passServiceNomenclature={nomenclatureService} passIdClient={passClient.idclient} />}
				<div id="anchorSousTab">{'\u00A0'}</div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose("edit")}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title"><center>Modification du service</center></DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={selectedServiceRow.serviceXml ? selectedServiceRow.serviceXml : ''}
									variant="outlined"
									fullWidth
									label="Service XML"
									InputProps={{readOnly: true}}
									InputLabelProps={{shrink: true}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Autocomplete
									disableClearable
									filterSelectedOptions
									options={nomenclatureService.sort((a, b) => -b.uf2.localeCompare(a.uf2))}
									value={selectedServiceRow.idServiceOrganigramme ? selectedServiceRow.idServiceOrganigramme : null}
									getOptionLabel={(option) => option.uf3}
									getOptionSelected={(option, value) => option.uf3 === value.uf3}
									groupBy={(option) => option.uf2}
									id="selectNomenclatureService"
									onChange={(e, option) => handleChange(e, option, "update")}
									// style={{width: "100%"}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											fullWidth
											label={"Service Espelia"}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Autocomplete
									filterSelectedOptions
									options={nomenclaturePolitiquePublique.sort((a, b) => -b.domaine.localeCompare(a.domaine))}
									value={selectedServiceRow.idPolitiquePublique ? selectedServiceRow.idPolitiquePublique : null}
									getOptionLabel={(option) => option.libelle}
									getOptionSelected={(option, value) => option.libelle === value.libelle}
									groupBy={(option) => option.domaine}
									id="selectNomenclaturePolitiquePublique"
									onChange={(e, option) => handleChange(e, option, "update")}
									style={{width: "100%"}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={"Politique Publique"}

										/>
									)}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose("edit")}>
							Annuler
						</Button>
						<ValidationParamButton onClick={() => handleSubmit("update")}>						
							Modifier
						</ValidationParamButton>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					open={openExport}
					onClose={() => handleClose("export")}
					TransitionComponent={Transition}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-infodropzone"
					aria-describedby="alert-dialog-infodropzone"
				>
					<DialogContent>
						<center>
							<CircularProgress />
							<DialogContentText id="alert-dialog-send-file">
								Export des services en cours. <br/>
								Ceci peut prendre quelques minutes.<br/>
								Vous serez averti lorsque le processus sera terminé.
							</DialogContentText>
						</center>
					</DialogContent>
				</Dialog>    
				</main>
		}
		{!passClient &&
			<main>
				<div className={classes.toolbar} />
				<Grid item container justify="flex-start" sm={12} md={12} lg={12}>
					<Paper className={classes.warningPaper}>
						<Typography variant="body1">
							Pour visualiser et paramétrer les services, vous devez sélectionner un client
						</Typography>
					</Paper>
				</Grid>
			</main>
		}
		</div>
    )
}

export default Service