import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ls from 'localstorage-slim';

import DetailsParPaie from "../components/exports/detailsParPaie";
import FicheDePaie from "../components/exports/ficheDePaie";
import BaseAgentsXemelios from "../components/exports/baseAgentsXemelios";
import BaseAgentsDsn from "../components/exports/baseAgentsDsn";

import { Grid, 
         Typography,
         Paper,
         Tabs,
         Tab,
        } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
    root: {
		padding: theme.spacing(3),
		display: 'flex',
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
		float: 'left',
	},
	titlePage: {
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		marginLeft: 25,
		marginTop: 8,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
			marginLeft: 0,
			marginTop: 0,
		},
	},
    iconstyle: {
		marginTop: 12,
		float: 'right', 
		color: 'white',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
			marginTop: 16,
		},
	},   
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}))

const AntTabs = withStyles({
    root: {
        backgroundColor: '#2d444f',
        display: 'flex',
        justifyContent: 'center'
    },
    indicator: {
        backgroundColor: '#eb6c29',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 245,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontFamily: 'AvantGardeBold',
        fontSize: '1.2em',
        color: 'white',
        '&:hover': {
            color: '#eb6c29',
            opacity: 1,
        },
        '&$selected': {
            color: '#eb6c29',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#eb6c29',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Exports = () => {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:900px)');
	const responsiveDevice2 = useMediaQuery('(min-width:465px)');
    
    const [stateOnglets, setStateOnglets] = useState(0);
	const [client, setClient] = useState({});

	const getDecodedClient = () => {
        const client = ls.get('client', { decrypt: true })

		if(client){
			const decodedClient = JSON.parse(client);
			setClient(decodedClient);
		}
	}

	useEffect(() => {
		getDecodedClient();
	}, []);

    const handleChange = (event, newValue) => {
		setStateOnglets(newValue);
	};

    return (
        <div className={classes.root}>
            <main>
                <div className={classes.toolbar} />

				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
                    {/* <Grid container item className={classes.titleBox}> */}
                    <Grid item xs={12} lg={2} container justify="center">
                        {responsiveDevice2 ?
                            // <Grid container item justify="flex-end" xs={1} lg={2}>
                                <GetAppIcon className={classes.iconstyle} />
                            // </Grid>
                        : null}
                        {/* <Grid item xs={11} lg={10}> */}
                            <Typography variant="h6" className={classes.titlePage}>
                                Exports
                            </Typography>
                        {/* </Grid> */}
                    </Grid>
                    {responsiveDevice ? 
                        <Grid item xs={12} lg={10}>
                            <Paper>
                                <AntTabs
                                    value={stateOnglets}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                >
                                    <AntTab label="Détail Paie par mois" />
                                    <AntTab label="Fiche de paie" />
                                    <AntTab label="Base agents Xemelios" />
                                    <AntTab label="Base agents DSN" />
                                </AntTabs>
                            </Paper>
                        </Grid>
                    : null }
                </Grid>
				<Grid container spacing={3} justify="center">
                    {responsiveDevice ?
                        <Grid item xs={12} lg={12}>
                            {stateOnglets === 0 && <DetailsParPaie passClient={client} />}
                            {stateOnglets === 1 && <FicheDePaie passClient={client} />}
                            {stateOnglets === 2 && <BaseAgentsXemelios passClient={client} />}
                            {stateOnglets === 3 && <BaseAgentsDsn passClient={client} />}
                        </Grid>
                    :
                        <Grid container item xs={12} lg={12}>
                            <Paper className={classes.warningPaper}>
                                <Typography variant="body1">
                                    Le tableau n'est pas en mesure de s'afficher correctement sur votre écran. <br/>
                                    Veuillez passer sur un ordinateur afin
                                    pouvoir afficher le tableau de paramétrage.
                                </Typography>
                            </Paper>
                        </Grid> 
                    } 
                    {/* </Grid> */}
                </Grid>
            </main>
        </div>
    );
}

export default Exports