import axios from "axios";
import {CODEPAIECORRESP_API, CODEPAIENOMENC_API, EXPORT_CODE_PAIE_XLS, DELETERETURNEXCEL} from "../config.js";

function findAllCodePaies(param1){
    return axios
        .get(CODEPAIECORRESP_API + '?idclient.idclient=' + param1)
        .then(response => response.data['hydra:member']);
}

function findAllNomenclatures(){
    return axios
        .get(CODEPAIENOMENC_API)
        .then(response => response.data['hydra:member']);
}

function fetchOneCodePaie(param){
    return axios
        .get(CODEPAIECORRESP_API + "/" + param)
        .then(response => response.data);
}

function changeCodePaie(param1, param2){
    return axios
        .put(CODEPAIECORRESP_API + "/" + param1, param2);
}

function exportCodePaie(param){
    return axios({
        url: EXPORT_CODE_PAIE_XLS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Code_Paie_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

export default {
    findAllNomenclatures,
    findAllCodePaies,
    changeCodePaie,
    fetchOneCodePaie,
    exportCodePaie
}
