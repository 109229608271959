import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import AgentAPI from "../services/AgentAPI"

import { Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Grid,
    TextField,
    MenuItem,
    Dialog,
    DialogContent,
    Slide,
    Checkbox,
    FormControlLabel,
    DialogTitle,		
    DialogActions,
    Button,
    Popover,
    Typography
   } from '@material-ui/core';

import SearchBar from "material-ui-search-bar";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ValidationParamButton = withStyles({
	root: {
		color: '#fff',
		backgroundColor: '#2d444f',
		'&:hover': {
			backgroundColor: '#3492aa',
		},
		fontFamily: 'AvantGardeBold',
		float: 'right'
	},
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    papertab: {
		width: '100%',
		marginTop: 15,
	},
    container: {
        [theme.breakpoints.up('xl')]: {
            maxHeight: 300,
            marginTop: 10,
        },
        [theme.breakpoints.down(1900)]: {
            maxHeight: 300,
            marginTop: 10,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    cells: {
        textAlign: 'left'
    },
    tablebody: {
        fontFamily: 'AvantGardeBook'
    },
    selectclient: {
		'& .MuiTextField-root': {
            width: 200,
            marginLeft: '30%'
		},
	},
    popover: {
        width: 600,
        padding: 10
    },
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const columns = [
    { id: 'nomEmployeur', label: 'Employeur', minWidth: 120, align: 'left'},
    { id: 'nom', label: 'Nom', minWidth: 50 , align: 'left'},
    { id: 'prenom', label: 'Prénom',  minWidth: 50, align: 'left'},
    { id: 'matricule', label: 'Matricule', minWidth: 90, align: 'left'},
    { id: 'sexe', label: 'Sexe', minWidth: 100, align: 'left'},
    { id: 'moisNaissance', label: 'Mois de naissance', minWidth: 120, align: 'left'},
];

const columnsAgentMois = [
    { id: 'idagentMois', label: 'Id_agent_mois', minWidth: 30, align: 'left', margin: 0, padding: 0},
    { id: 'annee', label: 'Année', minWidth: 20 , align: 'left', margin: 0},
    { id: 'mois', label: 'Mois',  minWidth: 20, align: 'left', margin: 0},
    { id: 'statut', label: 'Statut', minWidth: 90, align: 'left', margin: 0},
    { id: 'grade', label: 'Grade', minWidth: 20, align: 'left', margin: 0},
    { id: 'service', label: 'Service', minWidth: 20, align: 'left', margin: 0},
    { id: 'echelon', label: 'Echelon', minWidth: 20, align: 'left', margin: 0},
    { id: 'emploiMetier', label: 'Emploi', minWidth: 20, align: 'left', margin: 0},
    { id: 'permanent', label: 'Permanent', minWidth: 20, align: 'left', margin: 0},
];

const FicheAgentComponent = ({passClient}) => {
    const classes = useStyles();

    const [employeurs, setEmployeurs] = useState([]);
    const [employeurSelected, setEmployeurSelected] = useState("all");
    const [agents, setAgents] = useState([]);
    const [agentsMois, setAgentsMois] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState({});
    const [selectedAgentMois, setSelectedAgentMois] = useState({});

    /* Utilisé pour la pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState("libellePaieXml");
    const [open, setOpen] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const requestSearch = (searchedVal) => {
        const filteredRows = agents.filter((row) => {
			let searchRequest = row.matricule.toLowerCase().includes(searchedVal.toLowerCase());
            return searchRequest;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const fetchEmployeur = async (passClient) => {
        if(passClient){
            try {
                const responseEmployeur = await AgentAPI.findEmployeur(passClient.idclient);
                setEmployeurs(responseEmployeur);
            } catch(error){
                console.log(error);
            }
        }
    }

    const fetchAgents = async (passClient) => {
        if(passClient){
            try {
                const responseAgents = await AgentAPI.findAgents(passClient.idclient);
                responseAgents.forEach((agent) => {
                    let month = new Date(agent.moisNaissance).getMonth() <= 9 ? "0"+ new Date(agent.moisNaissance).getMonth() : new Date(agent.moisNaissance).getMonth();
                    let year = new Date(agent.moisNaissance).getFullYear();
                    agent.moisNaissance = month+"/"+year;
                })

                setAgents(responseAgents);
                setRows(responseAgents);
            } catch(error) {
                console.log(error);
            }
        }
    }

    const fetchAgentsByEmployeur = (employeurSelected) => {
        if(employeurSelected !== "all"){
            let agentsByEmployeur = agents.filter((row) => {
                if(row.idemployeur.idemployeur === employeurSelected){
                    return row;
                }
            });
            setRows(agentsByEmployeur);
        } else {
            setRows(agents);
        }
    }   

    useEffect(() => {
        fetchAgents(passClient);
        fetchEmployeur(passClient);
    }, [passClient]);

    const fetchAgentsMois = async (selectedAgent) => {
        if(selectedAgent.idagent) {
            try {
                const responseAgentsMois = await AgentAPI.findAgentsMois(selectedAgent.idagent);
                responseAgentsMois.forEach((agent) => {
                    let month = agent.mois <= 9 ? "0"+ agent.mois : agent.mois;
                    agent.mois = month;
                })

                setAgentsMois(responseAgentsMois);
            } catch(error){
                console.log(error);
            }
        }
    }

    useEffect(() => {
        fetchAgentsMois(selectedAgent);
    }, [selectedAgent]);

    const handleSubmit = async () => {
        try {
            if(selectedAgentMois.mois.startsWith('0')){
                selectedAgentMois.mois = selectedAgentMois.mois.substring(1, selectedAgentMois.mois.length);
            }
            await AgentAPI.updateAgentMois(selectedAgentMois);
            reloopAssociation({id: passClient.idclient, idagentMois: selectedAgentMois.idagentMois});
            toast.success(`Modification avec succès de l'agent_mois "${selectedAgentMois.idagentMois}"`);
            setSelectedAgentMois({});
            setOpen(false);
            setOpenPopover(false);
            fetchAgentsMois(selectedAgent);
        } catch(error) {
            toast.error(`Une erreur est survenue sur l'agent_mois' "${selectedAgentMois.idagentMois}"`);
        }
    }

    const reloopAssociation = async (param) => {
        try {
            await AgentAPI.reassociation(param);
        } catch(error){
            console.log(error);
        }
    }

    /* Changement de l'idclient dans le select */
    const handleChange = (event) => {
        event.preventDefault();

        setEmployeurSelected(event.target.value);
        fetchAgentsByEmployeur(event.target.value);
        setSelectedAgent({});
        setSelectedAgentMois({});
	}

    const openPopUpEdit = (param) => {
        setOpen(!open);
        setSelectedAgentMois(param);
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

    return (
        <div className={classes.root}>
        {passClient &&
            <>        
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item sm={4} md={4} lg={4}>
                        <div className={classes.selectclient}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label={<span className={classes.labelstyle}>Employeur</span>}
                                value={employeurSelected}
                                onChange={(event) => handleChange(event)}
                                variant="outlined"
                                size='small'
                            >
                                <MenuItem value="all">
                                    Aucun
                                </MenuItem>
                                {employeurs.map((empl, j) => (
                                    <MenuItem key={empl+j} value={empl.idemployeur}>
                                        {empl.nomEmployeur}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
                <Paper className={classes.papertab}>
                    <SearchBar
                        placeholder="Rechercher un agent par matricule"
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                    />
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className={classes.tableheader}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            sortDirection={order}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'desc'}
                                                onClick={(e) => handleRequestSort(e, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>                                    
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,i) => (
                                    <TableRow 
                                        hover
                                        key={i}
                                        className={classes.tablebody}
                                        selected={selectedAgent.idagent === item.idagent ? true : false}
                                        onClick={() => setSelectedAgent(item)}
                                    >
                                        <TableCell className={classes.cells}>{item.idemployeur.nomEmployeur}</TableCell>
                                        <TableCell className={classes.cells}>{item.nom}</TableCell>
                                        <TableCell className={classes.cells}>{item.prenom}</TableCell>
                                        <TableCell className={classes.cells}>{item.matricule}</TableCell>
                                        <TableCell className={classes.cells}>{item.sexe === "2" ? "Féminin" : item.sexe === "1" ? "Masculin" : "Non défini"}</TableCell>
                                        <TableCell className={classes.cells}>{item.moisNaissance}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TablePagination
                    labelRowsPerPage="Lignes par pages:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Page précédente"
                        }}
                        nextIconButtonProps={{
                        "aria-label": "Page suivante"
                        }}
            
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {/* Second tableau */}
                {selectedAgent.idagent ?
                    <>
                        <Paper className={classes.papertab}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            {columnsAgentMois.map((column) => (
                                                <TableCell
                                                    className={classes.tableheader}
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    sortDirection={order}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'desc'}
                                                        onClick={(e) => handleRequestSort(e, column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>                                    
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(agentsMois, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, j) => (
                                            <TableRow 
                                                hover
                                                key={j}
                                                className={classes.tablebody}
                                                onClick={() => openPopUpEdit(item)}
                                            >
                                                <TableCell className={classes.cells}>{item.idagentMois}</TableCell>
                                                <TableCell className={classes.cells}>{item.annee}</TableCell>
                                                <TableCell className={classes.cells}>{item.mois}</TableCell>
                                                <TableCell className={classes.cells}>{item.statut}</TableCell>
                                                <TableCell className={classes.cells}>{item.grade}</TableCell>
                                                <TableCell className={classes.cells}>{item.service}</TableCell>
                                                <TableCell className={classes.cells}>{item.echelon}</TableCell>
                                                <TableCell className={classes.cells}>{item.emploiMetier}</TableCell>
                                                <TableCell className={classes.cells}>
                                                    <Checkbox
                                                            checked={item.permanent ? item.permanent : false}
                                                            disabled={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <TablePagination
                            labelRowsPerPage="Lignes par pages:"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                            rowsPerPageOptions={[50, 100, 150]}
                            component="div"
                            count={agentsMois.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Page précédente"
                                }}
                                nextIconButtonProps={{
                                "aria-label": "Page suivante"
                                }}
                    
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setOpen(false)}
                            disableBackdropClick
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title" className={classes.titleModale}><center>Modification de l'agent au matricule {selectedAgent.matricule}</center></DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={selectedAgentMois.statut ? selectedAgentMois.statut : ""}
                                            variant="outlined"
                                            fullWidth
                                            label="Statut"
                                            InputLabelProps={{shrink: true}}
                                            onChange={(event) => setSelectedAgentMois({...selectedAgentMois, statut: event.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={selectedAgentMois.grade ? selectedAgentMois.grade : ""}
                                            variant="outlined"
                                            fullWidth
                                            label="Grade"
                                            InputLabelProps={{shrink: true}}
                                            onChange={(event) => setSelectedAgentMois({...selectedAgentMois, grade: event.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={selectedAgentMois.service ? selectedAgentMois.service : ""}
                                            variant="outlined"
                                            fullWidth
                                            label="Service"
                                            InputLabelProps={{shrink: true}}
                                            onChange={(event) => setSelectedAgentMois({...selectedAgentMois, service: event.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={selectedAgentMois.echelon ? selectedAgentMois.echelon : ""}
                                            variant="outlined"
                                            fullWidth
                                            label="Échelon"
                                            InputLabelProps={{shrink: true}}
                                            onChange={(event) => setSelectedAgentMois({...selectedAgentMois, echelon: event.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={selectedAgentMois.emploiMetier ? selectedAgentMois.emploiMetier : ""}
                                            variant="outlined"
                                            fullWidth
                                            label="Emploi"
                                            InputLabelProps={{shrink: true}}
                                            onChange={(event) => setSelectedAgentMois({...selectedAgentMois, emploiMetier: event.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControlLabel 
                                            control={ 
                                                <Checkbox
                                                    checked={selectedAgentMois.permanent ? selectedAgentMois.permanent : false }
                                                    onChange={(event) => setSelectedAgentMois({...selectedAgentMois, permanent: event.target.checked})}
                                                />}
                                            label="Permanent" 
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <ValidationParamButton onClick={() => setOpen(false)}>
                                    Annuler
                                </ValidationParamButton>      
                                <ValidationParamButton onClick={(event) => {setOpenPopover(true); setAnchorEl(event.currentTarget);}}>
                                    Modifier
                                </ValidationParamButton>      
                            </DialogActions>
                        </Dialog>
                        <Popover
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={() => setOpenPopover(false)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Grid container className={classes.popover}> 
                                <Grid item container justify="center" alignItems="center">
                                    <Typography paragraph variant="body1" component="p" align="center">Les modifications sont irréversibles concernant les données brutes. <br/> Êtes-vous sûr de vouloir continuer ?</Typography>
                                </Grid>
                                <Grid item container alignItems="center" justify="center">
                                    <Grid item>
                                        <Button onClick={() => setOpenPopover(false)} color="inherit">
                                            Non, je refuse
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ValidationParamButton onClick={() => handleSubmit()} color="inherit">
                                            Oui, je souhaite continuer
                                        </ValidationParamButton>
                                    </Grid>
                                </Grid>
                            </Grid>            
                        </Popover>
                    </>
                    : null
                }
            </>
        }
        {!passClient &&
            <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                <Paper className={classes.warningPaper}>
                    <Typography variant="body1">
                        Pour visualiser les fiches agents, vous devez sélectionner un client
                    </Typography>
                </Paper>
            </Grid>
        }
        </div>
    )
}

export default FicheAgentComponent