import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import EffectifAPI from '../../services/EffectifAPI';

import { Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TablePagination,
         TableRow,
         TableSortLabel,
         IconButton,
         Tooltip,
         Grid,
         TextField,
         MenuItem,
         Dialog,
         DialogContent,
         DialogContentText,
         Slide,
         CircularProgress,
         Typography
        } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    papertab: {
		width: '100%',
		marginTop: 40,
		marginBottom: 40,
	},
    container: {
        [theme.breakpoints.up('xl')]: {
            maxHeight: 710,
            marginTop: 10,
        },
        [theme.breakpoints.down(1900)]: {
            maxHeight: 700,
            marginTop: 10,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    cells: {
        textAlign: 'center'
    },
    tablebody: {
        fontFamily: 'AvantGardeBook'
    },
    selectclient: {
		'& .MuiTextField-root': {
            width: 200,
            marginLeft: '30%'
		},
	},
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'nbAgents', label: 'Nombre d\'agents', minWidth: 120 , align: 'center'},
    { id: 'annee', label: 'Année',  minWidth: 50, align: 'center'},
    { id: 'mois', label: 'Mois', minWidth: 90, align: 'center'},
    { id: 'suppression', label: '', minWidth: 170, align: 'right'},
];

const EffectifDsn = ({passClient}) => {
    const classes = useStyles();
    
    // const [idClient, setIdClient] = useState('all');
    const [yearSelected, setYearSelected] = useState("all");
    const [effectifs, setEffectifs] = useState([]);
    const [send, setSend] = useState(false);
    const [open, setOpen] = useState(false);
    const [years, setYears] = useState([]);
    /* Utilisé pour la pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState("libellePaieXml");
    const [rows, setRows] = useState([]);
    // const [searched, setSearched] = useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const filteredRows = effectifs.filter((row) => {
            let rowToShow;
                if(yearSelected !== "all"){
                    if(row.annee === yearSelected){
                        rowToShow = row;
                    }
                } else {
                    rowToShow = row;
                }
            return rowToShow;
        });
        setRows(filteredRows);
	}, [effectifs, yearSelected]);

    const fetchEffectif = useCallback(async (passClient) => {
        if(passClient.idclient){
            try {
                const responseEffectif = await EffectifAPI.sendIdForEffectifDsn({id: passClient.idclient});
                setEffectifs(responseEffectif);
                setRows(responseEffectif);

                let yearsTab = [];
                responseEffectif.forEach((item) => {
                    item.annee = new Date(item.moisDeclaration).getFullYear()
                    if(new Date(item.moisDeclaration).getMonth() + 1 <= 9){
                        let nextMonth = new Date(item.moisDeclaration).getMonth() + 1;
                        item.mois = "0" + nextMonth;
                    } else {
                        item.mois = new Date(item.moisDeclaration).getMonth() + 1;
                    }
                    yearsTab.push(item.annee);
                })
                setYears(yearsTab.filter(function(ele , pos){return yearsTab.indexOf(ele) === pos;}))
            } catch(error) {
                console.log(error);
            }
        }
    }, []);

    useEffect(() => {
        fetchEffectif(passClient);
    }, [passClient, send, fetchEffectif]);

    const handleDeleteEffectif = async (event, param) => {
        event.preventDefault();
        setOpen(true); 
        try {
            await EffectifAPI.sendIdEmployeurForDeleteDsn(param.idclient, param.moisDeclaration);
            setSend(!send);
            handleClose();
            toast.success(`Suppression des effectifs effectuées pour la date du ${param.moisDeclaration}.`);
        } catch(error) {
            //console.log(error);
            toast.error(`Echec de la suppression des effectifs.`);
        }
	}

    const handleClose = () => {
		setOpen(false);
    };

    /* Changement de l'idclient dans le select */
    const handleChange = (event, param) => {
        event.preventDefault();
        setYearSelected(event.target.value);
	}

    const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

    return (
        passClient.idclient ?
            <>
                <Grid item container sm={12} md={12} lg={12} justify="center" alignItems="center">
                    <div className={classes.selectclient}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label={<span className={classes.labelstyle}>Année</span>}
                            value={yearSelected}
                            onChange={(event) => handleChange(event)}
                            variant="outlined"
                            size='small'
                        >
                            <MenuItem value="all">
                                Aucun
                            </MenuItem>
                            {years.map((y, i) => (
                                <MenuItem key={i} value={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </Grid>
                <Paper className={classes.papertab}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className={classes.tableheader}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            sortDirection={order}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'desc'}
                                                onClick={(e) => handleRequestSort(e, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>                                    
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length > 0 ?
                                    stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,i) => (
                                        <TableRow 
                                            hover
                                            key={i}
                                            className={classes.tablebody}
                                        >
                                            <TableCell className={classes.cells}>{item.nbAgents}</TableCell>
                                            <TableCell className={classes.cells}>{item.annee}</TableCell>
                                            <TableCell className={classes.cells}>{item.mois}</TableCell>
                                            <TableCell className={classes.cells}>
                                                <Tooltip title="Supprimer">
                                                    <IconButton aria-label="delete" onClick={(event) => handleDeleteEffectif(event, item)}/*className={classes.margin}*/>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                : 
                                    <TableRow className={classes.tablebody}>
                                        <TableCell className={classes.cells}></TableCell>
                                        <TableCell className={classes.cells}>
                                            Absence de DSN
                                        </TableCell>
                                        <TableCell className={classes.cells}></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TablePagination
                    labelRowsPerPage="Lignes par pages:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Page précédente"
                        }}
                        nextIconButtonProps={{
                        "aria-label": "Page suivante"
                        }}
            
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    aria-labelledby="alert-dialog-slide-effectif"
                    aria-describedby="alert-dialog-slide-effectif"
                >
                    <DialogContent>
                        <center>
                            <CircularProgress />
                            <br/><br/>
                            <DialogContentText className={classes.fontstyle} id="alert-dialog-depot">
                                Suppression de l'effectif en cours. <br/>
                                Veuillez patienter.
                            </DialogContentText>
                        </center>
                    </DialogContent>
                </Dialog>

            </>
        : 
            <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                <Paper className={classes.warningPaper}>
                    <Typography variant="body1">
                        Pour visualiser les effectifs dsn, vous devez sélectionner un client
                    </Typography>
                </Paper>
            </Grid>
        
    )
}

export default EffectifDsn