import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FicheAgentComponent from '../components/ficheAgent.jsx';

import { Grid, 
         Typography
       } from '@material-ui/core';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			marginLeft: '15vw',
			marginRight: '2vw'
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '11vw',
		},
		[theme.breakpoints.down('lg')]: {
			marginRight: '2vw',
		},
	},
	toolbar: theme.mixins.toolbar,
	titleBox: {
		backgroundColor: '#2d444f', 
		borderRadius: 5,
		marginBottom: 20,
	},
	titlePage: {
		marginLeft: 25,
		color: 'white',
		fontFamily: "AvantGardeBold",
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em'
		},
		[theme.breakpoints.down('465')]: {
			fontSize: '1em',
		},
	},
	iconstyle: {
		float: 'right', 
		color: 'white',
		marginTop: 5,
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		},
  	},
}));

const FicheAgent = ({passingClient}) => {
  	const classes = useStyles();

  	const responsiveDevice = useMediaQuery('(min-width:545px)');

  	return (
		<div className={classes.root}>
			<main>
				<div className={classes.toolbar} />
				<Grid container spacing={2} justify="flex-start" className={classes.titleBox}>
					<Grid container item xs={12} lg={6} justify="center">
						{responsiveDevice ?
							<AssignmentIndIcon className={classes.iconstyle} />
						: null}
						<Typography variant="h6" className={classes.titlePage}>
							Fiche agent
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={3} justify="center">   
					<Grid item xs={12} lg={12}>
						<FicheAgentComponent passClient={passingClient} />
					</Grid>
				</Grid>
			</main>
		</div>
 	);
}

export default FicheAgent
