import axios from "axios";
import {SERVICECORRESPONDANCE_API, SERVICENOMENC_API, SERVICENOMENCPOLITIQUEPUBLIQUE_API, EXPORT_SERVICE_XLS, DELETERETURNEXCEL} from "../config.js";

function findAllServices(param1){
    return axios
        .get(SERVICECORRESPONDANCE_API + '?idclient.idclient=' + param1)
        .then(response => response.data['hydra:member']);
}

function fetchOneService(param){
    return axios
        .get(SERVICECORRESPONDANCE_API + "/" + param)
        .then(response => response.data);
}

function changeService(param1, param2){
    return axios
        .put(SERVICECORRESPONDANCE_API + "/" + param1, param2);
}

function deleteService(param){
    return axios
        .delete(SERVICECORRESPONDANCE_API + "/" + param);
}

function findAllNomenclatures(param) {
    return axios
        .get(SERVICENOMENC_API + '?idClient=' + param)
        .then(response => response.data['hydra:member']);
}

function findAllNomenclaturesPolitiquePublique() {
    return axios
        .get(SERVICENOMENCPOLITIQUEPUBLIQUE_API)
        .then(response => response.data['hydra:member']);
}

function addNomenclature(param){
    return axios
        .post(SERVICENOMENC_API, param);
}

function deleteNomenclature(param){
    return axios
        .delete(SERVICENOMENC_API + '/' + param);
}

function updateNomenclature(param, param2){
    return axios
        .put(SERVICENOMENC_API + '/' + param, param2);
}

function exportService(param){
    return axios({
        url: EXPORT_SERVICE_XLS,
        method: 'POST',
        data: param,
        responseType: 'blob', // important
    }).then((response) => {
        let filename = "Service_" + param.idclient + "_" + new Date().toLocaleString('fr-FR') + ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        axios.get(DELETERETURNEXCEL);
    });
}

export default {
    findAllServices,
    fetchOneService,
    changeService,
    findAllNomenclatures,
    findAllNomenclaturesPolitiquePublique,
    deleteService,
    addNomenclature,
    deleteNomenclature,
    updateNomenclature,
    exportService
}