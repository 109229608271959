import React, {useContext, useState, useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import jwtDecode from "jwt-decode";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Swal from 'sweetalert2';
import ls from 'localstorage-slim';

import AuthAPI from "../../services/AuthAPI";
import AuthContext from "../../contexts/AuthContext"
import Gsrh from "../../images/logogsrhompact.png";
import Logo from "../../images/logogsrh2.png";

import { Drawer,
         CssBaseline,
         Toolbar,
         ListItemText,
         ListItemIcon,
         AppBar,
         List,
         Typography,
         Divider,
         ListItem,
         Button,
         IconButton,
		 Popover,
		 TextField,
		 Grid,
		 Collapse 
       } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import BarChartIcon from '@material-ui/icons/BarChart';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import ForumIcon from '@material-ui/icons/Forum';
import PersonIcon from '@material-ui/icons/Person';
import TuneIcon from '@material-ui/icons/Tune';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#3492aa"
	},
	drawer: {
		width: 200,
		flexShrink: 0,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	title: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: theme.mixins.toolbar,
	divlogogsrh: {
		width: 100,
		marginTop: 10,  
	},
	logogsrh: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	sidebarlink: {
		textDecoration: 'none',
	},
	sidebar: {
		margin: 0,
		width: 205,
		backgroundColor: '#4b4644',
		height: '100%',
	},
	icon: {
		color: 'white',
	},
	icontext: {
		fontFamily: 'AvantGardeBold',
		color: 'white',
		fontSize: 12
	},
	logocontainer: {
		width: 80,
		height: 80,
		left: "28%",
		position: 'absolute',
		bottom: 10
	},
	logo: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
	},
	listitem: {
		'&:hover': {
			backgroundColor: "#eb6c29",
		},
		'&:active': {
			backgroundColor: "#6b95a3",
		}
	},
	"& .MuiTypographyRoot": {
		fontSize: '1.5em'
	},
	popover: {
		width: 300,
		padding: 10
	},
	divider: {
		marginTop: 10,
		marginBottom: 10
	},
	iconUser: {
		marginTop: 5,
		marginBottom: 10
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const CutsomizeListItem = withStyles({
	root: {
	  "&$selected": {
		backgroundColor: "rgba(235, 108, 41, 0.5)",
		color: "white",
		"& .MuiListItemIcon-root": {
		  color: "white"
		}
	  },
	  "&$selected:hover": {
		backgroundColor: "rgba(235, 108, 41, 0.5)",
		color: "white",
		"& .MuiListItemIcon-root": {
		  color: "white"
		}
	  },
	  "&:hover": {
		backgroundColor: "rgba(235, 108, 41, 0.5)",
		color: "white",
		"& .MuiListItemIcon-root": {
		  color: "white"
		}
	  }
	},
	selected: {}
  })(ListItem);

export default function Navbar({history, passingClient, setPassingClient, clientsArr, reload}) {
	const classes = useStyles();

	const responsiveDevice = useMediaQuery('(min-width:1280px)');

	const UrlPBI = "//app.powerbi.com";
	const UrlJalios = "//sp2000.jaliosagora.com/jcms/c_1371796/fr/gs-rh-communaute";

	const [state, setState] = useState({left: false});
	const [token, setToken] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openSubList, setOpenSubList] = useState({
		administrer: false,
		deposer: false,
		parametrer: false,
		analyser: false,
		echanger: false
	});
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const [clientStorage, setClientStorage] = useState(null);
	const [clients, setClients] = useState([]);
	
	useEffect(() => {
		setClients(clientsArr);
	}, [clientsArr]);

	// Fonction permettant d'ouvrir le burgermenu
	const toggleDrawer = (side, open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [side]: open });
	};

	const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);

	const handleLogout = () => {
		setPassingClient(null);
		setIsAuthenticated(false);
		AuthAPI.logout();
		reload();
	}
	
	/* Permet de récupérer le token et de connaitre le role de l'utilisateur */
	const getDecodedToken = () => {
		const tokenBrut = window.localStorage.getItem("authToken");
		
		if(tokenBrut){
			const decodedToken = jwtDecode(tokenBrut);
			setToken(decodedToken);
			// getClients(decodedToken.id);
		}
	}

	useEffect(() => {
		getDecodedToken();
	}, []);

	useEffect(() => {
		setClientStorage(passingClient);
	}, [passingClient]);

	const logOutExpiredToken = () => {
		if(AuthAPI.isAuthenticated() === false){
		  	Swal.fire({
				icon: 'error',
				title: 'La session a expiré.',
				text: 'Veuillez-vous reconnecter.',
			});
		  	handleLogout();
		}
	}

	useEffect(() => {
		logOutExpiredToken();
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleChangeClient = (event, option) => {
		event.preventDefault();

		if(option === null){
			window.localStorage.removeItem("client");
		} else {
			ls.set("client", JSON.stringify(option), { encrypt: true });            
		}
		setPassingClient(option);
    }

	const handleOpenSubList = (param) => {
		setOpenSubList({...openSubList, [param]: !openSubList[param]})
	}

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
	};

  	// Onglets de la sidebar
	const sideList = side => (
		<div className={classes.sidebar} onKeyDown={toggleDrawer(side, false)}>
			<List>
				<NavLink to="/accueil" className={classes.sidebarlink}>
					<ListItem 
						button 
						className={classes.listitem} 
						selected={selectedIndex === 0}
						onClick={(event) => handleListItemClick(event, 0)}
					>
						<ListItemIcon><HomeIcon className={classes.icon}/></ListItemIcon>
						<ListItemText className={classes.icontext}>Accueil</ListItemText>
					</ListItem>
				</NavLink>
				<Divider />
				{token ? 
					token.roles.map((token, i) => (
						token === "ROLE_ADMIN" ?
							<React.Fragment key={"lien"+i}>
									<ListItem button className={classes.listitem} onClick={() => handleOpenSubList("administrer")}>
										<ListItemIcon>
											<StarBorder className={classes.icon} />
										</ListItemIcon>
										<ListItemText primary="Administrer" className={classes.icontext} />
										{openSubList.administrer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
									</ListItem>
									<Collapse in={openSubList.administrer} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											<NavLink to="/compte" className={classes.sidebarlink}>
												<CutsomizeListItem 
													button 
													className={classes.nested} 
													selected={selectedIndex === 1}
													onClick={(event) => handleListItemClick(event, 1)}
												>
													<ListItemText primary="Compte" className={classes.icontext} />
												</CutsomizeListItem>
											</NavLink>

										</List>
									</Collapse>
								<Divider />
							</React.Fragment>
						: null
					)) 
				: null
				}
				<Divider />
				<ListItem button className={classes.listitem} onClick={() => handleOpenSubList("deposer")}>
					<ListItemIcon>
						<MoveToInboxIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Déposer" className={classes.icontext} />
					{openSubList.deposer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItem>
				<Collapse in={openSubList.deposer} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<NavLink to="/depotXemelios" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 2}
								onClick={(event) => handleListItemClick(event, 2)}
							>
								<ListItemText primary="Dépôt des xemelios" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/depotDns" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 3}
								onClick={(event) => handleListItemClick(event, 3)}
							>
								<ListItemText primary="Dépôt des DSN" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/effectif" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 4}
								onClick={(event) => handleListItemClick(event, 4)}
							>
								<ListItemText primary="Vérification des effectifs" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/exports" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 5}
								onClick={(event) => handleListItemClick(event, 5)}
							>
								<ListItemText primary="Exports" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
					</List>
				</Collapse>
				<Divider />
				<ListItem button className={classes.listitem} onClick={() => handleOpenSubList("parametrer")}>
					<ListItemIcon>
						<TuneIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Paramétrer" className={classes.icontext} />
					{openSubList.parametrer ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItem>
				<Collapse in={openSubList.parametrer} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<NavLink to="/grades" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 6}
								onClick={(event) => handleListItemClick(event, 6)}
							>
								<ListItemText primary="Grades" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/codespaie" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 7}
								onClick={(event) => handleListItemClick(event, 7)}
							>
								<ListItemText primary="Codes paie" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/services" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 8}
								onClick={(event) => handleListItemClick(event, 8)}
							>
								<ListItemText primary="Services" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/metiers" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 9}
								onClick={(event) => handleListItemClick(event, 9)}
							>
								<ListItemText primary="Métiers" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/echelons" className={classes.sidebarlink}>
							<CutsomizeListItem
								button 
								className={classes.nested} 
								selected={selectedIndex === 10}
								onClick={(event) => handleListItemClick(event, 10)}
							>
								<ListItemText primary="Échelons" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/ficheAgent" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 11}
								onClick={(event) => handleListItemClick(event, 11)}
							>
								<ListItemText primary="Fiche agent" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
						<NavLink to="/autresbases" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 12}
								onClick={(event) => handleListItemClick(event, 12)}
							>
								<ListItemText primary="Autres bases" className={classes.icontext} />
							</CutsomizeListItem>
						</NavLink>
					</List>
				</Collapse>
				<Divider />
				<ListItem button className={classes.listitem} onClick={() => handleOpenSubList("analyser")}>
					<ListItemIcon>
						<BarChartIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Analyser" className={classes.icontext} />
					{openSubList.analyser ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItem>
				<Collapse in={openSubList.analyser} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<a href={UrlPBI} target="_blank" rel="noopener noreferrer" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 13}
								onClick={(event) => handleListItemClick(event, 13)}
							>
								<ListItemText className={classes.icontext}>PBI</ListItemText>
							</CutsomizeListItem>
						</a>
					</List>
				</Collapse>
				<Divider />
				<ListItem button className={classes.listitem} onClick={() => handleOpenSubList("echanger")}>
					<ListItemIcon>
						<ForumIcon className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Échanger" className={classes.icontext} />
					{openSubList.echanger ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />}
				</ListItem>
				<Collapse in={openSubList.echanger} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<a href={UrlJalios} target="_blank" rel="noopener noreferrer" className={classes.sidebarlink}>
							<CutsomizeListItem 
								button 
								className={classes.nested} 
								selected={selectedIndex === 14}
								onClick={(event) => handleListItemClick(event, 14)}
							>
								<ListItemText className={classes.icontext}>Support & Communauté</ListItemText>
							</CutsomizeListItem>
						</a>
					</List>
				</Collapse>
			</List>
			<div className={classes.logocontainer}>
				<img src={Logo} alt="Logo GS'RH" className={classes.logo} />
			</div>
		</div>
	);

    if(responsiveDevice){
		return (
			// Si plus grand qu'un smartphone
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						<div className={classes.divlogogsrh}>
							<img className={classes.logogsrh} src={Gsrh} alt="Logo GS'RH" />
						</div>
					</Typography>
					{!isAuthenticated ? 
						<Link to="/seconnecter" className={classes.sidebarlink}>
							<Button color="white">Login</Button>
						</Link>
					: 
						<IconButton onClick={handleClick} aria-label="compte" aria-describedby={id}>
							<AccountBoxIcon fontSize="large" style={{color: "white"}} />
						</IconButton>
					}
				</Toolbar>
				</AppBar>
				<Drawer 
					className={classes.drawer}
					variant="permanent"
				> 
					<div className={classes.toolbar} />
					{sideList('left')}
				</Drawer>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div className={classes.popover}>
						{token &&
						<>
							<Grid container className={classes.iconUser}>
								<PersonIcon style={{marginRight: 5}} />
								<Typography>
									Bonjour, {token.prenom} {token.nom}
								</Typography>
							</Grid>
							<Divider className={classes.divider} />
						</>
						}
						<Autocomplete
							filterSelectedOptions
							options={clients}
							value={clientStorage}
							getOptionLabel={(option) => option.nomClient}
							getOptionSelected={(option, value) => option.idclient === value.idclient}
							renderInput={(params) =>
								<TextField {...params} 	
									variant="outlined" label="Choix du client"
								/>
							}
							onChange={(e, option) => handleChangeClient(e, option)}
							disabled={clients.length > 1 ? false : true}
						/>
						<Divider className={classes.divider} />
						<Button onClick={handleLogout} color="inherit">Déconnexion</Button>
					</div>

				</Popover>
			</div>
		)
    } else {
		return (
			// Si taille smartphone
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							<div className={classes.divlogogsrh}>
								<img className={classes.logogsrh} src={Gsrh} alt="Logo GS'RH" />
							</div>
						</Typography>
						{!isAuthenticated ? 
							<Link to="/seconnecter" className={classes.sidebarlink}>
								<Button color="white">Login</Button>
							</Link>
						: 
							<IconButton onClick={handleClick} aria-label="compte" aria-describedby={id}>
								<AccountBoxIcon fontSize="large" style={{color: "white"}} />
							</IconButton>
						}
					</Toolbar>
				</AppBar>
				<Drawer open={state.left} onClose={toggleDrawer('left', false)}>
					{sideList('left')}
				</Drawer>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div className={classes.popover}>
						{token &&
						<>
							<Grid container className={classes.iconUser}>
								<PersonIcon style={{marginRight: 5}} />
								<Typography>
									Bonjour, {token.prenom} {token.nom}
								</Typography>
							</Grid>
							<Divider className={classes.divider} />
						</>
						}
						<Autocomplete
							filterSelectedOptions
							options={clients}
							value={clientStorage}
							getOptionLabel={(option) => option.nomClient}
							getOptionSelected={(option, value) => option.idclient === value.idclient}
							renderInput={(params) =>
								<TextField {...params} 	
									variant="outlined" label="Choix du client"
								/>
							}
							onChange={(e, option) => handleChangeClient(e, option)}
							disabled={clients.length > 1 ? false : true}
						/>
						<Divider className={classes.divider} />
						<Button onClick={handleLogout} color="inherit">Déconnexion</Button>
					</div>

				</Popover>
			</div>
		)
    }
}

