import React, { useEffect, useState } from 'react';
import UserAPI from '../../services/UserAPI';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";

import { Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TablePagination,
         TableRow,
         TextField,
         Button,
         Grid,
         Dialog,
         DialogActions,
         DialogContent,
         DialogTitle,
         Slide,
         MenuItem,
         Checkbox,
         Collapse,
         Box,
         Tooltip,
         IconButton,
         FormControlLabel,
         TableSortLabel
        } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import SearchBar from "material-ui-search-bar";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginBottom: 30
    },
    container: {
        [theme.breakpoints.up('xl')]: {
            maxHeight: 640,
        },
        [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    cells: {
        textAlign: 'center'
    },
    tablebody: {
        fontFamily: 'AvantGardeBook'
    }
}));

const ValidationParamButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#2d444f',
      '&:hover': {
        backgroundColor: '#3492aa',
      },
      fontFamily: 'AvantGardeBold',
      float: 'right'
    },
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'prenom', label: 'Prénom', minWidth: 120 },
    { id: 'nom', label: 'Nom', minWidth: 120 },
    { id: 'email', label: 'Adresse email',  minWidth: 50, align: 'center'},
    { id: 'roles', label: 'Rôle accordé', minWidth: 90, align: 'center'},
    { id: 'collapse', label: 'Clients', minWidth: 50, align: 'center', display: false},
    { id: 'edition', label: 'Éditer', minWidth: 50, align: 'center', display: false},
];

const UserList = ({reloadTab, passUsers, passClients, reload}) => {
    const classes = useStyles();
    /* Pagination du tableau */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [userProfil, setUserProfil] = useState({});
    const [role, setRole] = useState('');
    const [idclient, setIdclient] = useState([]);
    const [open, setOpen] = useState(false);
    const [collapsing, setCollapsing] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [rows, setRows] = useState(passUsers);
    const [searched, setSearched] = useState("");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState("prenom");

    useEffect(() => {
        setRows(passUsers);
    }, [passUsers]);
    
    const fetchUserProfil = async (param) => {
        try {
            const profil = await UserAPI.fetchUserProfil(param);
            let clientUser = []
            profil.clients.forEach((client) => {
                clientUser.push("/api/clients/" + client.idclient);
            });
            setUserProfil({
                id: profil.id,
                prenom: profil.prenom,
                nom: profil.nom,
                email: profil.email,
                roles: profil.roles,
                clients: clientUser
            });
            setRole(profil.roles[0]);

            const clientsassociés = profil.clients;

            clientsassociés.forEach(client => {
                setIdclient(clients => [...clients, client]);
            })
         } catch(error) {
            toast.error("Une erreur est survenue concernant la récupération du profil de l'utilisateur demandé.");
        }
    }
    const handleSubmitModif = async (event) => {
        event.preventDefault();

        try {
            const idClients = [];
            if(selectAll){
                const value = userProfil.clients;
                value.forEach(user => {
                    idClients.push("/api/clients/" + user.idclient);
                })
                userProfil.clients = idClients;
            }
            await UserAPI.changeUserProfil(userProfil.id, userProfil);
            handleClose();
            toast.success(`Modification de l'utilisateur ${userProfil.prenom} ${userProfil.nom} réussi.`);
            setUserProfil({});
            setIdclient([]);
            reloadTab();
            reload();
        } catch(error) {
            toast.error("Une erreur est survenue lors de la modification du profil de l'utilisateur.");
        }
    }

    const handleRowClick = (event, param) => {
        setOpen(true);
        fetchUserProfil(param);
    }

    const handleModifyProfil = (event, param, option = null) => {
        if(param === "roles" || param === "clients"){
            if(param === "clients"){
                setIdclient(option); 
                const idValue = [];

                option.forEach(id => {
                    idValue.push("/api/clients/" + id.idclient);
                })
                
                setUserProfil({...userProfil, [param] : idValue});
            } else {
                setRole(event.target.value);
                setUserProfil({...userProfil, [param] : [event.target.value]});
            }
        } else if(param === "all") {
            if(selectAll){
                setSelectAll(false);
                setUserProfil({...userProfil, clients: []});
            } else if(selectAll === false){
                setSelectAll(true);
                setIdclient([]);
                setUserProfil({...userProfil, clients: passClients});
            }
        } else {
            setUserProfil({...userProfil, [param] : event.target.value});

        }
    };

    const handleDeleteUser = async (param) => {
        try {
            await UserAPI.deleteUser(param.id);
            handleClose();
            reloadTab();
            toast.success(`Suppression de l'utilisateur ${userProfil.prenom} ${userProfil.nom} réussi.`);

        } catch(error){
            console.log(error);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setIdclient([]);
        setSelectAll(false);
        setCollapsing({});
    };

    /* Utilisé pour la pagination du tableau */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = passUsers.filter((row) => {
            return row.nom.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}
	  
	function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

    return (
        <>
            <Paper>
                <SearchBar
                    placeholder="Rechercher un utilisateur par son nom"
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        className={classes.tableheader}
                                        key={column.id}
                                        align={column.align}
                                        sortDirection={order}
                                    >
                                        {column.display === false ? 
                                            column.label
                                        : 
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(e) => handleRequestSort(e, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                                <React.Fragment key={user.id}>
                                    <TableRow 
                                        hover
                                        key={user.id}
                                        className={classes.tablebody}
                                    >
                                        <TableCell>{user.prenom}</TableCell>
                                        <TableCell>{user.nom}</TableCell>
                                        <TableCell className={classes.cells}>{user.email}</TableCell>
                                        <TableCell className={classes.cells}>{user.roles[0] === "ROLE_ADMIN" ? "ADMINISTRATEUR" : "UTILISATEUR"}</TableCell>
                                        <TableCell className={classes.cells}> 
                                            <IconButton aria-label="expand row" size="small" onClick={() => setCollapsing({...collapsing, [user.id]: !collapsing[user.id]} )}>
                                                {collapsing[user.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={classes.cells}>
                                            <Tooltip title="Editer">
                                                <IconButton aria-label="edit" onClick={(event) => handleRowClick(event, user.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={collapsing[user.id]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                {user.clients.map((client) => { 
                                                    return <span key={client.idclient}>({client.idclient}) {client.nomClient} | </span>
                                                })}
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <TablePagination
                labelRowsPerPage="Lignes par pages:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count}`}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={passUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "Page précédente"
                    }}
                    nextIconButtonProps={{
                    "aria-label": "Page suivante"
                    }}
        
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"><center>Modification de l'utilisateur</center></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={userProfil.prenom}
                                id="surname"
                                name="prenom"
                                label="Prénom"
                                onChange={(e) => handleModifyProfil(e, "prenom")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={userProfil.nom}
                                id="name"
                                name="nom"
                                label="Nom"
                                onChange={(e) => handleModifyProfil(e, "nom")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={userProfil.email}
                                id="mail"
                                name="email"
                                label="Adresse email"
                                onChange={(e) => handleModifyProfil(e, "email")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="role"
                                select
                                required
                                fullWidth
                                name="roles"
                                label="Rôle accordé"
                                value={role}
                                onChange={(e) => handleModifyProfil(e, "roles")}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            >
                                <MenuItem value="ROLE_ADMIN">
                                    ADMINISTRATEUR
                                </MenuItem>
                                <MenuItem value="ROLE_USER">
                                    UTILISATEUR
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={<Checkbox checked={selectAll} name="all" onChange={(e) => handleModifyProfil(e, "all")} />}
                                label="Sélectionner tous les clients"
                            />
                        </Grid>
                        {selectAll === false && 
                            <Grid item xs={12} sm={12}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="clientslist"
                                        name="clients"
                                        options={passClients}
                                        value={idclient}
                                        getOptionLabel={(option) => option.nomClient}
                                        filterSelectedOptions
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Clients associés"
                                                style={{ width: "100%"}}
                                            />
                                        )}
                                        onChange={(e, option) => handleModifyProfil(e, 'clients', option)}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ValidationParamButton onClick={() => handleDeleteUser(userProfil)} color="primary">
                        Supprimer
                    </ValidationParamButton>
                    <ValidationParamButton onClick={handleClose} color="primary">
                        Annuler
                    </ValidationParamButton>
                    <ValidationParamButton onClick={handleSubmitModif} color="primary">
                        Modifier
                    </ValidationParamButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserList;