import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import ExportAPI from '../../services/ExportAPI';

import {Paper,
        TextField,
        Button,
        Grid,
        Typography,
        Dialog,
        DialogContent,
        DialogContentText,
        CircularProgress,
        Slide
    } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
    paperRoot: {
        width: '100%',
        marginTop: 40,
        marginBottom: 40
    },
    container: {
        [theme.breakpoints.up('xl')]: {
        maxHeight: 640,
        },
        [theme.breakpoints.down(1900)]: {
        maxHeight: 440,
        },
    },
    tableheader: {
        textTransform: "uppercase",
        fontFamily: 'AvantGardeBold',
        color: '#3492aa',
        backgroundColor: '#ebebeb',
    },
    labelstyle: {
        fontFamily: 'AvantGardeBook',
    },
    search: {
        float: 'right',
    },
    warningPaper: {
        padding: 20,
        textAlign: "justify",
        fontFamily: 'AvantGardeBook',
    }
}));

const ValidationParamButton = withStyles({
    root: {
        color: '#fff',
        backgroundColor: '#2d444f',
        '&:hover': {
            backgroundColor: '#3492aa',
        },
        fontFamily: 'AvantGardeBold',
        float: 'right'
    },
})(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

const BaseAgentsXemelios = ({passClient}) => {
    const classes = useStyles();
    const [years, setYears] = useState([]);
    const [monthes, setMonthes] = useState([]);
    const [yearSelected, setYearSelected] = useState("");
    const [monthSelected, setMonthSelected] = useState("");
    const [open, setOpen] = useState(false);

    const getYearsByMatricule = async (passClient) => {
        try {
            const response = await ExportAPI.getYearsByMatricule({idclient: passClient.idclient, matricule: null});
            setYears(response);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue lors de la récupération des années des xemelios.');
        }   
    }

    useEffect(() => {
        if(passClient.idclient){
            getYearsByMatricule(passClient);
        }
    }, [passClient]);

    const getMonthByYearsAndMatricules = async (param) => {
        try {
            const response = await ExportAPI.getMonthByYearsAndMatricules({idclient: passClient.idclient, matricule: null, year: param});
            setMonthes(response);
        } catch(error) {
            console.log(error);
            toast.error('Une erreur est survenue lors de la récupération des mois des xemelios.');
        }   
    }

    const exportBaseAgentsXemelios = async () => {
        if(passClient.idclient && yearSelected && monthSelected) {
            setOpen(true);
            try {
                await ExportAPI.exportXlsBaseAgentsXemelios({idclient: passClient.idclient, annee: yearSelected, mois: monthSelected});
                setOpen(false);
            } catch(error){
                console.log(error);
                setOpen(false);
                toast.error('Une erreur est survenue lors de l\'export de la base agents xemelios.');
            }
        }
    }

    const handleChange = (event, option, param) => {
        event.preventDefault();
        
        if(param === "year") {
            setYearSelected(option ? option.annee : null);
            if(option) getMonthByYearsAndMatricules(option.annee);
        } else if(param === "month"){
            setMonthSelected(option ? option.mois : null);
        }
	}

    return (
        <div style={{marginTop: 20}}>
            <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item sm={6} md={6} lg={6}>
                    <Autocomplete
                        options={years}
                        getOptionLabel={(option) => option.annee}
                        getOptionSelected={(option, value) => option.annee === value.annee}
                        renderInput={(params) =>
                            <TextField {...params} 	
                                variant="outlined" label="Choix de l'année"
                            />
                        }
                        onChange={(event, option) => handleChange(event, option, "year")}
                    />
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                    <Autocomplete
                        options={monthes}
                        getOptionLabel={(option) => option.mois}
                        getOptionSelected={(option, value) => option.mois === value.mois}
                        renderInput={(params) =>
                            <TextField {...params} 	
                                variant="outlined" label="Choix du mois"
                            />
                        }
                        onChange={(event, option) => handleChange(event, option, "month")}
                        disabled={yearSelected ? false : true}
                    />
                </Grid>
                {!passClient.idclient &&
                    <Grid item container justify="flex-start" sm={12} md={12} lg={12}>
                        <Paper className={classes.warningPaper}>
                            <Typography variant="body1">
                                Pour réaliser un export de la base agents xemelios, vous devez sélectionner un client
                            </Typography>
                        </Paper>
                    </Grid>
                }
                <Grid item container justify="flex-start" sm={12} md={12} lg={12}> 
                    <ValidationParamButton disabled={passClient.idclient && yearSelected && monthSelected ? false : true} variant="contained" onClick={exportBaseAgentsXemelios}> 
                        Export de la base agents xemelios
                    </ValidationParamButton> 
                </Grid>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={open}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    aria-labelledby="alert-dialog-infodropzone"
                    aria-describedby="alert-dialog-infodropzone"
                >
                    <DialogContent>
                        <center>
                            <CircularProgress />
                            <DialogContentText id="alert-dialog-send-file">
                                Export de la base agents xemelios en cours. <br/>
                                Ceci peut prendre quelques minutes.<br/>
                                Vous serez averti lorsque le processus sera terminé.
                            </DialogContentText>
                        </center>
                    </DialogContent>
                </Dialog>        
            </Grid>
        </div>
    )
}

export default BaseAgentsXemelios